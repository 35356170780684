import React, { Fragment, useContext, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import UserContext from "../../context/user/userContext";
import CommunicationContext from "../../context/communication/communicationContext";
import Spinner from "../../containers/Spinner";

export default function CommunicationRecipient({ match }) {
    // const { id: communication_id, uuid: communication_recipient_uuid } = recipient;

    const {
        params: { communication_id, communication_recipient_uuid }
    } = match;

    const {
        communicationRecipient,
        getCommunicationRecipient,
        updateCommunicationStatus
    } = useContext(CommunicationContext);
    const { groupType } = useContext(UserContext);

    useEffect(() => {
        getCommunicationRecipient(
            communication_id,
            communication_recipient_uuid
        );
    }, []);

    if (!communicationRecipient) {
        return <Spinner />;
    } else {
        return (
            <div className="communication">
                <div className="communication">
                    <span>Communication</span>
                </div>
                <div className="communication__inner">
                    <div className="row lead__header">
                        {ReactHtmlParser(communicationRecipient.data.preview)}
                    </div>
                </div>
                <div className="communication__footer">
                    {/*<a
                        id="follow-up-communication-button"
                        href="/"
                        className="follow-up-communication button expanded"
                    >
                        Mark as Not Actioned
                    </a> */}

                    <div className="row">
                        {(groupType === "Dealer" || groupType === "Dealer Group") &&
                            !communicationRecipient.data.sale_status && (
                                <Fragment>
                                    <div className="column medium-6 text-center">
                                        <button
                                            style={{ width: "150px" }}
                                            className="button view"
                                            onClick={updateCommunicationStatus.bind(
                                                this,
                                                {
                                                    status: "complete",
                                                    communication_recipient_uuid: communication_recipient_uuid
                                                }
                                            )}
                                        >
                                            Sale Made
                                        </button>
                                    </div>
                                    <div className="column medium-6 text-center">
                                        <button
                                            style={{ width: "150px" }}
                                            className="button view"
                                            onClick={updateCommunicationStatus.bind(
                                                this,
                                                {
                                                    status: "lost",
                                                    communication_recipient_uuid: communication_recipient_uuid
                                                }
                                            )}
                                        >
                                            Sale Not Made
                                        </button>
                                    </div>
                                </Fragment>
                            )}
                    </div>
                </div>
            </div>
        );
    }
}
