import React from "react";

// import routeCollection from "./routes.js";
import UserState from "./context/user/UserState";
import LeadState from "./context/lead/LeadState";
import SearchState from "./context/search/SearchState";
import CustomerState from "./context/customer/CustomerState";
import PointState from "./context/point/PointState";
import DealerState from "./context/dealer/DealerState";
import CommunicationState from "./context/communication/CommunicationState";
import RewardState from "./context/reward/RewardState";
import Routes from "./routes";
import PotentialCustomerState from "./context/potentialcustomer/PotentialCustomerState";
import ReportState from "./context/report/ReportState";

function App() {
    return (
        <SearchState>
            <UserState>
                <LeadState>
                    <CustomerState>
                        <PotentialCustomerState>
                            <PointState>
                                <CommunicationState>
                                    <RewardState>
                                        <DealerState>
                                            <ReportState>
                                                <Routes />
                                            </ReportState>
                                        </DealerState>
                                    </RewardState>
                                </CommunicationState>
                            </PointState>
                        </PotentialCustomerState>
                    </CustomerState>
                </LeadState>
            </UserState>
        </SearchState>
    );
}

export default App;
