import React, { Fragment } from "react";
import { has } from "lodash";

const Points = ({ customer, categoryOptions }) => {
    if (has(customer,'calc.activated_at')) {
        return (
            <Fragment>
                <div className="columns small-5">
                    <div className="customer__points">
                        <h4>Points Statement</h4>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Current Points Total</td>
                                    <td style={{ textAlign: "right" }}>
                                        <strong>
                                            {customer.calc.available_points
                                                ? customer.calc.available_points.toLocaleString()
                                                : 0}
                                        </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>All Points Given</td>
                                    <td style={{ textAlign: "right" }}>
                                        {customer.calc.points
                                            ? customer.calc.points.toLocaleString()
                                            : 0}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Redeemed Points</td>
                                    <td style={{ textAlign: "right" }}>
                                        <strong>
                                            {customer.calc.redeemed_points
                                                ? customer.calc.redeemed_points.toLocaleString()
                                                : 0}
                                        </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Expired Points</td>
                                    <td style={{ textAlign: "right" }}>
                                        {customer.calc.expired_points
                                            ? customer.calc.expired_points.toLocaleString()
                                            : 0}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table>
                            <thead>
                                <tr>
                                    <th>Parts Grouping</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {categoryOptions.map(option => {
                                    console.log(`${option.value}_points`);
                                    return (
                                        <tr key={option.value}>
                                            <td>{option.name}</td>
                                            <td style={{ textAlign: "right" }}>
                                                {Number(
                                                    (customer.points_statement &&
                                                        customer.points_statement[`${option.value}_points`]) || 0
                                                ).toLocaleString()}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Fragment>
        );
    } else {
		 return null;
	 }
};

export default Points;
