import { GET_TOKEN, GET_USER, SET_LOADING } from "../types";
import { get } from "lodash";

export default (state, action) => {
    switch (action.type) {
        case GET_USER:
            return {
                ...state,
                user: get(action, "payload.data.user", {}),
                group: get(action, "payload.data.group", {}),
                groupType: get(action, "payload.data.groupType", {}),
                loading: false
            };
        case GET_TOKEN:
            return {
                ...state,
                passport: get(action, "payload"),
                loading: false
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true
            };

        default:
            return state;
    }
};
