import React, { Fragment, useContext, useEffect, useState } from "react";
import { map, isString } from "lodash";
import TableLayout from "../../containers/TableLayout";
import RewardsRow from "./RewardsRow";
import RewardContext from "../../context/reward/rewardContext";
import SearchContext from "../../context/search/searchContext";
import UserContext from "../../context/user/userContext";
import Spinner from "../../containers/Spinner";
import { checkInteger, checkString, getFilterByValue } from "../../utils/utils";
import { useIsFirstRender } from "../../utils/isFirstRender";
import { getRewardFilterOptions, getDefaultRewardFilterOption } from "../rewardFilterOptions";
import GetOnFirstRender from "../GetOnFirstRender";

export default function RewardsTable({ match }) {
    const {
        params: { filter, from, to }
    } = match;

    const {
        rewards,
        getRewards,
        loading,
        rewardsPage,
        setRewardPage,
        rewardCount,
        rewardFilterValue,
        setRewardFilterValue,
        setRewardFilterOrDefault
    } = useContext(RewardContext);

    const { startDateMySQL, endDateMySQL } = useContext(SearchContext);
    const { groupType, user } = useContext(UserContext);

    const isFirstRender = useIsFirstRender();
    const rewardFilterOptions = getRewardFilterOptions(groupType);
    const defaultRewardFilterOption = getDefaultRewardFilterOption(groupType); /* TODO - USE THIS VALUE */
    const filterObject = getFilterByValue(rewardFilterOptions, rewardFilterValue);
    const { dates } = filterObject;

    const apiUrl = checkString(process.env.REACT_APP_BASE_URL);

    const getItemsUrl = () =>
        getRewards({
            rewardFilterValue: isString(filter) ? filter : rewardFilterValue
        });
    const getItemsState = () => getRewards({ rewardFilterValue: rewardFilterValue });

    return (
        <Fragment>
            <div className="row">
                <h3 className="title pad-t">Rewards</h3>
                <GetOnFirstRender
                    to={to}
                    from={from}
                    filter={filter}
                    getItems={getItemsUrl}
                    setFilterValue={setRewardFilterValue}
                />
                <TableLayout
                    activePage={rewardsPage}
                    setActivePage={setRewardPage}
                    totalItems={checkInteger(rewardCount)}
                    getItems={getItemsState}
                    setFilterValue={setRewardFilterValue}
                    filterValue={rewardFilterValue}
                    filterOptions={rewardFilterOptions}
                    showDates={dates}
                    showSearchBox={true}
                    exportLink={`${apiUrl}/download/Rewards/${user.uuid}/${groupType}/${startDateMySQL}/${endDateMySQL}/${rewardFilterValue}`}
                >
                    <table id="rewards-table" className="responsive" width="100%">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Customer</th>
                                <th>ID</th>
                                <th>Reward Type</th>
                                <th>Code</th>
                                <th>Value</th>
                                <th>Created At</th>
                                <th>Expires At</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading &&
                                map(rewards, (reward, i) => (
                                    <RewardsRow
                                        key={i}
                                        reward={reward}
                                        groupType={groupType}
                                    />
                                ))}
                        </tbody>
                    </table>
                    {loading ? <Spinner /> : null}
                </TableLayout>
            </div>
        </Fragment>
    );
}
