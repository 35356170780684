import React, { Fragment } from "react";

const Ifbr = ({ element }) => {

    if (!element) {
        return '';
    } else {
        return (
            <Fragment>
                {element}<br />
            </Fragment>
        );
    }
};

export default Ifbr;
