import React, { useContext, useEffect } from "react";
import logo from "../images/distrigo_logo.svg";
import UserContext from "../context/user/userContext";

const Header = () => {
    const userContext = useContext(UserContext);

    const { getUser, user, group, groupType } = userContext;
    useEffect(() => {
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="header">
            <div className="row">
                <div className="columns">
                    <div className="header__inner">
                        <a className="header__logo" href="/">
                            <img src={logo} alt="Distrigo" />
                        </a>
                        <ul className="menu header__login">
                            <li className="user">
                                {(user && user.first_name)
                                    ? `${user.first_name} ${user.last_name}`
                                    : ""}
                            </li>
                            <li className="role">
                                {(group && group.name) ? `${group.name}` : ""}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
