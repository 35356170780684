import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
    secondsToHms,
    displayPercentage,
    checkBool,
    replaceUnderscores
} from "../../../utils/utils";
import SearchContext from "../../../context/search/searchContext";

export default function HeadOfficeLeadRow({
    leadDealer,
    filterValue: filterValueRaw,
    filterObject
}) {
    const { startDateMySQL, endDateMySQL } = useContext(SearchContext);

    const filterValue = replaceUnderscores(filterValueRaw);
    const { dates } = filterObject;

    const link = checkBool(dates)
        ? `/lead/dealer/${leadDealer.id}/${filterValue}/${startDateMySQL}/${endDateMySQL}`
        : `/lead/dealer/${leadDealer.id}/${filterValue}`;

    return (
        <tr key={leadDealer.id}>
            <td className="PD">
                <Link to={link}>{leadDealer.dealer_name}</Link>
            </td>
            <td className="PD">{leadDealer.dealer_code.replace("HUB_", "")}</td>
            <td className="PD">{leadDealer.total_leads}</td>
            <td className="PD">{leadDealer.total_followed_up}</td>
            <td className="PD">{leadDealer.total_to_follow_up}</td>
            <td className="PD">{leadDealer.total_notifications_sent}</td>
            <td className="PD">
                {leadDealer.average_followed_up > 0 &&
                leadDealer.average_followed_up_time > 0
                    ? secondsToHms(leadDealer.average_followed_up_time)
                    : "-"}
            </td>
            <td className="PD">
                {displayPercentage(leadDealer.average_followed_up)}
            </td>
            <td className="PD" className="leads__buttons">
                <Link to={link}>
                    <button className="button button--black">View </button>
                </Link>
            </td>
        </tr>
    );
}
