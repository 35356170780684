import React from "react";
import TableHeader from "../components/TableHeader";
import TableFooter from "../components/TableFooter";

export default function Layout({
    children,
    title,
    activePage,
    setActivePage,
    paginationValue,
    setPaginationChange,
    showPagination,
    totalItems,
    getItems,
    filterValue,
    setFilterValue,
    filterOptions,
    showDates,
    showSearchBox,
    changeDatesOnly,
    exportLink,
    hideFilter,
    useFilterOptions,
}) {
    return (
        <div className="site">
            <TableHeader
                title={title}
                getItems={getItems}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                filterOptions={filterOptions}
                showDates={showDates}
                showSearchBox={showSearchBox}
                changeDatesOnly={changeDatesOnly}
                hideFilter={hideFilter}
                useFilterOptions={useFilterOptions}
                setActivePage={setActivePage}
            />
            <div>{children}</div>
            <TableFooter
                activePage={activePage}
                setActivePage={setActivePage}
                paginationValue={paginationValue}
                setPaginationChange={setPaginationChange}
                showPagination={showPagination}
                totalItems={totalItems}
                getItems={getItems}
                exportLink={exportLink}
            />
        </div>
    );
}
