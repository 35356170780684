import React, { useContext } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    __RouterContext
} from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";
import { useTransition, animated } from "react-spring";
import Layout from "./containers/Layout";

// Views
import Summary from "./pages/Summary";
import LeadsTable from "./tables/LeadsTable";
import RewardsTable from "./tables/RewardsTable";
import DealerLeadsTable from "./tables/DealerLeadsTable";
import DealerRewardsTable from "./tables/DealerRewardsTable";
import DealerCustomersTable from "./tables/DealerCustomersTable";
import DealerPotentialCustomersTable from "./tables/DealerPotentialCustomersTable";
import HeadOfficeLeads from "./tables/HeadOffice/HeadOfficeLeads";
import HeadOfficeRewards from "./tables/HeadOffice/HeadOfficeRewards";
import HeadOfficeCustomers from "./tables/HeadOffice/HeadOfficeCustomers";
import HeadOfficePotentialCustomers from "./tables/HeadOffice/HeadOfficePotentialCustomers";
import CommunicationsTable from "./tables/CommunicationsTable";
import CustomersTable from "./tables/CustomersTable";
import PotentialCustomersTable from "./tables/PotentialCustomersTable";
import HeadOfficeCommunications from "./tables/HeadOffice/HeadOfficeCommunications";
import DealerCommunicationsTable from "./tables/DealerCommunicationsTable";
import DealerCommunicationRecipientsTable from "./tables/DealerCommunicationRecipientsTable";
import CommunicationRecipientsTable from "./tables/CommunicationRecipientsTable";
import ReportsTable from "./tables/ReportsTable";
import Lead from "./components/Lead";
import Customer from "./components/Customer";
import PotentialCustomer from "./components/PotentialCustomer";
//import CommunicationRecipient from "./components/CommunicationRecipient";
import CommunicationRecipient from "./components/CommunicationRecipient/CommunicationRecipientOriginal";

export default function Routes() {
    const { location } = useContext(__RouterContext);
    const transitions = useTransition(location, location => location.pathname, {
        initial: { opacity: 0, transform: "translate(0,0)" },
        from: { opacity: 0, transform: "translate(100%,0)" },
        enter: { opacity: 1, transform: "translate(0%,0)" },
        leave: { opacity: 0, transform: "translate(-90%,0)" }
    });

                // {transitions.map(({ item, props, key }) => {
    return (
        <Router>
            <Layout location={location}>
                    {
                        /*
                        // console.log('route - item',item);
                        <animated.div
                            style={{
                                ...props,
                                position: "relative",
                                height: "100%",
                                width: "100%"
                            }}
                            key={key}
                        >
                        <Switch location={item}>
                    return (
								*/
                    }
							  <Switch>
                            <ScrollToTop>
                                <Route exact path="/" component={Summary} />
                                {/* HO Reward Routes */}
                                <Route
                                    exact
                                    path="/reward-dealers/:filter?/:from?/:to?"
                                    component={HeadOfficeRewards}
                                />
                                {/* Individual Rewards at the Dealer */}
                                <Route
                                    exact
                                    path="/reward/dealer/:dealer_id/:filter?/:from?/:to?"
                                    component={DealerRewardsTable}
                                />
                                {/* Dealer Reward Routes */}
                                {/* Individual Reward at the Dealer */}
                                <Route
                                    exact
                                    path="/rewards/:filter?/:from?/:to?"
                                    component={RewardsTable}
                                />{" "}
                                {/* HO Lead Routes */}
                                <Route
                                    exact
                                    path="/lead-dealers/:filter?/:from?/:to?"
                                    component={HeadOfficeLeads}
                                />
                                {/* Individual Leads at the Dealer */}
                                <Route
                                    exact
                                    path="/lead/dealer/:dealer_id/:filter?/:from?/:to?"
                                    component={DealerLeadsTable}
                                />
                                {/* Dealer Lead Routes */}
                                {/* Individual Leads at the Dealer */}
                                <Route
                                    exact
                                    path="/leads/:filter?/:from?/:to?"
                                    component={LeadsTable}
                                />{" "}
                                {/*y*/}
                                {/* Individual Lead Route */}
                                <Route
                                    exact
                                    path="/lead/:lead_id"
                                    component={Lead}
                                />{" "}
                                {/*y*/}
                                {/* HO Customer Routes */}
                                {/* Customer Summary per Dealer */}
                                <Route
                                    exact
                                    path="/customer-dealers/:filter?/:from?/:to?"
                                    component={HeadOfficeCustomers}
                                />
                                {/* Individual Customers at the Dealer */}
                                <Route
                                    exact
                                    path="/customer/dealer/:dealer_id/:filter?/:from?/:to?"
                                    component={DealerCustomersTable}
                                />
                                {/* Dealer Customer Routes */}
                                {/* Individual Customers at the Dealer */}
                                <Route
                                    exact
                                    path="/customers/:filter?/:from?/:to?"
                                    component={CustomersTable}
                                />{" "}
                                {/*y*/}
                                {/* Individual Customer Route */}
                                <Route
                                    exact
                                    path="/customer/:customer_id"
                                    component={Customer}
                                />{" "}
                                {/*n - route doesnt exit?*/}
                                {/* HO Communications Routes */}
                                {/* Communication Summary per Dealer */}
                                <Route
                                    exact
                                    path="/communication-dealers/:filter?/:from?/:to?"
                                    component={HeadOfficeCommunications}
                                />
                                {/* Summary per Communication at the Dealer */}
                                <Route
                                    exact
                                    path="/communications-dealer/:dealer_id/:filter?/:from?/:to?"
                                    component={DealerCommunicationsTable}
                                />
                                {/* Individual Recipients per Communication at the Dealer */}
                                <Route
                                    exact
                                    path="/communication-recipients-dealer/:communication_id/dealer/:dealer_id/:filter?/:from?/:to?"
                                    component={
                                        DealerCommunicationRecipientsTable
                                    }
                                />
                                {/* Dealer Communications Routes */}
                                {/* Summary per Communication at the Dealer */}
                                <Route
                                    exact
                                    path="/communications/:filter?/:from?/:to?"
                                    component={CommunicationsTable}
                                />
                                {/* Individual Recipients per Communication at the Dealer */}
                                <Route
                                    exact
                                    path="/communication-recipients/:communication_id/:filter?/:from?/:to?"
                                    component={CommunicationRecipientsTable}
                                />
                                {/* Individual Communication Recipient Route */}
                                <Route
                                    exact
                                    path="/communication-recipient/:communication_id/:communication_recipient_uuid"
                                    component={CommunicationRecipient}
                                />
                                {/* HO Potential Customer Routes */}
                                {/* Potential Customer Summary per Dealer */}
                                <Route
                                    exact
                                    path="/potential-customer/dealers"
                                    component={HeadOfficePotentialCustomers}
                                />
                                {/* Individual Potential Customers at the Dealer */}
                                <Route
                                    exact
                                    path="/potential-customer/dealer/:dealer_id"
                                    component={DealerPotentialCustomersTable}
                                />
                                {/* Dealer Potential Customer Routes */}
                                {/* Individual Potential Customers at the Dealer */}
                                <Route
                                    exact
                                    path="/potential-customers"
                                    component={PotentialCustomersTable}
                                />
                                {/* Individual Potential Customer Route */}
                                <Route
                                    exact
                                    path="/potential-customer/:id"
                                    component={PotentialCustomer}
                                />
                                {/* Reports Route */}
                                <Route
                                    exact
                                    path="/reports"
                                    component={ReportsTable}
                                />
                            </ScrollToTop>
                        </Switch>
                    {
                        /*</animated.div>*/
                    }
            </Layout>
        </Router>
    );
}

                //})}
