import { useContext } from "react";
import { __RouterContext } from "react-router-dom";
import { compose } from "lodash/fp";
import lodash from "lodash";
import {
    reduce,
    replace,
    map,
    includes,
    split,
    get,
    slice,
    concat,
    join,
    indexOf,
} from "lodash";
import moment from "moment";

/**
 * checkString
 * will not cause crash if given value isnt a string,
 * instead throws non fatal error
 *
 * @param {string} x
 * @returns {string} x | ""
 */
export const checkString = (x) => {
    if (lodash.isString(x)) {
        return x;
    } else {
        // console.error(`Given value: ${x} is not a string`);
        return "";
    }
};

/**
 * Get the full stack error
 *
 * @param {string} errorString
 * @return {string} errorString
 */
const parseStackError = compose(
    (e) => lodash.join(e),
    //e => lodash.filter(e, x => lodash.includes(x, "src")),
    (e) => lodash.split(e, "@"),
    checkString
);

/**
 * Log a non fatal error message to the console
 *
 * @param {string} message
 * @return {string} errorString
 */
export const logError = (msg) => {
    const e = new Error().stack;

    const e2 = parseStackError(e);

    // console.log('lodash.find',lodash.find);
    // console.log('lodash.includes',lodash.includes);

    console.error(msg);
    //console.log(e2)
};
/*
export const logError = compose(
	e => { console.error(e) },
	e => parseStackError(e),
	msg => {
		return new Error().stack
	}
);
*/

/**
 * checkArray
 * will not cause crash if given value isnt array
 * instead throws non fatal error
 *
 * @param {array} x
 * @returns {array} x | []
 */
export const checkArray = (x) => {
    if (lodash.isArray(x)) {
        return x;
    } else {
        //throw new Error("Given value is not an array")
        // console.error(`Given value: ${x} is not an array`);
        return [];
    }
};

/**
 * checkBool
 * Display an error message if given value is not a boolean
 *
 * @param {boolean} x
 * @returns {boolean} x | any
 */
export const checkBool = (x) => {
    if (lodash.isBoolean(x)) {
        return x;
    } else {
        // console.error(`Given value: ${x} is not a boolean`);
        return x;
    }
};

/**
 * checkInteger
 * will not cause crash if given value isnt integer,
 * instead throws non fatal error
 *
 * @param {number} x
 * @returns {number} x | 0
 */
export const checkInteger = (x) => {
    if (lodash.isInteger(x)) {
        return x;
    } else {
        //console.error("Given value is not an integer");
        //throw new Error('Given value is not an integer');

        //console.trace("Given value is not an integer");

        // console.error(new Error().stack);
        // logError(`Given value: ${x} is not an integer`);
        return 0;
    }
};

/**
 * checkFunction
 * will not cause crash if given value isnt a function,
 * instead throws non fatal error
 *
 * @param {function} x
 * @returns {function} x | () => {}
 */
export const checkFunction = (x) => {
    if (lodash.isFunction(x)) {
        return x;
    } else {
        // console.error(`Given value: ${x} is not a function`);
        return () => {};
    }
};

/**
 * arrayLen
 * will not cause crash if given value isnt array
 * instead throws non fatal error
 *
 * @param {array}
 * @returns {array}
 */
export const arrayLen = compose(lodash.size, checkArray);

/**
 * parseInteger
 * will not cause crash if given value isnt integer
 * instead throws non fatal error
 *
 * @param {string}
 * @returns {number}
 */
export const parseInteger = compose(checkInteger, parseInt);

/**
 * runFunction
 * will not cause crash if given value isnt a function
 * instead throws non fatal error
 *
 * @param {function}
 * @param {array} args
 * @returns {any} functions return
 */
export const runFunction = (func, args = []) => {
    const func_ = checkFunction(func);
    return func_(...args);
};

/**
 * getArray
 * will not cause crash if given value isnt an array
 * instead throws non fatal error
 *
 * @param {object}
 * @returns {array}
 */
export const getArray = compose(checkArray, (object, path) =>
    lodash.get(object, path)
);

/**
 * getInteger
 * will not cause crash if given value isnt an integer
 * instead throws non fatal error
 *
 * @param {object}
 * @returns {number}
 */
export const getInteger = compose(checkInteger, (object, path) =>
    lodash.get(object, path)
);

/**
 * For use with reduce.
 * Filter options to a single filter value
 *
 * @param {string} accumulator
 * @param {object} filterOption
 * @returns {string} filterValue
 */
const optionsToFilter = (accumulator, filterOption) => {
    const { value } = filterOption;
    return value === "sale_made" ? value : accumulator;
};

/**
 * Is given filter present in filter options?
 * If not then return default value
 *
 * @param {string} filter_
 * @param {array<Object>} filterOptions_
 * @param {string} defaultFilter
 * @return {string} filterValue || defaultFilter
 */
export const getFilter = compose(
    checkString,
    (filter_, filterOptions_, defaultFilter) => {
        const filter = checkString(filter_);

        return lodash.reduce(
            checkArray(filterOptions_),
            (accumulator, filterOption, index) => {
                const { value } = filterOption;

                return value === filter ? filterOption.value : accumulator;
            },
            checkString(defaultFilter)
        );
    }
);

/*
export const getFilter_ = compose(
	checkString,
   (filter, filterOptions) => {
		return reduce(checkArray(filterOptions), optionsToFilter.bind('test','testvalue'), "");
	}
)
*/

/**
 * Replace hyphens with underscores
 *
 * @param {string} hyphened string
 * @return {string} underscored string
 */
export const replaceHyphens = compose(
    (s) => replace(s, /-/g, "_"),
    checkString
);

/**
 * Replace underscores with hypens
 *
 * @param {string} underscored string
 * @return {string} hypened string
 */
export const replaceUnderscores = compose(
    (s) => replace(s, /_/g, "-"),
    checkString
);

/**
 * Retrieve value from local storage
 *
 * @param {string} key string
 * @return {string} value
 */
export const getLocalStorage = (key) => window.localStorage.getItem(key);

/**
 * Retrieve value from local storage
 *
 * @param {string} key string
 * @return {string} value
 */
export const updateLocalStorage = (key, value) =>
    window.localStorage.setItem(key, value);

export const secondsToHms = (x) => {
    const d = Number(x);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    return `${h}h ${m}m ${s}s`;
};

export const displayPercentage = (x) => {
    const p = Number(x * 100).toFixed(2);

    return Number(x) ? `${p}%` : "-";
};


export const capitaliseFirstLetter = x => {
	return x[0].toUpperCase() + x.substring(1)
};

/**
 * @param {array} filterOptionsRaw
 * @param {string} filterRaw
 * @return {string} validFilter
 */
export const getFilterByValue = (filterOptionsRaw, filterRaw) => {
    const filter = checkString(filterRaw);
    const filterOptions = checkArray(filterOptionsRaw);

    const validFilter = reduce(
        filterOptions,
        (accum, current) => (current.value === filter ? current : accum),
        {}
    );
    return validFilter;
};

/**
 * @param {array} filterOptionsRaw
 * @param {string} filterRaw
 * @param {string} defaultFilter
 * @return {string} validFilter
 */
export const checkFilter = (filterValuesRaw) => (filterRaw) => {
    const filterValues = checkArray(filterValuesRaw);
    const filter = checkString(filterRaw);

    if (includes(filterValues, filter)) return filter;

    console.error("Invalid filter value:", filter);

    // return users default filter
    return "all";
};

/**
 * @param {Date} startDate
 * @param {string} path
 * @return {string} path | url
 */
export const replaceStartDate = (startDate) => (path) => {
    const startDateStr = moment(startDate).isValid()
        ? moment(startDate).format("YYYY-MM-DD")
        : "";

    return replace(path, ":from?", startDateStr);
};

/**
 * @param {Date} endDate
 * @param {string} path
 * @return {string} path | url
 */
export const replaceEndDate = (endDate) => (path) => {
    const endDateStr = moment(endDate).isValid()
        ? moment(endDate).format("YYYY-MM-DD")
        : "";

    return replace(path, ":to?", endDateStr);
};

/**
 * @param {string} filter
 * @param {string} path
 * @return {string} path | url
 */
export const replaceFilter = (filter) => (path) =>
    replace(path, ":filter?", replaceUnderscores(filter));

/**
 * If unrequired - remove the date placeholders
 * from the path string
 *
 * @param {boolean} dates
 * @param {string} path
 * @return {string} path
 */
export const maybeRemoveDates = (dates) => (path) =>
    dates ? path : replace(path, /\/:filter\?.+/, "/:filter?");

/**
 * @param {string} url
 * @return {string} url
 */
export const urlSections = (url) => split(url, "/");

/**
 * Get filter and date parameter placeholders from url path
 *
 * @param {string} path
 * @return {string} path
 */
export const pathParams = (path) => get(/:filter.+/.exec(path), "[0]", "");

/**
 * Get url without date and filter parameter placeholders
 *
 * @param {array} splitUrl
 * @param {array} splitPath
 * @return {array} array
 */
export const urlWithoutParams = (splitUrl, splitPath) =>
    slice(splitUrl, 0, indexOf(splitPath, ":filter?"));

/**
 * Fill unchanged parameters
 * from the given url
 *
 * @param {string} url
 * @param {string} path
 * @return {string} path
 */
export const fillUnchangedParameters = (url) => (path) => {
    const splitPath = split(path, "/");
    const splitUrl = split(url, "/");
    const paramStr = pathParams(path);

    if (paramStr.length === 0) return url;

    const newPath = urlWithoutParams(splitUrl, splitPath);

    const filledPath = join(concat(newPath, paramStr), "/");

    return filledPath;
};

/**
 * Make a new url from given parameters
 *
 * @param {string} path
 * @param {string} url
 * @param {object} filterObject
 * @param {object} startDate
 * @param {object} endDate
 * @return {string} newUrl
 */
export const makeNewUrl = (path, url, filterObject, startDate, endDate) => {
    const { value: filterValue, dates } = filterObject;

    const result = compose(
        replaceFilter(filterValue),
        replaceEndDate(endDate),
        replaceStartDate(startDate),
        maybeRemoveDates(dates),
        fillUnchangedParameters(url),
        checkString
    )(path);

    return result;
};

/**
 * Updates url with users selected filters / date range on
 * the active page
 *
 * @param {string} defaultFilter
 * @return {string} validFilter
 */
export const updateUrlMaybe = (
    history,
    path,
    url,
    filterObject,
    startDate,
    endDate
) => {
    const { push } = history;
    const newUrl = makeNewUrl(path, url, filterObject, startDate, endDate);

    if (url !== newUrl) push(newUrl);
};

/**
 * @param {string} elementType
 * @param {string} id
 * @return {div} divElement
 */
export const createElementWithId = (elementType, id) => {
    let element = document.createElement(elementType);
    element.setAttribute("id", id);

    return element;
};

/**
 * @param {string} date
 * @param {string} fallbackDate
 * @return {object} validDate
 */
export const validateDate = (date, fallbackDate) =>
    moment(date).isValid() && date !== undefined
        ? moment(date, "YYYY-MM-DD")
        : moment(fallbackDate, "YYYY-MM-DD");

/**
 * @param {object} momentDate
 * @return {string} dateString
 */
export const formatDate = (momentDate) => momentDate.format("YYYY-MM-DD");

/**
 * @param {string} date
 * @return {string} validDate
 */
export const prepareDateString = (date, fallbackDate) =>
    compose(formatDate, validateDate)(date, fallbackDate);
