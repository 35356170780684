import React, { useReducer } from "react";
import axios from "axios";
import { get } from "lodash"
import ReportingContext from "./reportContext";
import ReportingReducer from "./ReportReducer";
import {
    SET_LOADING,
    GET_REPORTS,
} from "../types";

const ReportState = (props) => {
    const initialState = {
        reports: [],
        downloadUrl: '',
        loading: false,
    };

    const [state, dispatch] = useReducer(ReportingReducer, initialState);

    const getReports= async () => {
        setLoading();
        const res = await axios.get(`/api/v1/reports`);
        dispatch({
            type: GET_REPORTS,
            payload: get(res,'data', {}),
        });
    };


    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <ReportingContext.Provider
            value={{
                reports: state.reports,
                loading: state.loading,
                setLoading,
                getReports,
            }}
        >
            {props.children}
        </ReportingContext.Provider>
    );
};

export default ReportState;
