import { getInteger, checkInteger, getArray } from "../../utils/utils";
import { get } from "lodash";
import {
    SET_LOADING,
    SET_LOADED,
    SET_CATEGORY_OPTIONS_LOADING,
    GET_TOTAL_CUSTOMERS,
    GET_ALL_CUSTOMERS,
    GET_ACTIVATED_CUSTOMERS,
    GET_NOT_ACTIVATED_CUSTOMERS,
    GET_CUSTOMER,
    GET_CUSTOMER_PAGE,
    GET_CUSTOMER_DEALERS,
    GET_DEALER_CUSTOMERS,
    GET_CUSTOMER_COUNTS,
    GET_CATEGORY_OPTIONS,
    GET_MARKETING_CAMPAIGNS_CUSTOMER,
    GET_MARKETING_CAMPAIGNS_DEALER,
    GET_COMMUNICATIONS_CUSTOMER,
    SET_CUSTOMER_FILTER_VALUE,
    SET_CUSTOMER_DEALERS_PAGE,
    SET_DEALER_CUSTOMERS_PAGE,
    SET_CUSTOMER_PAGE
} from "../types";

export default (state, action) => {
    switch (action.type) {
        case SET_CUSTOMER_PAGE:
            return {
                ...state,
                customerPage: action.payload
            };

        case SET_DEALER_CUSTOMERS_PAGE:
            return {
                ...state,
                dealerCustomersPage: checkInteger(action.payload)
            };

        case SET_CUSTOMER_DEALERS_PAGE:
            return {
                ...state,
                customerDealersPage: checkInteger(action.payload)
            };

        case SET_CUSTOMER_FILTER_VALUE:
            return {
                ...state,
                customerFilterValue: action.payload
            };

        case GET_TOTAL_CUSTOMERS:
            return {
                ...state,
                customerTotalCount: getInteger(action, "payload.data.count"),
                loading: false
            };
        case GET_CUSTOMER_COUNTS:
            return {
                ...state,
                customerCounts: get(action, "payload.data", {}),
                loading: false
            };
        case GET_ALL_CUSTOMERS:
            return {
                ...state,
                customers: get(action, "payload.data"),
                customerTotal: getInteger(action, "payload.meta.total"),
                customerLinks: get(action, "payload.links", {}),
                loading: false
            };
        case GET_ACTIVATED_CUSTOMERS:
            return {
                ...state,
                customersActivated: get(action, "payload.data", {}),
                customersActivatedTotal: getInteger(
                    action,
                    "payload.meta.total"
                ),
                customerActivatedLinks: get(action, "payload.links", {}),
                loading: false
            };
        case GET_NOT_ACTIVATED_CUSTOMERS:
            return {
                ...state,
                customersNotActivated: action.payload.data,
                customerNotActivatedTotal: action.payload.meta.total,
                customerNotActivatedLinks: action.payload.links,
                loading: false
            };
        case GET_DEALER_CUSTOMERS:
            return {
                ...state,
                customers: get(action, "payload.data", {}),
                customerCount: getInteger(action, "payload.meta.total"),
                customerLinks: get(action, "payload.links", {}),
                loading: false
            };
        case GET_CUSTOMER_DEALERS:
            return {
                ...state,
                customerDealers: get(action, "payload.data", {}),
                customerDealerCount: getInteger(action, "payload.meta.total"),
                customerDealerLinks: get(action, "payload.links"),
                loading: false
            };
        case GET_CUSTOMER_PAGE:
            return {
                ...state,
                customers: action.payload,
                loading: false
            };
        case GET_CUSTOMER:
            return {
                ...state,
                customer: action.payload,
                loading: false
            };
        case GET_CATEGORY_OPTIONS:
            return {
                ...state,
                categoryOptions: get(action, "payload.data.categories", {}),
                categoryOptionsLoaded: false
            };
        case GET_MARKETING_CAMPAIGNS_CUSTOMER:
            return {
                ...state,
                marketingCampaignsCustomer: get(
                    action,
                    "payload.data.marketingCampaigns",
                    {}
                ),
                loading: false
            };
        case GET_MARKETING_CAMPAIGNS_DEALER:
            return {
                ...state,
                marketingCampaignsDealer: get(
                    action,
                    "payload.data.marketingCampaigns",
                    {}
                ),
                loading: false
            };
        case GET_COMMUNICATIONS_CUSTOMER:
            return {
                ...state,
                customerCommunications: get(
                    action,
                    "payload.data.communications",
                    {}
                ),
                loading: false
            };
        case SET_LOADING:
            return {
                ...state,
                itemsLoading: getArray(action, "payload"),
                loading: true
            };

        case SET_LOADED:
            return {
                ...state,
                itemsLoading: getArray(action, "payload.itemsLoading"),
                loading: get(action, "payload.loading")
            };

        case SET_CATEGORY_OPTIONS_LOADING:
            return {
                ...state,
                categoryOptionsLoaded: true
            };
        default:
            return state;
    }
};
