import React, { useState, useEffect } from "react";
import { map } from "lodash";
import {
    checkString,
    checkFunction,
    getFilterByValue,
    runFunction,
} from "../../utils/utils";

const FilterSelect = ({
    filterValue,
    filterOptions,
    setFilterValue,
    filter,
}) => {
    const filterObject = getFilterByValue(filterOptions, filterValue);

    const updateChosenFilter = (x) => {
        const val =
            checkString(x.target.value) !== ""
                ? checkString(x.target.value)
                : "all_dates"; 
        setFilterValue(val);
    };

    const optionGroup = (item, i) => {
        return (
            <optgroup key={"grp_" + item.value + "_" + i} label={item.name}>
                {map(item.children, (child, i) => {
                    return child.children
                        ? optionGroup(child)
                        : optionItem(child);
                })}
            </optgroup>
        );
    };

    const optionItem = (item) => {
        return (
            <option
                key={item.value}
                value={checkString(item.value)}
                className={item.class}
            >
                {checkString(item.name)}
            </option>
        );
    };

    return (
        <select value={filter} onChange={updateChosenFilter}>
            {map(filterOptions, (filter, i) => {
                if (filter.children) {
                    return optionGroup(filter, i);
                } else {
                    return optionItem(filter);
                }
            })}
        </select>
    );
};

export default FilterSelect;
