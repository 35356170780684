import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faEnvelope,
    faEnvelopeOpenText,
    faPhoneSquare,
    faTimes
} from "@fortawesome/free-solid-svg-icons";

export default function CustomerRow({ customer, selectOption }) {
    return (
        <tr key={customer.customer_id}>
            <td className="PD">{customer.trade_team_customer}</td>
            <td className="PD">{customer.trade_team_id}</td>
            <td className="PD">
                {customer.permissions
                    ? <FontAwesomeIcon icon={faCheck} title={"Filled in GDPR Form"} />
                    : <FontAwesomeIcon icon={faTimes} color={"lightgray"} title={"Not filled in GDPR Form"}  />}
            </td>
            <td className="PD">{customer.type}</td>
            <td className="PD">
                {customer.awarded_points_to_show
                    ? Number(customer.awarded_points_to_show).toLocaleString()
                    : 0}
            </td>
            <td className="PD">
                {customer[`points_${selectOption}`]
                    ? Number(
                          customer[`points_${selectOption}`]
                      ).toLocaleString()
                    : 0}
            </td>
            <td className="PD">
                {customer.redeemed_points_to_show
                    ? Number(customer.redeemed_points_to_show).toLocaleString()
                    : 0}
            </td>
            <td className="PD">
                {customer.expired_points
                    ? Number(customer.expired_points).toLocaleString()
                    : 0}
            </td>
            <td className="PD">
                {customer.available_points
                    ? Number(customer.available_points).toLocaleString()
                    : 0}
            </td>
            <td className="PD">
                {customer.channel_post > 0
                    ? <FontAwesomeIcon icon={faEnvelopeOpenText} title={"Permission to post"} />
                    : <FontAwesomeIcon icon={faEnvelopeOpenText} color={"lightgray"} title={"No permission to send post"} />}
                {" "}
                {customer.channel_sms > 0
                    ? <FontAwesomeIcon icon={faPhoneSquare} title={"Permission to send sms"} />
                    : <FontAwesomeIcon icon={faPhoneSquare} color={"lightgray"} title={"No permission to send sms"} />}
                {" "}
                {customer.channel_email > 0
                    ? <FontAwesomeIcon icon={faEnvelope} title={"Permission to send email"} />
                    : <FontAwesomeIcon icon={faEnvelope} color={"lightgray"} title={"No permission to send email"} />}
            </td>
            <td className="PD">
                {customer.activated_at
                    ? <FontAwesomeIcon icon={faCheck} color={"green"} title={"Activated at " + moment(customer.activated_at).format("DD/MM/YYYY")} />
                    : <FontAwesomeIcon icon={faTimes} color={"lightgray"}  title={"Not in PERKS yet"} />}
            </td>

            <td className="leads__buttons PD">
                {customer.activated_at
                    ? <Link to={`/customer/${customer.customer_id}`}><button className="button button--black">View</button></Link>
                    : ""
                }
            </td>
        </tr>
    );
}
