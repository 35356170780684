import React, { Component } from "react";

export class ErrorBoundary extends Component {
    state = { error: null };

    static getDerivedStateFromError(error) {
        return { error: error };
    }

    componentDidCatch(error, info) {
        console.log("error: ", error);
        console.log("error info: ", info);
    }

    render() {
        if (this.state.error) {
            return <p>Something Broke</p>;
        }

        return this.props.children;
    }
}
