import React, { useContext } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { has } from "lodash";
import SearchContext from "../../context/search/searchContext";
import { SpinnerSmall } from "../../containers/Spinner";

const TileInner = ({
    header,
    body,
    slug,
    total,
    show_dates,
    loading,
    stat
}) => {
    const { startDate, endDate } = useContext(SearchContext);

    if (loading) {
        return (
            <div className="card tile__card">
                <div className="card-section tile__body">
                    <div className="tile__number">
                        {" "}
                        <SpinnerSmall />{" "}
                    </div>
                    <p className="tile__text">{header.toUpperCase()}</p>
                </div>
            </div>
        );
    }
    return (
        <div className="card tile__card">
            <div className="card-section tile__body">
                <div className="tile__number">
                    <h2 style={{ paddingBottom: "1rem" }}>
                        {has(stat, "body") === true && null != body
                            ? isNaN(body)
                                ? body
                                : Number(body).toLocaleString()
                            : "-"}
                    </h2>
                    {has(stat, "total") === true && !isNaN(total) ? (
                        <span style={{ marginTop: "-10px" }}>
                            OF: {Number(total).toLocaleString()}
                        </span>
                    ) : null}
                    {has(stat, "total") === false ? <span></span> : null}
                </div>
                <p className="tile__text">{header.toUpperCase()}</p>
                {show_dates ? (
                    <div className="tile__date">
                        &lt;
                        {` ${moment(startDate).format("DD/MM/YY")} - 
                                ${moment(endDate).format("DD/MM/YY")} `}
                        &gt;
                    </div>
                ) : (
                    <span></span>
                )}
            </div>
        </div>
    );
};

const Tile = ({
    header,
    body,
    slug,
    total,
    show_dates,
    loading,
    noLink,
    stat
}) => {
    return (
        <div className="column">
            {noLink === true ? (
                <TileInner
                    header={header}
                    body={body}
                    slug={slug}
                    total={total}
                    show_dates={show_dates}
                    loading={loading}
                    stat={stat}
                />
            ) : (
                <Link to={slug}>
                    <TileInner
                        header={header}
                        body={body}
                        slug={slug}
                        total={total}
                        show_dates={show_dates}
                        loading={loading}
                        stat={stat}
                    />
                </Link>
            )}
        </div>
    );
};

export default Tile;
