import { map, includes, filter } from "lodash";
import { checkFilter, checkArray, checkString } from "../utils/utils";

export const defaultCommunicationFilterOption = {
    Dealer: "all_dates",
    "Dealer Group": "all_dates",
    Region: "all_dates",
    Zone: "all_dates",
};

export const communicationFilterOptions = [
    {
        value: "all_dates",
        name: "DISTRIGO PERKS comms sent",
        dates: true,
        availableTo: ["Dealer", "Dealer Group", "Zone", "Region"], // d
    },
    {
        value: "opened_dates",
        name: "DISTRIGO PERKS comms opened",
        dates: true,
        availableTo: ["Zone", "Region"],
    },
    {
        value: "to_action_dates",
        name: "DISTRIGO PERKS comms to action",
        dates: true,
        availableTo: ["Dealer", "Dealer Group", "Zone", "Region"], // d
    },
    {
        value: "sale_made_dates",
        name: "Sales made",
        dates: true,
        availableTo: ["Zone", "Region", "Dealer", "Dealer Group"], // added dealer for date range total
    },
    {
        value: "sale_not_made_dates",
        name: "Sales not made",
        dates: true,
        availableTo: ["Zone", "Region", "Dealer Group", "Dealer"], // added dealer for date range total
    },
    {
        value: "to_action",
        name: "Total DISTRIGO PERKS comms to action",
        dates: false,
        availableTo: ["Dealer", "Dealer Group"],
    },
    {
        value: "to_action",
        name: "Total to action",
        dates: false,
        availableTo: ["Region", "Zone"],
    },
    // {
    // value: "sale_made",
    // name: "Total sales made",
    // dates: false,
    // availableTo: ["Dealer", "Region", "Zone"] // d
    // },
    // {
    // value: "sale_not_made",
    // name: "Total sales not made",
    // dates: false,
    // availableTo: ["Dealer", "Zone", "Region"] // d
    // }

    /*
    {
        value: "all",
        name: "All PERKS Communications Sent",
        dates: false,
        availableTo: ["Dealer", "Zone", "Region"]
    },
    {
        value: "opened",
        name: "Opened",
        dates: false,
        availableTo: ["Dealer", "Zone", "Region"]
    },
    {
        value: "clicked",
        name: "Clicked",
        dates: false,
        availableTo: ["Dealer", "Zone", "Region"]
    },
    {
        value: "clicked_dates",
        name: "Comms sent between dates that were clicked on",
        dates: true,
        availableTo: ["Dealer", "Zone", "Region"]
    },
    {
        value: "actioned",
        name: "Actioned",
        dates: false,
        availableTo: ["Dealer", "Region", "Zone"]
    },
    {
        value: "actioned_dates",
        name: "Comms sent between dates that have been Actioned",
        dates: true,
        availableTo: ["Dealer", "Zone", "Region"]
    },
	 */
];

export const communicationFilterValues = () =>
    map(communicationFilterOptions, (f) => f.value);

/**
 * check if given filter is a communcation filter value,
 * if not, return the default "all"
 *
 * @param {type} filter
 * @return {type} filter || defaultFilter
 */
export const checkCommunicationFilter = (filter) =>
    checkFilter(communicationFilterValues())(filter);

/**
 * Get available communication filter options for users group
 *
 * @param {string} groupType
 * @return {array} availableCommunicationFilterOptions
 */
export const getCommunicationFilterOptions = (groupType) =>
    filter(communicationFilterOptions, (o) =>
        includes(checkArray(o.availableTo), checkString(groupType))
    );
