import React, { Fragment, useContext, useEffect, useState } from "react";
import { map, isString } from "lodash";
import {
    checkInteger,
    checkString,
    getFilterByValue,
    prepareDateString,
} from "../../utils/utils";
import TableLayout from "../../containers/TableLayout";
import Spinner from "../../containers/Spinner";
import CustomerRow from "./CustomerRow";
import CustomerContext from "../../context/customer/customerContext";
import UserContext from "../../context/user/userContext";
import SearchContext from "../../context/search/searchContext";
import { getCustomerFilterOptions } from "../customerFilterOptions";
import GetOnFirstRender from "../GetOnFirstRender";
import SortButton from "../../components/TableHeader/SortButton";
import { TableHeaderRow } from "../../components/TableHeader/TableHeaderRow";

const CustomersTable = ({ match }) => {
    const {
        params: { filter, from, to },
    } = match;

    const {
        customersActivated: customers,
        getActivatedCustomers,
        categoryOptions,
        getCategoryOptions,
        categoryOptionsLoaded,
        loading,
        customerFilterValue,
        setCustomerFilterValue,
        customerPage,
        setCustomerPage,
        customersActivatedTotal,
    } = useContext(CustomerContext);

    const { startDateMySQL, endDateMySQL } = useContext(SearchContext);
    const { groupType, user } = useContext(UserContext);

    const [customerSearchText, setCustomerSearchText] = useState("");
    const [tradeTeamIdText, setTradeTeamIdText] = useState("");
    const [customerTypeText, setCustomerTypeText] = useState("");
    const [value, setValue] = useState("all");
    const [sortDirection, setSortDirection] = useState("asc");
    const [sortField, setSortField] = useState("trade_team_customer");
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);

    const customerFilterOptions = getCustomerFilterOptions(groupType);
    const filterObject = getFilterByValue(
        customerFilterOptions,
        customerFilterValue
    );
    useEffect(() => {
        if (initialLoadComplete) {
            handleSubmit();
        }
    }, [sortDirection, sortField]);
    //Call this function to get the data from the server when something changes
    const handleSubmit = () => {
        setCustomerPage(1);
        getItemsState();
    };
    const { dates } = filterObject;

    const apiUrl = checkString(process.env.REACT_APP_BASE_URL);

    const handleSelectChange = (event) => {
        setValue(event.target.value);
    };

    const getItemsUrl = () => {
        getCategoryOptions();
        getActivatedCustomers({
            customerFilterValue: isString(filter)
                ? filter
                : customerFilterValue,
            start: prepareDateString(from, startDateMySQL),
            end: prepareDateString(to, endDateMySQL),
            customerSearchText: customerSearchText,
            tradeTeamIdText: tradeTeamIdText,
            customerTypeText: customerTypeText,
            sortDirection: "asc",
            sortField: "trade_team_customer",
        }).then(() => {
            setInitialLoadComplete(true);
        });
    };

    const getItemsState = () => {
        getActivatedCustomers({
            customerFilterValue: customerFilterValue,
            start: startDateMySQL,
            end: endDateMySQL,
            customerSearchText: customerSearchText,
            tradeTeamIdText: tradeTeamIdText,
            customerTypeText: customerTypeText,
            sortDirection: sortDirection,
            sortField: sortField,
        });
        setInitialLoadComplete(true);
    };

    return (
        <Fragment>
            <div className="row">
                <h3 className="title pad-t">{"Customers"}</h3>
                <GetOnFirstRender
                    from={from}
                    to={to}
                    filter={filter}
                    setFilterValue={setCustomerFilterValue}
                    getItems={getItemsUrl}
                />
                <TableLayout
                    activePage={customerPage}
                    setActivePage={setCustomerPage}
                    totalItems={checkInteger(customersActivatedTotal)}
                    getItems={getItemsState}
                    setFilterValue={setCustomerFilterValue}
                    filterValue={customerFilterValue}
                    filterOptions={customerFilterOptions}
                    showDates={dates}
                    exportLink={`${apiUrl}/download/Customers/${user.uuid}/${groupType}/${startDateMySQL}/${endDateMySQL}/${customerFilterValue}`}
                >
                    <table id="customers" className="responsive" width="100%">
                        <thead>
                            <TableHeaderRow>
                                <th>
                                    <SortButton
                                        field={"trade_team_customer"}
                                        sortDirection={sortDirection}
                                        setSortDirection={setSortDirection}
                                        setSortField={setSortField}
                                        sortField={sortField}
                                    >
                                        Customer{" "}
                                    </SortButton>
                                    <br />
                                    <input
                                        type="text"
                                        value={customerSearchText}
                                        onChange={(e) =>
                                            setCustomerSearchText(
                                                e.target.value
                                            )
                                        }
                                        placeholder="Search"
                                    />
                                </th>
                                <th>
                                    <SortButton
                                        field={"trade_team_id"}
                                        sortDirection={sortDirection}
                                        setSortDirection={setSortDirection}
                                        setSortField={setSortField}
                                        sortField={sortField}
                                    >
                                        ID{" "}
                                    </SortButton>
                                    <br />
                                    <input
                                        type="text"
                                        value={tradeTeamIdText}
                                        onChange={(e) =>
                                            setTradeTeamIdText(e.target.value)
                                        }
                                        placeholder="Search"
                                    />
                                </th>
                                <th>GDPR</th>
                                <th>
                                    <SortButton
                                        field={"customer_type"}
                                        sortDirection={sortDirection}
                                        setSortDirection={setSortDirection}
                                        setSortField={setSortField}
                                        sortField={sortField}
                                    >
                                        Type{" "}
                                    </SortButton>
                                    <br />
                                    <input
                                        type="text"
                                        value={customerTypeText}
                                        onChange={(e) =>
                                            setCustomerTypeText(e.target.value)
                                        }
                                        placeholder="Search"
                                    />
                                </th>
                                <th>
                                    <SortButton
                                        field={"awarded_points"}
                                        sortDirection={sortDirection}
                                        setSortDirection={setSortDirection}
                                        setSortField={setSortField}
                                        sortField={sortField}
                                    >
                                        Points Awarded{" "}
                                    </SortButton>
                                </th>
                                <th>
                                    Category <br />
                                    <select
                                        value={value}
                                        onChange={handleSelectChange}
                                    >
                                        <option disabled selected value>
                                            {" "}
                                            -- Select An Option --{" "}
                                        </option>
                                        {map(categoryOptions, (option) => {
                                            return (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </th>
                                <th>
                                    <SortButton
                                        field={"points_claimed"}
                                        sortDirection={sortDirection}
                                        setSortDirection={setSortDirection}
                                        setSortField={setSortField}
                                        sortField={sortField}
                                    >
                                        Points Claimed{" "}
                                    </SortButton>
                                </th>
                                <th>
                                    <SortButton
                                        field={"points_expired"}
                                        sortDirection={sortDirection}
                                        setSortDirection={setSortDirection}
                                        setSortField={setSortField}
                                        sortField={sortField}
                                    >
                                        Points Expired{" "}
                                    </SortButton>
                                </th>
                                <th>
                                    <SortButton
                                        field={"points_available"}
                                        sortDirection={sortDirection}
                                        setSortDirection={setSortDirection}
                                        setSortField={setSortField}
                                        sortField={sortField}
                                    >
                                        Points Available{" "}
                                    </SortButton>
                                </th>
                                <th>Permissions</th>
                                <th>DISTRIGO PERKS</th>
                                <th>View</th>
                            </TableHeaderRow>
                        </thead>

                        {!loading && (
                            <tbody>
                                {customers.length > 0 ? (
                                    map(customers, (customer, i) => (
                                        <CustomerRow
                                            key={customer.trade_team_id}
                                            customer={customer}
                                            selectOption={value}
                                        />
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="12">No customers found</td>
                                    </tr>
                                )}
                            </tbody>
                        )}
                    </table>
                    {loading || categoryOptionsLoaded ? <Spinner /> : null}
                </TableLayout>
            </div>
        </Fragment>
    );
};

export default CustomersTable;
