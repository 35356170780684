import React, { Fragment, useEffect, useContext } from "react";
import moment from "moment";
import { concat } from "lodash";
import Tile from "../components/Tile/Tile";
import TableHeader from "../components/TableHeader";
import Promotions from "../components/Promotions";
import LeadContext from "../context/lead/leadContext";
import UserContext from "../context/user/userContext";
import CommunicationContext from "../context/communication/communicationContext";
import CustomerContext from "../context/customer/customerContext";
import SearchContext from "../context/search/searchContext";
import PointContext from "../context/point/pointContext";
import RewardContext from "../context/reward/rewardContext";
import ReportContext from "../context/report/reportContext";
import { displayPercentage } from "../utils/utils";
import { DATA_START_DATE, CURRENT_DATE } from "../config/constants";

const Summary = ({ match }) => {
    const { path } = match;
    const { reports, getReports, loading: reportsLoading } = useContext(
        ReportContext
    );
    const { groupType } = useContext(UserContext);
    const { getLeadCounts, leadCounts, loading: leadsLoading } = useContext(
        LeadContext
    );
    const {
        getRewardCounts,
        rewardCounts,
        loading: rewardsLoading,
    } = useContext(RewardContext);

    const {
        getCommunicationCounts,
        communicationCounts,
        loading: communicationLoading,
    } = useContext(CommunicationContext);
    const {
        getCustomerCounts,
        customerCounts,
        getMarketingCampaignsDealer,
        marketingCampaignsDealer,
        loading: customerLoading,
    } = useContext(CustomerContext);
    const { startDateMySQL, endDateMySQL } = useContext(SearchContext);
    const {
        getTotalPointsAwarded,
        getPointsAwarded,
        getTotalPointsRedeemed,
        getPointsRedeemed,
        getTotalPointsExpired,
        pointsTotalAwarded,
        pointsAwarded,
        pointsTotalRedeemed,
        pointsRedeemed,
        pointsTotalActive,
        pointsTotalExpired,
        loading: pointsLoading,
    } = useContext(PointContext);

    const getSummaryItems = () => {
        getReports(); // report state
        getLeadCounts(); // lead state
        getRewardCounts(); // reward state
        getCommunicationCounts(); // comms state

        getCustomerCounts(); // customer state
        getMarketingCampaignsDealer(); // customer state

        getTotalPointsAwarded(); // point state
        getPointsAwarded(); // point state
        getTotalPointsRedeemed(); // point state
        getPointsRedeemed(); // point state
        getTotalPointsExpired(); // point state
    };

    const isLoading = () => {
        if (
            pointsLoading === true ||
            reportsLoading === true ||
            leadsLoading === true ||
            communicationLoading === true ||
            customerLoading === true ||
            rewardsLoading === true
        ) {
            return true;
        } else {
            return false;
        }
    };
    const loading = isLoading();

    useEffect(() => {
        getSummaryItems();
    }, []);

    if (!groupType) {
        return (
            <Fragment>
                <div className="stripe--light-gray stripe--large">
                    <div className="row column">
                        <TableHeader
                            changeDatesOnly={true}
                            useFilterOptions={false}
                            path={path}
                            title=""
                            getItems={getSummaryItems}
                        />
                        <Promotions
                            marketingCampaigns={marketingCampaignsDealer}
                        />
                    </div>
                </div>
                <div className="stripe--gray stripe--large">
                    <div className="row column">
                        <h3>Loading Data</h3>
                    </div>
                </div>
            </Fragment>
        );
    }

    let leadsData = [
        {
            header: "Leads Received",
            body: leadCounts["receivedDate"],
            total: leadCounts["receivedTotal"],
            slug: `/lead-dealers/all-dates/${startDateMySQL}/${endDateMySQL}`,
            show_dates: true,
        },
        {
            header: "Leads Still To Be Actioned",
            body: leadCounts["notActionedDate"],
            total: leadCounts["notActionedTotal"],
            slug: `/lead-dealers/not-actioned-dates/${startDateMySQL}/${endDateMySQL}`,
            show_dates: true,
        },
        {
            header: "Sales Made",
            body: leadCounts["saleMadeDate"],
            total: leadCounts["saleMadeTotal"],
            slug: `/lead-dealers/sale-made-dates/${startDateMySQL}/${endDateMySQL}`,
            show_dates: true,
        },
        {
            header: "Sales Not Made",
            body: leadCounts["saleNotMadeDate"],
            total: leadCounts["saleNotMadeTotal"],
            slug: `/lead-dealers/sale-not-made-dates/${startDateMySQL}/${endDateMySQL}`,
            show_dates: true,
        },
        {
            header: "Total Leads Received",
            body: leadCounts["receivedTotal"],
            //slug: "/lead-dealers/all",
            slug: `/lead-dealers/all-dates/${DATA_START_DATE}/${CURRENT_DATE}`,
            show_dates: false,
        },
        {
            header: "Total Leads Still To Be Actioned",
            body: leadCounts["notActionedTotal"],
            //slug: "/lead-dealers/not-actioned",
            slug: `/lead-dealers/not-actioned-dates/${DATA_START_DATE}/${CURRENT_DATE}`,
            show_dates: false,
        },
        {
            header: "Total Sales Made",
            body: leadCounts["saleMadeTotal"],
            //slug: "/lead-dealers/sale-made",
            slug: `/lead-dealers/sale-made-dates/${DATA_START_DATE}/${CURRENT_DATE}`,
            show_dates: false,
        },
        {
            header: "Total Sales Not Made",
            body: leadCounts["saleNotMadeTotal"],
            //slug: "/lead-dealers/sale-not-made",
            slug: `/lead-dealers/sale-not-made-dates/${DATA_START_DATE}/${CURRENT_DATE}`,
            show_dates: false,
        },
    ];
    let leadsClassName = "large-up-4";

    if (groupType === "Dealer") {
        leadsData = [
            {
                header: "Leads Received",
                body: leadCounts["receivedDate"],
                total: leadCounts["receivedTotal"],
                slug: `/leads/all-dates/${startDateMySQL}/${endDateMySQL}`,
                show_dates: true,
            },
            {
                header: "Leads Actioned",
                body: leadCounts["actionedDate"],
                total: leadCounts["actionedTotal"],
                slug: `/leads/actioned-dates/${startDateMySQL}/${endDateMySQL}`,
                show_dates: true,
            },
            {
                header: "Leads Still To Be Actioned",
                body: leadCounts["notActionedDate"],
                total: leadCounts["notActionedTotal"],
                show_dates: true,
                slug: `/leads/not-actioned-dates/${startDateMySQL}/${endDateMySQL}`,
                // slug: `/leads/not-actioned`,
                // show_dates: false
            },

            {
                header: "Total Leads Received",
                body: leadCounts["receivedTotal"],
                //slug: "/leads/all",
                slug: `/leads/all-dates/${DATA_START_DATE}/${CURRENT_DATE}`,
                show_dates: false,
            },
            {
                header: "Total Leads Actioned",
                body: leadCounts["actionedTotal"],
                //slug: "/leads/actioned",
                slug: `/leads/actioned-dates/${DATA_START_DATE}/${CURRENT_DATE}`,
                show_dates: false,
            },
            {
                header: "Total Leads Still To Be Actioned",
                body: leadCounts["notActionedTotal"],
                //slug: "/leads/not-actioned",
                slug: `/leads/not-actioned-dates/${DATA_START_DATE}/${CURRENT_DATE}`,
                show_dates: false,
            },
        ];
        leadsClassName = "large-up-3";
    }

    let customerData = [
        {
            header: "Total Customers in Data Centre",
            body: customerCounts["totalCustomerCount"],
            slug: "/customer-dealers/all",
            show_dates: false,
            noLink: true,
        },
        {
            header: "Total Customers Eligible to Join",
            body: customerCounts["totalTier2CustomerCount"],
            slug: "/customer-dealers/all",
            show_dates: false,
        },
        {
            header: "Total Customers to be activated",
            body: customerCounts["customersNotInPerksCustomerCount"],
            slug: "/customer-dealers/not-perks",
            show_dates: false,
        },
        {
            header: "Total Customers Activated",
            body: customerCounts["perksCustomerCount"],
            //slug: "/customer-dealers/all-perks",
            slug: `/customer-dealers/all-perks-dates/${DATA_START_DATE}/${CURRENT_DATE}`,
            show_dates: false,
        },
        {
            header: "Customers Activated",
            body: customerCounts["perksDatesCustomerCount"],
            total: customerCounts["perksCustomerCount"],
            slug: `/customer-dealers/all-perks-dates/${startDateMySQL}/${endDateMySQL}`,
            show_dates: true,
        },
        {
            header: "Total Unverified Customers",
            body: customerCounts["unverifiedPerksCount"],
            slug: "/customer-dealers/all-perks-unverified",
            show_dates: false,
        },
        {
            //No link to unsubscribe individual data for head office - deliberately going to perks data
            header: "Total Customers Unsubscribed",
            body: customerCounts["unsubscribedCustomersCount"],
            slug: "/customer-dealers/all-perks",
            show_dates: false,
            noLink: true,
        },
        {
            //No link to unsubscribe individual data for head office - deliberately going to perks data
            header: "Customers Unsubscribed",
            body: customerCounts["unsubscribedDatesCustomersCount"],
            total: customerCounts["unsubscribedCustomersCount"],
            slug: `/customer-dealers/all-perks-dates/${startDateMySQL}/${endDateMySQL}`,
            show_dates: true,
            noLink: true,
        },
    ];

    if (groupType === "Dealer") {
        customerData = [
            {
                header: "Total Customers Activated",
                body: customerCounts["perksCustomerCount"],
                //slug: "/customers/all-perks",
                slug: `/customers/all-perks-dates/${DATA_START_DATE}/${CURRENT_DATE}`,
                show_dates: false,
            },
            {
                header: "Total Customers to be activated",
                body: customerCounts["customersNotInPerksCustomerCount"],
                slug: "/customers/not-perks",
                show_dates: false,
            },
            {
                header: "Total Contactable Customers",
                body: customerCounts["contactablePerksCount"],
                slug: "/customers/all-perks-contactable",
                show_dates: false,
            },
            {
                header: "Customers activated",
                body: customerCounts["perksDatesCustomerCount"],
                total: customerCounts["perksCustomerCount"],
                slug: `/customers/all-perks-dates/${startDateMySQL}/${endDateMySQL}`,
                show_dates: true,
            },
            {
                header: "Unverified Customers",
                body: customerCounts["unverifiedPerksCount"],
                slug: "/customers/all-perks-unverified",
                show_dates: false,
            },
            {
                header: "Potential Customers",
                body: customerCounts["potentialCustomersCount"],
                slug: "/potential-customers",
                show_dates: false,
            },
            {
                header: "Total Customers Unsubscribed",
                body: customerCounts["unsubscribedCustomersCount"],
                slug: "/reports",
                show_dates: false,
            },
            {
                header: "Customers Unsubscribed",
                body: customerCounts["unsubscribedDatesCustomersCount"],
                total: customerCounts["unsubscribedCustomersCount"],
                slug: `/reports`,
                show_dates: true,
            },
        ];
    }

    let commsData = [
        {
            header: "Total Contactable Customers",
            body: customerCounts["contactablePerksCount"],
            slug: "/customer-dealers/all-perks-contactable",
            show_dates: false,
        },
        {
            header: "DISTRIGO PERKS Comms Sent",
            body: communicationCounts["sentDatesCount"],
            total: communicationCounts["sentCount"],
            slug: `/communication-dealers/all-dates/${startDateMySQL}/${endDateMySQL}`,
            show_dates: true,
        },
        {
            header: "DISTRIGO PERKS Comms Opened",
            body: communicationCounts["openedDatesCount"],
            total: communicationCounts["openedCount"],
            slug: `/communication-dealers/opened-dates/${startDateMySQL}/${endDateMySQL}`,
            show_dates: true,
        },
        {
            header: "DISTRIGO PERKS Comms Open Rate",
            body: displayPercentage(communicationCounts["openDatesRate"]),
            slug: `/communication-dealers/opened-dates/${startDateMySQL}/${endDateMySQL}`,
            show_dates: true,
        },
    ];
    if (groupType === "Dealer") {
        commsData = [
            {
                header: "DISTRIGO PERKS Comms Sent",
                body: communicationCounts["sentDatesCount"],
                total: communicationCounts["sentCount"],
                slug: `/communications/all-dates/${startDateMySQL}/${endDateMySQL}`,
                show_dates: true,
            },
            {
                header: "Total DISTRIGO PERKS Comms to Action",
                body: communicationCounts["toActionCount"],
                slug: `/communications/to-action`,
                show_dates: false,
            },
        ];
    }

    let commsActionData = [
        {
            header: "DISTRIGO PERKS Comms To Action",
            body: communicationCounts["toActionDatesCount"],
            total: communicationCounts["toActionCount"],
            slug: `/communication-dealers/to-action-dates/${startDateMySQL}/${endDateMySQL}`,
            show_dates: true,
        },
        {
            header: "Sales Made",
            body: communicationCounts["saleMadeDatesCount"],
            total: communicationCounts["saleMadeCount"],
            slug: `/communication-dealers/sale-made-dates/${startDateMySQL}/${endDateMySQL}`,
            show_dates: true,
        },
        {
            header: "Sales Not Made",
            body: communicationCounts["saleNotMadeDatesCount"],
            total: communicationCounts["saleNotMadeCount"],
            slug: `/communication-dealers/sale-not-made-dates/${startDateMySQL}/${endDateMySQL}`,
            show_dates: true,
        },
        {
            header: "Total To Action",
            body: communicationCounts["toActionCount"],
            slug: "/communication-dealers/to-action",
            show_dates: false,
        },
        {
            header: "Total Sales Made",
            body: communicationCounts["saleMadeCount"],
            //slug: "/communication-dealers/sale-made",
            slug: `/communication-dealers/sale-made-dates/${DATA_START_DATE}/${CURRENT_DATE}`,
            show_dates: false,
        },
        {
            header: "Total Sales Not Made",
            body: communicationCounts["saleNotMadeCount"],
            //slug: "/communication-dealers/sale-not-made",
            slug: `/communication-dealers/sale-not-made-dates/${DATA_START_DATE}/${CURRENT_DATE}`,
            show_dates: false,
        },
    ];
    if (groupType === "Dealer") {
        // combine the 2 arrays together for dealer so tiles sit
        // on a single line
        commsActionData = [
            {
                header: "Total Sales Made",
                body: communicationCounts["saleMadeCount"],
                //slug: "/communications/sale-made",
                slug: `/communications/sale-made-dates/${DATA_START_DATE}/${CURRENT_DATE}`,
                show_dates: false,
            },
            {
                header: "Total Sales Not Made",
                body: communicationCounts["saleNotMadeCount"],
                //slug: "/communications/sale-not-made",
                slug: `/communications/sale-not-made-dates/${DATA_START_DATE}/${CURRENT_DATE}`,
                show_dates: false,
            },
        ];
    }

    let pointsData = [
        {
            header: "Total Points Awarded",
            body: pointsTotalAwarded,
            slug: "/customer-dealers/all-perks",
            show_dates: false,
        },
        {
            header: "Total Points Redeemed",
            body: pointsTotalRedeemed,
            slug: "/customer-dealers/all-perks",
            show_dates: false,
        },
        {
            header: "Total Points Live",
            body: pointsTotalActive,
            slug: "/customer-dealers/all-perks",
            show_dates: false,
        },
        {
            header: "Total Points Expired",
            body: pointsTotalExpired,
            slug: "/customer-dealers/all-perks",
            show_dates: false,
        },
        {
            header: "Points Awarded",
            body: pointsAwarded,
            total: pointsTotalAwarded,
            slug: `/customer-dealers/all-perks-points-dates/${startDateMySQL}/${endDateMySQL}`,
            show_dates: true,
        },
        {
            header: "Points Redeemed",
            body: pointsRedeemed,
            total: pointsTotalRedeemed,
            slug: `/customer-dealers/all-perks-points-dates/${startDateMySQL}/${endDateMySQL}`,
            show_dates: true,
        },
    ];
    if (groupType === "Dealer") {
        pointsData = [
            {
                header: "Points Awarded",
                body: pointsAwarded,
                total: pointsTotalAwarded,
                slug: `/customers/all-perks-points-dates/${startDateMySQL}/${endDateMySQL}`,
                show_dates: true,
            },
            {
                header: "Points Redeemed",
                body: pointsRedeemed,
                total: pointsTotalRedeemed,
                slug: `/customers/all-perks-points-dates/${startDateMySQL}/${endDateMySQL}`,
                show_dates: true,
            },
            {
                header: "Total Points Awarded",
                body: pointsTotalAwarded,
                slug: "/customers/all-perks",
                show_dates: false,
            },
            {
                header: "Total Points Redeemed",
                body: pointsTotalRedeemed,
                slug: "/customers/all-perks",
                show_dates: false,
            },
        ];
    }

    if (groupType === "Dealer") {
        commsData = concat(commsData, commsActionData);
    }

    let rewardsData = [
        {
            header: "Total number of redemptions",
            body: rewardCounts["totalCount"],
            slug: `/reward-dealers/all-dates/${DATA_START_DATE}/${CURRENT_DATE}`,
            show_dates: false,
        },
        {
            header: "Number of redemptions",
            body: rewardCounts["totalDatesCount"],
            total: rewardCounts["totalCount"],
            slug: `/reward-dealers/all-dates/${startDateMySQL}/${endDateMySQL}`,
            show_dates: true,
        },
        {
            header: "Total number of Amazon redemptions",
            body: rewardCounts["amazonCount"],
            slug: `/reward-dealers/all-dates/${DATA_START_DATE}/${CURRENT_DATE}`,
            show_dates: false,
        },
        {
            header: "Number of Amazon redemptions",
            body: rewardCounts["amazonDatesCount"],
            total: rewardCounts["amazonCount"],
            slug: `/reward-dealers/all-dates/${startDateMySQL}/${endDateMySQL}`,
            show_dates: true,
        },
        {
            header: "Total number of snap-on redemptions",
            body: rewardCounts["snaponCount"],
            slug: `/reward-dealers/all-dates/${DATA_START_DATE}/${CURRENT_DATE}`,
            show_dates: false,
        },
        {
            header: "Number of snap-on redemptions",
            body: rewardCounts["snaponDatesCount"],
            total: rewardCounts["snaponCount"],
            slug: `/reward-dealers/all-dates/${startDateMySQL}/${endDateMySQL}`,
            show_dates: true,
        },
        {
            header: "Total number of credit redemptions",
            body: rewardCounts["creditCount"],
            slug: `/reward-dealers/all-dates/${DATA_START_DATE}/${CURRENT_DATE}`,
            show_dates: false,
        },
        {
            header: "Number of credit redemptions",
            body: rewardCounts["creditDatesCount"],
            total: rewardCounts["creditCount"],
            slug: `/reward-dealers/all-dates/${startDateMySQL}/${endDateMySQL}`,
            show_dates: true,
        },
    ];

    if (groupType === "Dealer") {
        rewardsData = [];
    }

    return (
        <Fragment>
            <div className="stripe--light-gray stripe--large">
                <div className="row column">
                    <TableHeader
                        useFilterOptions={false}
                        path={path}
                        title=""
                        getItems={getSummaryItems}
                    />
                </div>
                <div className="row">
                    <Promotions marketingCampaigns={marketingCampaignsDealer} />
                </div>
            </div>
            <div className="stripe--gray stripe--large">
                <div className="row column">
                    <h3 className="title pad-b">Customer Data - Leads</h3>
                    <div
                        style={{ maxWidth: "1045px" }}
                        className={`row small-up-1 medium-up-2 ${leadsClassName}`}
                    >
                        {leadsData.map((stat, i) => {
                            return (
                                <Tile
                                    key={i}
                                    header={stat.header}
                                    body={stat.body}
                                    slug={stat.slug}
                                    total={stat.total}
                                    show_dates={stat.show_dates}
                                    loading={leadsLoading}
                                    noLink={stat.noLink}
                                    stat={stat}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="stripe--light-gray stripe--large">
                <div className="row column">
                    <h3 className="title pad-b">
                        Customer Data - DISTRIGO PERKS
                    </h3>
                    <h4 className="title">Customer Numbers</h4>
                    <div className="row small-up-1 medium-up-2 large-up-5">
                        {customerData.map((stat, i) => {
                            return (
                                <Tile
                                    key={i}
                                    header={stat.header}
                                    body={stat.body}
                                    slug={stat.slug}
                                    total={stat.total}
                                    show_dates={stat.show_dates}
                                    loading={customerLoading}
                                    noLink={stat.noLink}
                                    stat={stat}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className="row column">
                    <h4 className="title">DISTRIGO PERKS Communications</h4>
                    <div className="row small-up-1 medium-up-2 large-up-5">
                        {commsData.map((stat, i) => {
                            return (
                                <Tile
                                    key={i}
                                    header={stat.header}
                                    body={stat.body}
                                    slug={stat.slug}
                                    total={stat.total}
                                    show_dates={stat.show_dates}
                                    loading={communicationLoading}
                                    noLink={stat.noLink}
                                    stat={stat}
                                />
                            );
                        })}
                    </div>
                    {groupType !== "Dealer" && (
                        <div
                            className="row small-up-1 medium-up-2 large-up-3"
                            style={{
                                maxWidth: "778px",
                            }}
                        >
                            {commsActionData.map((stat, i) => {
                                return (
                                    <Tile
                                        key={i}
                                        header={stat.header}
                                        body={stat.body}
                                        slug={stat.slug}
                                        total={stat.total}
                                        show_dates={stat.show_dates}
                                        loading={communicationLoading}
                                        noLink={stat.noLink}
                                        stat={stat}
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>
                <div className="row column">
                    <h4 className="title">DISTRIGO PERKS Points</h4>
                    <div
                        className="row small-up-1 medium-up-2 large-up-4"
                        style={{ maxWidth: "1045px" }}
                    >
                        {pointsData.map((stat, i) => {
                            return (
                                <Tile
                                    key={i}
                                    header={stat.header}
                                    body={stat.body}
                                    slug={stat.slug}
                                    total={stat.total}
                                    show_dates={stat.show_dates}
                                    loading={pointsLoading}
                                    noLink={stat.noLink}
                                    stat={stat}
                                />
                            );
                        })}
                    </div>
                </div>
                {groupType !== "Dealer" && (
                    <div className="row column">
                        <h4 className="title">DISTRIGO PERKS Rewards</h4>
                        <div
                            className="row small-up-1 medium-up-2 large-up-4"
                            style={{ maxWidth: "1045px" }}
                        >
                            {rewardsData.map((stat, i) => {
                                return (
                                    <Tile
                                        key={i}
                                        header={stat.header}
                                        body={stat.body}
                                        slug={stat.slug}
                                        total={stat.total}
                                        show_dates={stat.show_dates}
                                        loading={rewardsLoading}
                                        noLink={stat.noLink}
                                        stat={stat}
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default Summary;
