import React, { Fragment, useContext } from "react";
import { includes } from "lodash";
import { __RouterContext } from "react-router-dom";
import NavLink from "../components/NavLink";
import { checkString } from "../utils/utils";
import ExternalLink from "../components/ExternalLink";
import SearchContext from "../context/search/searchContext";
import { defaultLeadFilterOption } from "../tables/leadFilterOptions";
import { defaultCustomerFilterOption } from "../tables/customerFilterOptions";
import { defaultCommunicationFilterOption } from "../tables/communicationFilterOptions";

const Navbar = ({ groupType }) => {
    const {
        location: { pathname }
    } = useContext(__RouterContext);
    const { startDateMySQL, endDateMySQL } = useContext(SearchContext);

    const createNavLink = (page, filter) => {
        return `${page}/${filter}/${startDateMySQL}/${endDateMySQL}`;
    };

    const leadsLink =
        groupType === "Dealer"
            ? createNavLink("/leads", defaultLeadFilterOption[groupType])
            : createNavLink(
                  "/lead-dealers",
                  defaultLeadFilterOption[groupType]
              );

    const customersLink =
        groupType === "Dealer"
            ? createNavLink(
                  "/customers",
                  defaultCustomerFilterOption[groupType]
              )
            : createNavLink(
                  "/customer-dealers",
                  defaultCustomerFilterOption[groupType]
              );
    const potentialCustomersLink =
        groupType === "Dealer"
            ? "/potential-customers"
            : "/potential-customer/dealers";

    const communicationsLink =
        groupType === "Dealer"
            ? createNavLink(
                  "/communications",
                  defaultCommunicationFilterOption[groupType]
              )
            : createNavLink(
                  "/communication-dealers",
                  defaultCommunicationFilterOption[groupType]
              );

    const rewardsLink = groupType === "Dealer" ? "/rewards" : "/reward-dealers";

    const distrigoUrl = checkString(process.env.REACT_APP_DISTRIGO_URL);

    return (
        <div className="navigation" id="main-navigation">
            <div className="row">
                <div className="menu-desktop">
                    <div className="navigation__inner">
                        <ul className="menu">
                            <li>
                                <ExternalLink href={distrigoUrl}>
                                    &lt; Back to Distrigo
                                </ExternalLink>
                            </li>
                            <NavLink
                                highlight={pathname === "/"}
                                to="/"
                                name="Summary"
                            />
                            <NavLink
                                highlight={includes(pathname, "lead")}
                                to={leadsLink}
                                name="Leads"
                            />
                            <NavLink
                                highlight={
                                    includes(pathname, "potential-customer") ===
                                        false &&
                                    includes(pathname, "customer") === true
                                }
                                to={customersLink}
                                name="Customers"
                            />
                            {groupType === "Dealer" && (
                                <NavLink
                                    highlight={includes(
                                        pathname,
                                        "potential-customer"
                                    )}
                                    to={potentialCustomersLink}
                                    name="Potential Customers"
                                />
                            )}
                            <NavLink
                                highlight={includes(pathname, "communication")}
                                to={communicationsLink}
                                name="Communications"
                            />
                            {groupType !== 'Dealer' &&
                                <NavLink
                                    highlight={includes(pathname, "reward")}
                                    to={rewardsLink}
                                    name="Rewards"
                                />
                            }
                            <NavLink
                                highlight={includes(pathname, "report")}
                                to="/reports"
                                name="Reports"
                            />
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
