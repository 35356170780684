import { get } from "lodash";
import {
    getArray,
    getInteger,
    checkInteger,
    checkString
} from "../../utils/utils";
import {
    SET_LOADING,
    GET_REWARD_COUNTS,
    GET_REWARDS,
    GET_DEALER_REWARDS,
    GET_REWARD_DEALERS,
    GET_REWARD,
    GET_REWARD_PAGE,
    REWARD_STATUS_UPDATED,
    SET_ACTIVE_PAGE_VALUE,
    SET_REWARD_DEALERS_PAGE,
    SET_DEALER_REWARDS_PAGE,
    SET_REWARD_FILTER_VALUE,
    SET_REWARD_PAGE
} from "../types";

export default (state, action) => {
    switch (action.type) {
        case SET_REWARD_PAGE:
            return {
                ...state,
                rewardsPage: checkInteger(action.payload)
            };

        case SET_REWARD_FILTER_VALUE:
            return {
                ...state,
                rewardFilterValue: checkString(action.payload)
            };

        case SET_DEALER_REWARDS_PAGE:
            return {
                ...state,
                dealerRewardsPage: checkInteger(action.payload)
            };

        case SET_REWARD_DEALERS_PAGE:
            return {
                ...state,
                rewardDealersPage: checkInteger(action.payload)
            };

        case SET_ACTIVE_PAGE_VALUE: // deprecated?
            return {
                ...state,
                activePage: action.payload
            };

        /* Deprecated ?
        case GET_TOTAL_REWARDS:
            return {
                ...state,
                rewardTotalCount: getInteger(action, "payload.data.count"),
                loading: false
            };
			*/

        case GET_REWARDS:
            return {
                ...state,
                rewards: getArray(action, "payload.data"),
                rewardCount: getInteger(action, "payload.meta.total"),
                rewardLinks: get(action, "payload.links", {}),
                loading: false
            };
        case GET_REWARD_COUNTS:
            return {
                ...state,
                rewardCounts: get(action, "payload.data", {}),
                loading: false
            };
        case GET_DEALER_REWARDS:
            return {
                ...state,
                rewards: getArray(action, "payload.data"),
                rewardCount: getInteger(action, "payload.meta.total"),
                rewardLinks: get(action, "payload.links", {}),
                loading: false
            };
        case GET_REWARD_DEALERS:
            return {
                ...state,
                rewardDealers: get(action, "payload.data", {}),
                rewardDealerCount: getInteger(action, "payload.meta.total"),
                rewardDealerLinks: get(action, "payload.links", {}),
                loading: false
            };
        case GET_REWARD_PAGE:
            return {
                ...state,
                rewards: checkInteger(action.payload),
                loading: false
            };
        case GET_REWARD:
            return {
                ...state,
                reward: action.payload,
                loading: false
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true
            };

        case REWARD_STATUS_UPDATED:
            return {
                ...state,
                reward: action.payload
            };

        default:
            return state;
    }
};
