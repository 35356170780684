import React, { Fragment, useContext, useEffect, useState } from "react";
import TableLayout from "../../containers/TableLayout";
import RewardsRow from "../RewardsTable/RewardsRow";
import RewardContext from "../../context/reward/rewardContext";
import DealerContext from "../../context/dealer/dealerContext";
import Spinner from "../../containers/Spinner";
import { map, isString } from "lodash";
import { checkArray, getFilterByValue } from "../../utils/utils";
import { checkInteger, parseInteger } from "../../utils/utils";
import { rewardFilterOptions } from "../rewardFilterOptions";
import GetOnFirstRender from "../GetOnFirstRender";
import UserContext from "../../context/user/userContext";

export default function DealerRewardsTable({ match }) {
    const {
        params: { filter, dealer_id: dealerId, from, to }
    } = match;

    const {
        rewards,
        rewardCount,
        getDealerRewards,
        loading,
        setDealerRewardsPage,
        dealerRewardsPage,
        setRewardFilterValue,
        setRewardFilterOrDefault,
        rewardFilterValue
    } = useContext(RewardContext);

    const { dealer, getDCDealer, dealerLoading } = useContext(DealerContext);
    const { groupType } = useContext(UserContext);

    const filterObject = getFilterByValue(rewardFilterOptions, rewardFilterValue);
    const { dates } = filterObject;

    const title = dealer && dealer.name ? `Rewards > ${dealer.name}` : "Rewards";

    const getItemsUrl = () => {
        getDCDealer(parseInteger(dealerId));
        getDealerRewards({
            rewardFilterValue: isString(filter) ? filter : rewardFilterValue,
            id: parseInteger(dealerId)
        });
    };

    const getItemsState = () => {
        getDealerRewards({
            rewardFilterValue: rewardFilterValue,
            id: parseInteger(dealerId)
        });
    };

    return (
        <Fragment>
            <div className="row">
                <h3 className="title pad-t">{title}</h3>
                <GetOnFirstRender
                    setFilterValue={setRewardFilterValue}
                    from={from}
                    to={to}
                    filter={filter}
                    getItems={getItemsUrl}
                />
                <TableLayout
                    activePage={dealerRewardsPage}
                    setActivePage={setDealerRewardsPage}
                    totalItems={checkInteger(rewardCount)}
                    getItems={getItemsState}
                    setFilterValue={setRewardFilterValue}
                    filterValue={rewardFilterValue}
                    filterOptions={rewardFilterOptions}
                    showDates={dates}
                >
                    <table
                        id="dealer-rewards"
                        className="responsive"
                        width="100%"
                    >
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Customer</th>
                                <th>ID</th>
                                <th>Reward Type</th>
                                <th>Code</th>
                                <th>Value</th>
                                <th>Created At</th>
                                <th>Expires At</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading &&
                                checkArray(rewards) &&
                                map(rewards, (reward, i) => (
                                    <RewardsRow
                                        key={i}
                                        reward={reward}
                                        groupType={groupType}
                                    />
                                ))}
                        </tbody>
                    </table>
                    {loading ? <Spinner /> : null}
                </TableLayout>
            </div>
        </Fragment>
    );
}
