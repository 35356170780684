import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
    checkBool,
    replaceUnderscores
} from "../../../utils/utils";
import SearchContext from "../../../context/search/searchContext";

export default function HeadOfficeRewardRow({
    rewardDealer,
    filterValue: filterValueRaw,
    filterObject
}) {
    const { startDateMySQL, endDateMySQL } = useContext(SearchContext);

    const filterValue = replaceUnderscores(filterValueRaw);
    const { dates } = filterObject;

    const link = checkBool(dates)
        ? `/reward/dealer/${rewardDealer.id}/${filterValue}/${startDateMySQL}/${endDateMySQL}`
        : `/reward/dealer/${rewardDealer.id}/${filterValue}`;

    return (
        <tr key={rewardDealer.id}>
            <td className="PD">
                <Link to={link}>{rewardDealer.dealer_name}</Link>
            </td>
            <td className="PD">{rewardDealer.dealer_code.replace("HUB_", "")}</td>
            <td className="PD">{rewardDealer.total_amazon}</td>
            <td className="PD">{rewardDealer.total_credit}</td>
            <td className="PD">{rewardDealer.total_snapon}</td>
            <td className="PD">{rewardDealer.total_rewards}</td>
            <td className="PD">
                {rewardDealer.total_points
                    ? Number(rewardDealer.total_points).toLocaleString()
                    : 0}
            </td>
            <td className="PD" className="rewards__buttons">
                <Link to={link}>
                    <button className="button button--black">View </button>
                </Link>
            </td>
        </tr>
    );
}
