import React, { Fragment } from "react";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { formatDate, parseDate } from "react-day-picker/moment";
import { getLocalStorage } from "../utils/utils";

class FromToPicker extends React.Component {
    constructor(props) {
        super(props);

        let s = getLocalStorage("startDate")
            ? new Date(getLocalStorage("startDate"))
            : this.props.start;
        let t = getLocalStorage("endDate")
            ? new Date(getLocalStorage("endDate"))
            : this.props.end;
        this.state = {
            from: s,
            to: t
        };
    }

    setStart = date => {
        this.setState({ from: date });
        this.props.setStart(date);
        window.localStorage.setItem("startDate", date);
    };
    setEnd = date => {
        this.setState({ to: date });
        this.props.setEnd(date);
        window.localStorage.setItem("endDate", date);
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            nextProps.start !== prevState.from ||
            nextProps.end !== prevState.to
        ) {
            return { from: nextProps.start, to: nextProps.end };
        } else return null;
    }

    render() {
        const { from, to } = this.state;
        const modifiers = { start: from, end: to };

        return (
            <Fragment>
                <b>DATE FROM</b>
                <div className="InputFromTo">
                    <DayPickerInput
                        style={{
                            background: "#ffffff",
                            marginLeft: "10px",
                            marginRight: "10px"
                        }}
                        value={from}
                        placeholder="From"
                        format="LL"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        dayPickerProps={{
                            selectedDays: { from, to },
                            toMonth: to,
                            modifiers,
                            numberOfMonths: 2,
                            onDayClick: () => this.to.getInput().focus()
                        }}
                        onDayChange={this.setStart}
                    />{" "}
                    <b>DATE TO</b>{" "}
                    <span
                        className="InputFromTo-to"
                        style={{ background: "#ffffff", marginLeft: "10px" }}
                    >
                        <DayPickerInput
                            ref={el => (this.to = el)}
                            value={to}
                            placeholder="To"
                            format="LL"
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                                selectedDays: { from, to },
                                modifiers,
                                month: from,
                                fromMonth: from,
                                numberOfMonths: 2
                            }}
                            onDayChange={this.setEnd}
                        />
                    </span>
                </div>
            </Fragment>
        );
    }
}
export default FromToPicker;
