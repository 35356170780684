import { getInteger, checkInteger } from "../../utils/utils";
import { get } from "lodash";
import {
    SET_LOADING,
    GET_COMMUNICATION_COUNTS,
    GET_COMMUNICATION_DEALERS,
    GET_DEALER_COMMUNICATIONS,
    GET_DEALER_COMMUNICATION_RECIPIENTS,
    GET_COMMUNICATIONS,
    GET_COMMUNICATION_RECIPIENTS,
    GET_COMMUNICATION_RECIPIENT,
    SET_COMMUNICATION_FILTER_VALUE,
    SET_DEALER_COMMUNICATIONS_PAGE,
    SET_DEALER_COMMUNICATION_RECIPIENTS_PAGE,
    SET_COMMUNICATION_DEALERS_PAGE,
    COMMUNICATION_STATUS_UPDATED
} from "../types";

export default (state, action) => {
    switch (action.type) {
        case COMMUNICATION_STATUS_UPDATED:
            return {
               ...state,
					communicationRecipient: action.payload
            };

        case SET_COMMUNICATION_DEALERS_PAGE:
            return {
                ...state,
                communicationDealersPage: checkInteger(action.payload)
            };
        case SET_DEALER_COMMUNICATION_RECIPIENTS_PAGE:
            return {
                ...state,
                dealerCommunicationRecipientsPage: checkInteger(action.payload)
            };
        case SET_DEALER_COMMUNICATIONS_PAGE:
            return {
                ...state,
                dealerCommunicationsPage: checkInteger(action.payload)
            };
        case SET_COMMUNICATION_FILTER_VALUE:
            return {
                ...state,
                communicationFilterValue: action.payload
            };

        case GET_COMMUNICATION_COUNTS:
            return {
                ...state,
                communicationCounts: get(action, "payload.data", {}),
                loading: false
            };
        case GET_COMMUNICATION_DEALERS:
            return {
                ...state,
                communicationDealers: get(action, "payload.data", {}),
                communicationDealerCount: getInteger(
                    action,
                    "payload.meta.total"
                ),
                communicationDealerLinks: get(action, "payload.links"),
                loading: false
            };
        case GET_DEALER_COMMUNICATIONS:
            return {
                ...state,
                dealerCommunications: get(action, "payload.data", {}),
                dealerCommunicationsCount: getInteger(
                    action,
                    "payload.meta.total"
                ),
                dealerCommunicationsLinks: get(action, "payload.links", {}),
                // communicationCount: getInteger(action, "payload.meta.total"),
                // communicationLinks: get(action, "payload.links", {}),
                loading: false
            };
        case GET_DEALER_COMMUNICATION_RECIPIENTS:
            return {
                ...state,
                communicationRecipients: get(action, "payload.data", {}),
                communicationRecipientCount: getInteger(
                    action,
                    "payload.meta.total"
                ),
                communicationRecipientLinks: get(action, "payload.links", {}),
                loading: false
            };
        case GET_COMMUNICATIONS:
            return {
                ...state,
                communications: get(action, "payload.data", {}),
                communicationCount: getInteger(action, "payload.meta.total"),
                communicationLinks: get(action, "payload.links", {}),
                loading: false
            };
        case GET_COMMUNICATION_RECIPIENTS:
            return {
                ...state,
                communicationRecipients: get(action, "payload.data", {}),
                communicationRecipientCount: getInteger(
                    action,
                    "payload.meta.total"
                ),
                communicationRecipientLinks: get(action, "payload.links", {}),
                loading: false
            };
        case GET_COMMUNICATION_RECIPIENT:
            return {
                ...state,
                communicationRecipient: action.payload,
                loading: false
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
};
