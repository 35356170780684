import React, { useEffect, useContext, useState } from "react";
import LeadContext from "../../context/lead/leadContext";
import UserContext from "../../context/user/userContext";
import DetailSection from "./DetailSection";
import BasketSection from "./BasketSection";
import Popup from "../Popup";
import PartsTable from "../../tables/PartsTable";

export default function Lead({ leadId }) {
    const leadContext = useContext(LeadContext);
    const { lead, getLead, updateLeadStatus } = leadContext;

    if (leadId === undefined) {
        leadId = 0;
    }

    const userContext = useContext(UserContext);
    const { groupType } = userContext;

    const [invoice, setInvoice] = useState("");
    const [quote, setQuote] = useState("");
    const [invError, setInvError] = useState("");
    const [quoteError, setQuoteError] = useState("");
    const [saleNotMadeReason, setSaleNotMadeReason] = useState(
        lead.data && lead.data.sale_lost_reason
            ? lead.data.sale_lost_reason
            : ""
    );
    const [saleNotMadeError, setSaleNotMadeError] = useState(false);

    const [saleNotMadeReasonShowing, setSaleNotMadeReasonShowing] =
        useState(false);

    useEffect(() => {
        getLead(leadId);
        // if (lead) {
        //
        //     console.log(lead);
        //     setInvoice(lead.invoice);
        //     setQuote(lead.quote);
        //
        //     if (lead.jobDetail) {
        //         console.log('lead jobdetail');
        //         console.log(lead.jobDetail)
        //     }
        //     if (lead.data) {
        //         console.log('lead data');
        //         console.log(lead.data);
        //     }
        //
        //
        // }
    }, []);

    const handleChange = (e) => {
        setInvoice(e.target.value);
        setInvError("");
    };

    const handleQuoteChange = (e) => {
        setQuote(e.target.value);
        setQuoteError("");
    };

    const submit = (status) => {
        setSaleNotMadeError(false);

        if (status === "lost" && !saleNotMadeReason) {
            setSaleNotMadeError(true);
        } else {
            if (status === "complete" && !invoice) {
                setInvError("*Invoice Number Required");
            } else {
                updateLeadStatus({
                    status: status,
                    leadId: parseInt(leadId),
                    invoice: invoice,
                    quote: quote,
                    sale_lost_reason: saleNotMadeReason,
                });
                setSaleNotMadeReasonShowing(false);
            }
        }
    };

    if (leadId === undefined) {
        return (
            <div
                style={{
                    backgroundColor: "white",
                    borderRadius: "4px",
                    padding: "2rem",
                }}
            >
                Something unexpected happened
            </div>
        );
    }

    if (!lead.jobDetail || lead.id === leadId) {
        return null;
    } else {
        if (lead.data && lead.data.invoice && !invoice) {
            setInvoice(lead.data.invoice);
        }
        if (lead.data && lead.data.quote && !quote) {
            setQuote(lead.data.quote);
        }

        return (
            <div
                className="lead"
                style={{
                    backgroundColor: "white",
                    borderRadius: "4px",
                    marginBottom: "2rem",
                }}
            >
                {!!saleNotMadeReasonShowing && (
                    <div style={{ padding: "2em" }}>
                        <h3>Reason for sale not made</h3>
                        <select
                            value={saleNotMadeReason}
                            onChange={(value) =>
                                setSaleNotMadeReason(value.target.value)
                            }
                            style={{
                                margin: "0 1em 0 0",
                                width: "200px",
                            }}
                        >
                            <option>Select a reason</option>
                            <option value="price-uncompetitive">
                                Price uncompetitive
                            </option>
                            <option value="not-in-stock-in-hub">
                                Not in stock in hub
                            </option>
                            <option value="not-in-stock-central">
                                Not in stock central warehouse
                            </option>
                            <option value="not-listed">Not listed</option>
                            <option value="not-preferred-supplier">
                                Not preferred supplier
                            </option>
                        </select>

                        {saleNotMadeError && (
                            <p style={{ color: "red" }}>
                                Please select one of the dropdowns as to why the
                                sale was not made in order to progress.
                            </p>
                        )}

                        <div>
                            <button
                                style={{ marginRight: "1em" }}
                                className="button"
                                onClick={() =>
                                    setSaleNotMadeReasonShowing(false)
                                }
                            >
                                Cancel
                            </button>

                            <button
                                className="button view"
                                onClick={submit.bind(this, "lost")}
                                style={{
                                    minWidth: "160px",
                                    background:
                                        lead.data.sale_status === "lost"
                                            ? "white"
                                            : "#E84E10",

                                    border:
                                        lead.data.sale_status === "lost"
                                            ? "2px solid #E84E10"
                                            : "none",
                                    color:
                                        lead.data.sale_status === "lost"
                                            ? "#E84E10"
                                            : "white",
                                }}
                            >
                                Sale Not Made
                            </button>
                        </div>
                    </div>
                )}

                {!saleNotMadeReasonShowing && (
                    <>
                        <div
                            style={{
                                borderTopLeftRadius: "4px",
                                borderTopRightRadius: "4px",
                            }}
                            className="lead__header"
                        >
                            <span>Job Request</span>
                        </div>
                        <div className="lead__inner">
                            <div className="row">
                                <DetailSection details={lead.customerDetail} />
                                <DetailSection details={lead.vehicleDetail} />
                            </div>
                            <div className="row">
                                <DetailSection details={lead.jobDetail} />
                                <BasketSection details={lead.basketDetail} />
                                {!!lead.data.audatex_user && (
                                    <PartsTable parts={lead.data.parts} />
                                )}
                            </div>
                        </div>
                        <div className="lead__footer">
                            <div className="row">
                                <div className="large-8 columns">
                                    {(groupType === "Dealer" ||
                                        groupType === "Dealer Group") && (
                                        <React.Fragment>
                                            <p className="invoice-disclaimer">
                                                Please enter the invoice number
                                                associated with this purchase.
                                                In listing this you are
                                                confirming that the details
                                                entered are correct.
                                            </p>
                                            <input
                                                id="invoice"
                                                placeholder="Enter the invoice number"
                                                type="text"
                                                value={invoice}
                                                onChange={handleChange}
                                            />
                                            {invError && (
                                                <div className="input-feedback">
                                                    {invError}
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                                <div className="large-4 columns">
                                    {groupType === "Dealer" && (
                                        <React.Fragment></React.Fragment>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                {lead.data.source === "Distrigo Credit" &&
                                    (groupType === "Dealer" ||
                                        groupType === "Dealer Group") && (
                                        <React.Fragment>
                                            <div className="column medium-6">
                                                <button
                                                    className="button view"
                                                    onClick={submit.bind(
                                                        this,
                                                        "complete"
                                                    )}
                                                    style={{
                                                        background:
                                                            lead.data
                                                                .sale_status ===
                                                            "complete"
                                                                ? "white"
                                                                : "#E84E10",

                                                        border:
                                                            lead.data
                                                                .sale_status ===
                                                            "complete"
                                                                ? "2px solid #E84E10"
                                                                : "none",
                                                        color:
                                                            lead.data
                                                                .sale_status ===
                                                            "complete"
                                                                ? "#E84E10"
                                                                : "white",
                                                    }}
                                                >
                                                    Mark As Redeemed
                                                </button>
                                            </div>
                                        </React.Fragment>
                                    )}

                                {lead.data.source !== "Distrigo Credit" &&
                                    (groupType === "Dealer" ||
                                        groupType === "Dealer Group") && (
                                        <React.Fragment>
                                            <div className="large-4 columns">
                                                <button
                                                    className="button view"
                                                    onClick={submit.bind(
                                                        this,
                                                        "complete"
                                                    )}
                                                    style={{
                                                        background:
                                                            lead.data
                                                                .sale_status ===
                                                            "complete"
                                                                ? "white"
                                                                : "#E84E10",

                                                        border:
                                                            lead.data
                                                                .sale_status ===
                                                            "complete"
                                                                ? "2px solid #E84E10"
                                                                : "none",
                                                        color:
                                                            lead.data
                                                                .sale_status ===
                                                            "complete"
                                                                ? "#E84E10"
                                                                : "white",
                                                    }}
                                                >
                                                    Sale Made
                                                </button>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                                className="large-4 columns text-center"
                                            >
                                                <button
                                                    className="button view"
                                                    onClick={() =>
                                                        setSaleNotMadeReasonShowing(
                                                            true
                                                        )
                                                    }
                                                    style={{
                                                        minWidth: "160px",
                                                        background:
                                                            lead.data
                                                                .sale_status ===
                                                            "lost"
                                                                ? "white"
                                                                : "#E84E10",

                                                        border:
                                                            lead.data
                                                                .sale_status ===
                                                            "lost"
                                                                ? "2px solid #E84E10"
                                                                : "none",
                                                        color:
                                                            lead.data
                                                                .sale_status ===
                                                            "lost"
                                                                ? "#E84E10"
                                                                : "white",
                                                    }}
                                                >
                                                    Sale Not Made
                                                </button>
                                            </div>
                                            <div className="large-4 columns text-right">
                                                <button
                                                    className="button view"
                                                    onClick={submit.bind(
                                                        this,
                                                        "quoted"
                                                    )}
                                                    style={{
                                                        background:
                                                            lead.data
                                                                .sale_status ===
                                                            "quoted"
                                                                ? "white"
                                                                : "#E84E10",

                                                        border:
                                                            lead.data
                                                                .sale_status ===
                                                            "quoted"
                                                                ? "2px solid #E84E10"
                                                                : "none",
                                                        color:
                                                            lead.data
                                                                .sale_status ===
                                                            "quoted"
                                                                ? "#E84E10"
                                                                : "white",
                                                    }}
                                                >
                                                    Quotation Made
                                                </button>
                                            </div>
                                        </React.Fragment>
                                    )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}
