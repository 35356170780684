import React from "react";

const SearchBar = ({ text, onChange, showSearchBox, filter }) => {

    const handleChange = event => {
        onChange(event.target.value);
    };

    if (! showSearchBox) {
        return null;
    }
    return (
        <input
            className="search__input"
            type="text"
            name="text"
            value={text}
            onChange={handleChange}
            placeholder="Search Code or Customer"
        />
    );

};

export default SearchBar;
