import React, { useContext, useEffect, useState } from "react";
import { has } from "lodash";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import UserContext from "../../context/user/userContext";
import CommunicationContext from "../../context/communication/communicationContext";
import Spinner from "../../containers/Spinner";
import { Document, Page, pdfjs } from "react-pdf";
import ExternalLink from "../ExternalLink";
// import file from "../../../public/10_09_20_Week_2_September_Distrigo_emails_5.pdf";

const EmailContainer = styled.div`
    width: 600px;
    margin: 0 auto;
    
    tbody th, tbody td {
        padding: unset;
    }
    
    tbody tr:nth-child(even) {
        background: unset;
    }

    thead, tbody, tfoot {
        border: unset;
        background-color: unset;
    }
    
    table {
        border-collapse: unset;
        margin-bottom: unset;
        border-radius: unset;
    }
    
    table td {
        border-collapse: unset;
    }
`;

export default function CommunicationRecipient({
    recipient,
    dealerId,
    communicationId,
    setVisible,
    acousticEmailHTML
}) {
    const {
        id: communication_recipient_id,
        uuid: communication_recipient_uuid,
        preview: communication_recipient_preview,
        link: communication_recipient_link,
        sale_status: communication_recipient_sale_status,
        invoice: communication_recipient_invoice,
        quote: communication_recipient_quote
    } = recipient;

    const {
        communicationRecipient,
        getCommunicationRecipient,
        communicationFilterValue,
        updateCommunicationStatus
    } = useContext(CommunicationContext);
    const { groupType } = useContext(UserContext);

    // const pdfTest = "https://cors-anywhere.herokuapp.com/https://distrigo-pdfs.s3.eu-west-2.amazonaws.com/10_09_20_Week_2_September_Distrigo_emails_5.pdf";
    // const pdfTest = "https://distrigo-pdfs.s3.eu-west-2.amazonaws.com/10_09_20_Week_2_September_Distrigo_emails_5.pdf";
    //
    // const pdfTest = "http://localhost:3000/10_09_20_Week_2_September_Distrigo_emails_5.pdf";

    const [invoice, setInvoice] = useState("");
    const [quote, setQuote] = useState("");
    const [invError, setInvError] = useState("");
    const [quoteError, setQuoteError] = useState("");

    const handleChange = e => {
        setInvoice(e.target.value);
        setInvError("");
    };
    const handleQuoteChange = e => {
        setQuote(e.target.value);
        setQuoteError("");
    };

    const submit = status => {
        if (status === "complete" && !invoice) {
            setInvError("*Invoice Number Required");
        } else {
            updateCommunicationStatus({
                values: {
                    status: status,
                    communication_recipient_uuid: communication_recipient_uuid,
                    invoice: invoice,
                    quote: quote
                },
                communicationId: communicationId,
                dealerId: dealerId,
                communicationFilterValue: communicationFilterValue
            });
            setVisible(false);
        }
    };

    useEffect(() => {
        getCommunicationRecipient(
            communication_recipient_id,
            communication_recipient_uuid,
            communication_recipient_preview,
            communication_recipient_link
        );
    }, []);

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    if (!communicationRecipient) {
        return <Spinner />;
    } else {
        if (has(communicationRecipient, "data")) {
            if (
                communicationRecipient.data &&
                communicationRecipient.data.invoice &&
                !invoice
            ) {
                setInvoice(communicationRecipient.data.invoice);
            }
            if (
                communicationRecipient.data &&
                communicationRecipient.data.quote &&
                !quote
            ) {
                setQuote(communicationRecipient.data.quote);
            }

            return (
                <div
                    style={{
                        backgroundColor: "white",
                        borderRadius: "4px"
                    }}
                    className="communication"
                >
                    <div
                        style={{ paddingTop: "0.5rem", paddingLeft: "0.5rem" }}
                        className="communication"
                    >
                        <h5 className="title">Communication</h5>
                    </div>


                    <div className="communication__footer">
                        <div className="row">
                            {(groupType === "Dealer" || groupType === "Dealer Group") && (
                                <React.Fragment>
                                    <div className="large-6 columns text-center">
                                        <button
                                            className="button view"
                                            onClick={submit.bind(this,"complete")}

                                            style={{
                                                background:
                                                    communication_recipient_sale_status ===
                                                    "complete"
                                                        ? "white"
                                                        : "#E84E10",

                                                border:
                                                    communication_recipient_sale_status ===
                                                    "complete"
                                                        ? "2px solid #E84E10"
                                                        : "none",
                                                color:
                                                    communication_recipient_sale_status ===
                                                    "complete"
                                                        ? "#E84E10"
                                                        : "white"
                                            }}
                                        >
                                            Sale Made
                                        </button>
                                    </div>
                                    <div className="large-6 columns text-center">
                                        <button
                                            className="button view"
                                            onClick={submit.bind(this, "lost")}

                                            style={{
                                                background:
                                                    communication_recipient_sale_status ===
                                                    "lost"
                                                        ? "white"
                                                        : "#E84E10",

                                                border:
                                                    communication_recipient_sale_status ===
                                                    "lost"
                                                        ? "2px solid #E84E10"
                                                        : "none",
                                                color:
                                                    communication_recipient_sale_status ===
                                                    "lost"
                                                        ? "#E84E10"
                                                        : "white"
                                            }}
                                        >
                                            Sale Not Made
                                        </button>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    {acousticEmailHTML && acousticEmailHTML.data &&
                    <EmailContainer
                        dangerouslySetInnerHTML={{__html: acousticEmailHTML.data}}
                    />
                    }
                </div>
            );
        } else {
            return null;
        }
    }
}
