import React, {useContext, useEffect} from "react";
import Navbar from "./Navbar";
// import DashboardNav from "./DashboardNav";
import Header from "./Header";
import Footer from "./Footer";
import UserContext from "../context/user/userContext";

const Layout = ({ location, children }) => {
    const userContext = useContext(UserContext);

    const { groupType, getUser } = userContext;
    useEffect(() => {
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

	// console.log('layout - location:', location);
	// console.log('layout - location.pathname:', location.pathname);

    return (
        <div className="site">
            <Header />
            <Navbar location={location.pathname} groupType={groupType} />
            {/* <DashboardNav location={location} /> */}
            <div className="site_content">{children}</div>
            <Footer />
        </div>
    );
};

export default Layout;
