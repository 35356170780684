import React, { Fragment, useContext, useEffect, useState } from "react";
import { map, isString } from "lodash";
import TableLayout from "../../../containers/TableLayout";
import Spinner from "../../../containers/Spinner";
import HeadOfficeCustomerRow from "./HeadOfficeCustomerRow";
import CustomerContext from "../../../context/customer/customerContext";
import SearchContext from "../../../context/search/searchContext";
import UserContext from "../../../context/user/userContext";
import {
    arrayLen,
    checkArray,
    getFilterByValue,
    prepareDateString
} from "../../../utils/utils";
import { useIsFirstRender } from "../../../utils/isFirstRender";
import { getCustomerFilterOptions } from "../../customerFilterOptions";
import GetOnFirstRender from "../../GetOnFirstRender";

const HeadOfficeCustomers = ({ match }) => {
    const {
        params: { filter, id, from, to }
    } = match;

    const {
        customerDealers,
        getCustomerDealers,
        getCustomerPage,
        getCategoryOptions,
        categoryOptions,
        loading,
        setCustomerFilterValue,
        setCustomerFilterOrDefault,
        customerFilterValue,
        setCustomerDealersPage,
        customerDealersPage,
        customerDealerCount
    } = useContext(CustomerContext);

    const { startDateMySQL, endDateMySQL } = useContext(SearchContext);

    const { groupType } = useContext(UserContext);
    const [value, setValue] = useState('all');
    const customerFilterOptions = getCustomerFilterOptions(groupType);
    const filterObject = getFilterByValue(
        customerFilterOptions,
        customerFilterValue
    );
    const { dates } = filterObject;

    const handleSelectChange = event => {
        setValue(event.target.value);
    };

    const getItemsUrl = () => {
        getCustomerDealers({
            customerFilterValue: isString(filter)
                ? filter
                : customerFilterValue,
            start: prepareDateString(from, startDateMySQL),
            end: prepareDateString(to, endDateMySQL)
        });
        getCategoryOptions();
    };

    const getItemsState = () => {
        getCustomerDealers({
            customerFilterValue: customerFilterValue,
            start: startDateMySQL,
            end: endDateMySQL
        });
    };

    return (
        <Fragment>
            <div
                className="stripe--light-gray stripe--large"
                style={{ padding: "0" }}
            >
                <div className="row">
                    <h3 className="title pad-t">Customers</h3>
                    <GetOnFirstRender
                        from={from}
                        to={to}
                        filter={filter}
                        setFilterValue={setCustomerFilterValue}
                        getItems={getItemsUrl}
                    />
                    <TableLayout
                        activePage={customerDealersPage}
                        setActivePage={setCustomerDealersPage}
                        showPagination={true}
                        totalItems={customerDealerCount}
                        getItems={getItemsState}
                        setFilterValue={setCustomerFilterValue}
                        filterValue={customerFilterValue}
                        filterOptions={customerFilterOptions}
                        showDates={dates}
                    >
                        <table
                            id="customers"
                            className="responsive"
                            width="100%"
                        >
                            <tbody>
                                <tr>
                                    <th>Parts Distributor</th>
                                    <th>Parts Distributor Code</th>
                                    <th>Pinpoint</th>
                                    <th>Activations</th>
                                    <th>Points Awarded</th>
                                    <th>
                                        <select
                                            value={value}
                                            onChange={handleSelectChange}
                                        >
                                            <option disabled selected value> -- Select An Option -- </option>
                                            {categoryOptions.map(option => {
                                                return (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </th>
                                    <th>Points Claimed</th>
                                    <th>Points Expired</th>
                                    <th>Points Available</th>
                                    <th>Action</th>
                                </tr>
                                {!loading &&
                                    checkArray(customerDealers) &&
                                    map(
                                        customerDealers,
                                        (customerDealer, i) => (
                                            <HeadOfficeCustomerRow
                                                key={customerDealer.id}
                                                selectOption={value}
                                                customerDealer={customerDealer}
                                                filterValue={
                                                    customerFilterValue
                                                }
                                                filterObject={filterObject}
                                            />
                                        )
                                    )}
                            </tbody>
                        </table>
                        {loading ? <Spinner /> : null}
                    </TableLayout>
                </div>
            </div>
        </Fragment>
    );
};

export default HeadOfficeCustomers;
