import React, { Fragment, useContext, useEffect, useState } from "react";
import { map, isString, compose } from "lodash";
import TableLayout from "../../containers/TableLayout";
import LeadRow from "./LeadRow";
import LeadContext from "../../context/lead/leadContext";
import SearchContext from "../../context/search/searchContext";
import UserContext from "../../context/user/userContext";
import Spinner from "../../containers/Spinner";
import {
    checkInteger,
    checkString,
    getFilterByValue,
    prepareDateString
} from "../../utils/utils";
import { useIsFirstRender } from "../../utils/isFirstRender";
import { getLeadFilterOptions } from "../leadFilterOptions";
import LeadPopUp from "../../components/DealerLeads/DealerLeadsPopup";
import GetOnFirstRender from "../GetOnFirstRender";

export default function LeadsTable({ match }) {
    const {
        params: { filter, from, to }
    } = match;

    const {
        leads,
        getLeads,
        loading,
        leadsPage,
        setLeadPage,
        leadCount,
        leadFilterValue,
        setLeadFilterValue,
        setLeadFilterOrDefault
    } = useContext(LeadContext);

    const { startDateMySQL, endDateMySQL } = useContext(SearchContext);
    const { groupType, user } = useContext(UserContext);

    const [leadId, setLeadId] = useState(undefined);

    const [popupVisible, setPopupVisible] = useState(false);
    const showPopup = () => setPopupVisible(true);
    const hidePopup = () => setPopupVisible(false);

    const isFirstRender = useIsFirstRender();
    const leadFilterOptions = getLeadFilterOptions(groupType);
    const filterObject = getFilterByValue(leadFilterOptions, leadFilterValue);
    const { dates } = filterObject;

    const apiUrl = checkString(process.env.REACT_APP_BASE_URL);

    const getItemsUrl = () =>
        getLeads({
            leadFilterValue: isString(filter) ? filter : leadFilterValue,
            start: prepareDateString(from, startDateMySQL),
            end: prepareDateString(to, endDateMySQL)
        });

    const getItemsState = () =>
        getLeads({
            leadFilterValue: leadFilterValue,
            start: startDateMySQL,
            end: endDateMySQL
        });

    return (
        <Fragment>
            <LeadPopUp
                show={popupVisible}
                setVisible={setPopupVisible}
                leadId={leadId}
            />
            <div className="row">
                <h3 className="title pad-t">Leads</h3>
                <GetOnFirstRender
                    to={to}
                    from={from}
                    filter={filter}
                    getItems={getItemsUrl}
                    setFilterValue={setLeadFilterValue}
                />
                <TableLayout
                    activePage={leadsPage}
                    setActivePage={setLeadPage}
                    totalItems={checkInteger(leadCount)}
                    getItems={getItemsState}
                    setFilterValue={setLeadFilterValue}
                    filterValue={leadFilterValue}
                    filterOptions={leadFilterOptions}
                    showDates={dates}
                    exportLink={`${apiUrl}/download/Leads/${user.uuid}/${groupType}/${startDateMySQL}/${endDateMySQL}/${leadFilterValue}`}
                >
                    <table id="leads-table" className="responsive" width="100%">
                        <thead>
                            <tr>
                                <th>Source</th>
                                <th>Customer</th>
                                <th>Description</th>
                                <th>Make</th>
                                <th>Model</th>
                                <th>Registration</th>
                                <th>Lead Creation</th>
                                <th>Customer Appointment Date</th>
                                <th>SMS Notification</th>
                                <th>Followed Up?</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading &&
                                map(leads, (lead, i) => (
                                    <LeadRow
                                        key={i}
                                        lead={lead}
                                        setLeadId={setLeadId}
                                        showPopup={showPopup}
                                    />
                                ))}
                        </tbody>
                    </table>
                    {loading ? <Spinner /> : null}
                </TableLayout>
            </div>
        </Fragment>
    );
}
