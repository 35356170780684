import React from "react";
import logo from "../images/stellantis-logo-white.png";
import { Link } from "react-router-dom";
import moment from "moment";
import ExternalLink from "../components/ExternalLink";
import {checkString} from "../utils/utils";
import facebookLogo from "../images/facebook-logo.png";

export default function Footer() {
    const distrigoUrl = checkString(process.env.REACT_APP_DISTRIGO_URL);
    return (
        <React.Fragment>
            <div className="footer">
                <div className="psa_footer">
                    <div className="footer__inner row columns">
                        <div className="footer__item large-2 columns">
                            <a

                                href="http://www.psa-peugeot-citroen.com/en"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <img
                                    src={logo}
                                    alt="logo"
                                    width="160"
                                    className="footer__logo"
                                />
                            </a>
                        </div>
                        <div className="footer__item large-8 columns">
                            <ul className="menu">
                                <li>
                                    <ExternalLink href={distrigoUrl + '/perks-terms-and-conditions'}>
                                        PERKS Terms &amp; Conditions
                                    </ExternalLink>
                                </li>
                                <li>
                                    <ExternalLink href={distrigoUrl + "/terms-and-conditions"}>
                                        Terms &amp; Conditions
                                    </ExternalLink>
                                </li>
                                <li>
                                    <ExternalLink href={distrigoUrl + "/distrigo-warranty"}>
                                        Warranty
                                    </ExternalLink>
                                </li>
                                <li>
                                    <ExternalLink href={distrigoUrl + "/privacy-policy"}>
                                        Privacy Policy
                                    </ExternalLink>
                                </li>
                                <li>
                                    <ExternalLink href={distrigoUrl + "/cookie-policy"}>
                                        Cookie Policy
                                    </ExternalLink>
                                </li>
                                <li>
                                    <ExternalLink href={distrigoUrl + "pdf/SLAVERY_AND_HUMAN_TRAFFICKING_STATEMENT_FOR_PCD_UK_2020-GPSQ.pdf"}>
                                        Modern Slavery Act
                                    </ExternalLink>
                                </li>
                                <li>
                                     <ExternalLink href={distrigoUrl + "/contact"}>Contact</ExternalLink>
                                </li>
                                <li>
                                    <ExternalLink href={distrigoUrl + "/sitemap"}>Sitemap</ExternalLink>
                                </li>
                                <li>
                                    <ExternalLink href={distrigoUrl + "/request-staff-account"}>
                                        Create a new Parts Distributor Account
                                    </ExternalLink>
                                </li>
                            </ul>
                            <span >
                                &copy; {moment().format("YYYY")} Stellantis. All
                                rights reserved.
                            </span>
                        </div>
                        <div className="footer__item large-2 columns text-right">
                            <p className="follow-us">FOLLOW US ON:  </p>
                            <ExternalLink target="_blank" href="https://www.facebook.com/distrigouk/">
                                <img
                                    src={facebookLogo}
                                    alt="facebook"
                                    width="33"
                                />
                            </ExternalLink>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
