import React from "react";

export default function DetailSection({ details }) {
    const { title, info, status } = details;
    if (status.status === false) {
        return <div></div>;
    }
    return (
        <div>
            <section className="detail">
                <div className="large-6 columns">
                    <h3>{title}</h3>
                    <table style={{ width: "100%" }}>
                        <tbody>
                            {info.map((stat, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="detail-label">
                                            {stat.name}
                                        </td>
                                        <td>{stat.value}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    );
}
