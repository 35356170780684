import React, { useContext } from "react";
import moment from "moment";
import RewardContext from "../../context/reward/rewardContext";

export default function RewardsRow({ reward, groupType }) {
    const { updateRewardStatus, rewardFilterValue } = useContext(RewardContext);

    return (
        <tr key={reward.id}>
            <td className="PD">{reward.last_name ? `${reward.first_name} ${reward.last_name}` : "-"}</td>
            <td className="PD">{reward.trade_team_customer}</td>
            <td className="PD">{reward.trade_team_id}</td>
            <td className="PD">{reward.display_type}</td>
            <td className="PD">
                {reward.claim_code ? reward.claim_code : "-"}
            </td>
            <td className="PD">
                £{(reward.value ? Number(reward.value) : 0).toFixed(2)}
            </td>
            <td className="PD">
                {reward.ordered_at
                    ? moment(reward.ordered_at).format("DD/MM/YYYY HH:mm")
                    : "-"}
            </td>
            <td className="PD">
                {reward.expires_at
                    ? moment(reward.expires_at).format("DD/MM/YYYY HH:mm")
                    : "-"}
            </td>
            <td className="PD">{reward.display_status}</td>
            <td className="rewards__buttons PD">
                {reward.redeemable && (groupType === "Dealer" || groupType === "Dealer Group") ? (
                    <button
                        className="button button--black"
                        onClick={updateRewardStatus.bind(this, {
                            values: { status: "redeemed", rewardId: reward.id },
                            rewardFilterValue: rewardFilterValue,
                        })}
                    >
                        Mark as Redeemed
                    </button>
                ) : (
                    "-"
                )}
            </td>
        </tr>
    );
}
