import React, { Fragment, useContext, useEffect } from "react";
import { map, isArray, isString } from "lodash";
import TableHeader from "../../components/TableHeader";
import TableFooter from "../../components/TableFooter";
import CommunicationRow from "./CommunicationRow";
import CommunicationsContext from "../../context/communication/communicationContext";
import UserContext from "../../context/user/userContext";
import {
    checkString,
    getFilterByValue,
    prepareDateString
} from "../../utils/utils";
import { getCommunicationFilterOptions } from "../communicationFilterOptions";
import TableLayout from "../../containers/TableLayout";
import SearchContext from "../../context/search/searchContext";
import GetOnFirstRender from "../GetOnFirstRender";
import Spinner from "../../containers/Spinner";

const CommunicationsTable = ({ match, location }) => {
    const {
        params: { filter, from, to }
    } = match;

    const {
        loading,
        communications,
        getCommunications,
        communicationFilterValue,
        setCommunicationFilterValue,
        setCommunicationFilterOrDefault
    } = useContext(CommunicationsContext);
    const { groupType, user } = useContext(UserContext);

    const { startDateMySQL, endDateMySQL } = useContext(SearchContext);

    const communicationFilterOptions = getCommunicationFilterOptions(groupType);

    const filterObject = getFilterByValue(
        communicationFilterOptions,
        communicationFilterValue
    );
    const { dates } = filterObject;

    const apiUrl = checkString(process.env.REACT_APP_BASE_URL);

    const getItemsUrl = () => {
        getCommunications({
            communicationFilterValue: isString(filter)
                ? filter
                : communicationFilterValue,
            start: prepareDateString(from, startDateMySQL),
            end: prepareDateString(to, endDateMySQL)
        });
    };

    const getItemsState = () =>
        getCommunications({
            communicationFilterValue: communicationFilterValue,
            start: startDateMySQL,
            end: endDateMySQL
        });

    return (
        <Fragment>
            <div className="row">
                <h3 className="title pad-t">Communications</h3>
                <GetOnFirstRender
                    to={to}
                    from={from}
                    filter={filter}
                    getItems={getItemsUrl}
                    setFilterValue={setCommunicationFilterValue}
                />
                <TableHeader
                    filterOptions={communicationFilterOptions}
                    showDates={dates}
                    setFilterValue={setCommunicationFilterValue}
                    filterValue={communicationFilterValue}
                    getItems={getItemsState}
                />
                <table id="commms-table" className="responsive" width="100%">
                    <tbody>
                        <tr>
                            <th>Communication Title</th>
                            <th>Method</th>
                            <th>Send Date</th>
                            <th>Sent</th>
                            <th>Opened</th>
                            <th>Clicked</th>
                            <th>View</th>
                        </tr>
                        {!loading &&
                            isArray(communications) &&
                            map(communications, (communication, i) => (
                                <CommunicationRow
                                    key={i}
                                    communication={communication}
                                    filterObject={filterObject}
                                />
                            ))}
                    </tbody>
                </table>
                {loading ? <Spinner /> : null}
                <TableFooter
                    showPagination={false}
                    exportLink={`${apiUrl}/download/Communications/${user.uuid}/${groupType}/${startDateMySQL}/${endDateMySQL}/${communicationFilterValue}`}
                />
            </div>
        </Fragment>
    );
};

export default CommunicationsTable;
