import { checkBool } from "../../utils/utils";
import {
    SET_SEARCH_TEXT,
    SET_LOADING,
    SET_START_DATE,
    SET_END_DATE,
    SET_LEAD_FILTER_VALUE,
    SET_PAGINATION_VALUE,
    TOGGLE_FORM_SUBMIT
} from "../types";
export default (state, action) => {
    switch (action.type) {
        case TOGGLE_FORM_SUBMIT:
            return {
                ...state,
                formSubmit: !checkBool(action.payload)
            };
        case SET_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.payload,
                loading: false
            };
        case SET_PAGINATION_VALUE:
            return {
                ...state,
                paginationValue: action.payload,
                loading: false
            };

        case SET_START_DATE:
            return {
                ...state,
                startDate: action.payload,
                loading: false
            };
        case SET_END_DATE:
            return {
                ...state,
                endDate: action.payload,
                loading: false
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true
            };

        default:
            return state;
    }
};
