import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchContext from "../../context/search/searchContext";
import {
    displayPercentage,
    replaceUnderscores,
    secondsToHms
} from "../../utils/utils";
import moment from "moment";

// export default function CommunicationRow({ communication }) {
export default function CommunicationRow({
    communication,
    filterValue: filterValueRaw,
    filterObject
}) {
    const { startDateMySQL, endDateMySQL } = useContext(SearchContext);

    const filterValue = replaceUnderscores(filterValueRaw);
    // const { dates } = filterObject;
    const dates = false;

    const link = dates
        ? `/communication-recipients-dealer/${communication.communication_id}/dealer/${communication.dealer_id}/${filterValue}/${startDateMySQL}/${endDateMySQL}?mailing_id=${communication.mailing_id}`
        : `/communication-recipients-dealer/${communication.communication_id}/dealer/${communication.dealer_id}/${filterValue}?mailing_id=${communication.mailing_id}`;


    return (
        <tr key={communication.id}>
            <td className="PD">{communication.title}</td>
            <td style={{ paddingLeft: "1.6rem" }}>
                <FontAwesomeIcon icon={faEnvelope} color={"black"} title={"Email"} />
            </td>
            <td className="PD">
                {communication.sent_at
                ? moment(communication.sent_at).format("DD/MM/YYYY")
                : "-"}
            </td>
            <td style={{ background: "#E8E8E8" }} className="PD">
                {communication.total_sent}
            </td>
            <td style={{ background: "#D3D3D3" }} className="PD">
                {communication.total_opened}
            </td>
            <td style={{ background: "#B4B4B4" }} className="PD">
                {communication.total_clicked}
            </td>
            <td className="communication__buttons PD">
                <Link to={link}>
                    <button className="button button--black">View </button>
                </Link>
            </td>
        </tr>
    );
}
