import React, { Fragment, useContext, useEffect, useState } from "react";
import { map, isString } from "lodash";
import axios from "axios";
import queryString from "querystring";


import { checkArray, checkInteger, getFilterByValue } from "../../utils/utils";
import { useIsFirstRender } from "../../utils/isFirstRender";
import TableLayout from "../../containers/TableLayout";
import Spinner from "../../containers/Spinner";
import CommunicationContext from "../../context/communication/communicationContext";
import DealerContext from "../../context/dealer/dealerContext";
import SearchContext from "../../context/search/searchContext";
import { communicationFilterOptions } from "../communicationFilterOptions";
import CommunicationRecipientsRow from "../CommunicationRecipientsTable/CommunicationRecipientsRow";
import CommunicationRecipientsPopup from "../CommunicationRecipientsTable/CommunicationRecipientsPopup";
import GetOnFirstRender from "../GetOnFirstRender";

const DealerCommunicationRecipientsTable = ({ match, location }) => {
    const {
        params: {
            filter,
            dealer_id: dealerId,
            communication_id: communicationId,
            from,
            to,
        },
    } = match;

    const urlSearchParams = new URLSearchParams(location.search);
    const { mailing_id: mailingId } = Object.fromEntries(urlSearchParams.entries());


    const {
        communicationRecipients,
        getDealerCommunicationRecipients,
        loading,
        communicationFilterValue,
        setCommunicationFilterValue,
        setCommunicationFilterOrDefault,
        communicationRecipientCount,
        dealerCommunicationRecipientsPage,
        setDealerCommunicationRecipientsPage,
    } = useContext(CommunicationContext);

    const [popupVisible, setPopupVisible] = useState(false);
    const showPopup = () => setPopupVisible(true);
    const hidePopup = () => setPopupVisible(false);

    const [recipient, setRecipient_] = useState({});
    const setRecipient = (recipient) => setRecipient_({ ...recipient });

    const [acousticEmailHTML, setAcousticEmailHTML] = useState();

    const isFirstRender = useIsFirstRender();
    const filterObject = getFilterByValue(
        communicationFilterOptions,
        communicationFilterValue
    );
    const { dates } = filterObject;

    const { dealer, getDCDealer, dealerLoading } = useContext(DealerContext);
    const { setStartDateIfValid, setEndDateIfValid } = useContext(
        SearchContext
    );

    const firstCommunicationRecipient =
        communicationRecipients && checkArray(communicationRecipients)
            ? communicationRecipients[0]
            : null;
    const title =
        dealer &&
        dealer.name &&
        firstCommunicationRecipient &&
        firstCommunicationRecipient.communication.title
            ? `DISTRIGO PERKS Communications > ${dealer.name} > ${firstCommunicationRecipient.communication.title}`
            : "DISTRIGO PERKS Communications";

    /*
    useEffect(() => {
        if (isFirstRender === true) {
            // If provided, apply url arguments to page
            if (isString(filter) === true)
                setCommunicationFilterOrDefault(filter);
            if (isString(from) === true) setStartDateIfValid(from);
            if (isString(to) === true) setEndDateIfValid(to);

            getDCDealer(dealerId);
            getDealerCommunicationRecipients({
                communicationFilterValue: filter,
                communicationId,
                dealerId
            });
        }
    }, []);
	 */

    const getAcousticEmailHTML = () => {
            axios
                .get(`/api/v1/acoustic/${mailingId}`)
                .then(data => {
                    setAcousticEmailHTML(data);
                })
                .catch(e => {
                    console.log(e);
                })
    };

    useEffect(() => {
        getAcousticEmailHTML();
    }, communicationRecipients)

    const getItemsUrl = () => {
        getDCDealer(dealerId);
        getDealerCommunicationRecipients({
            communicationFilterValue: isString(filter)
                ? filter
                : communicationFilterValue,
            communicationId,
            dealerId,
        });
    };

    const getItemsState = () => {
        getDealerCommunicationRecipients({
            communicationFilterValue: communicationFilterValue,
            communicationId,
            dealerId,
        });
    };

    return (
        <Fragment>
            <CommunicationRecipientsPopup
                acousticEmailHTML={acousticEmailHTML}
                show={popupVisible}
                setVisible={setPopupVisible}
                recipient={recipient}
                dealerId={dealerId}
                communicationId={communicationId}
            />
            <div className="row">
                <h3 className="title pad-t">{title}</h3>
                <GetOnFirstRender
                    to={to}
                    from={from}
                    filter={filter}
                    getItems={getItemsUrl}
                    setFilterValue={setCommunicationFilterValue}
                />

                <TableLayout
                    activePage={dealerCommunicationRecipientsPage}
                    setActivePage={setDealerCommunicationRecipientsPage}
                    getItems={getItemsState}
                    totalItems={checkInteger(communicationRecipientCount)}
                    hideFilter={true}
                >
                    <table
                        id="dealer-comms"
                        className="responsive"
                        width="100%"
                    >
                        <tbody>
                            <tr>
                                <th>Customer</th>
                                <th>ID</th>
                                <th>Communication Title</th>
                                <th>Sent</th>
                                <th>Opened</th>
                                <th>Clicked</th>
                                <th>Followed Up?</th>
                                <th>View</th>
                            </tr>
                            {communicationRecipients &&
                                checkArray(communicationRecipients) &&
                                map(
                                    communicationRecipients,
                                    (communicationRecipient, i) => (
                                        <CommunicationRecipientsRow
                                            key={i}
                                            communicationRecipient={
                                                communicationRecipient
                                            }
                                            showPopup={showPopup}
                                            setRecipient={setRecipient}
                                        />
                                    )
                                )}
                        </tbody>
                    </table>
                    {loading || dealerLoading ? <Spinner /> : null}
                </TableLayout>
            </div>
        </Fragment>
    );
};

export default DealerCommunicationRecipientsTable;
