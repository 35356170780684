import React, { Fragment, useEffect, useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import { Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import Header from "./Header";
import PotentialCustomerContext from "../../context/potentialcustomer/potentialCustomerContext";
import Spinner from "../../containers/Spinner";
import { isArray, isObject } from "lodash";

const PotentialCustomer = ({ match }) => {
    const {
        params: { id }
    } = match;
    const {
        potentialCustomer,
        getPotentialCustomer,
        getPotentialCustomerStatuses,
        potentialCustomerStatuses,
        potentialCustomerStatusesToSetByPD
    } = useContext(PotentialCustomerContext);

    useEffect(() => {
        getPotentialCustomer(id);
        getPotentialCustomerStatuses();
    }, []);

    const [formPosted, setFormPosted] = useState(false);

    if (formPosted === true) return <Redirect to="/potential-customers" />;

    if (
        !potentialCustomer.id ||
        !isArray(potentialCustomerStatusesToSetByPD) ||
        !isObject(potentialCustomerStatusesToSetByPD[0])
    ) {
        return <Spinner />;
    } else {
        return (
            <Fragment>
                <Header
                    potentialCustomer={potentialCustomer}
                    potentialCustomerStatuses={potentialCustomerStatuses}
                />
                <Formik
                    initialValues={{
                        id: potentialCustomer.id,
                        accountNo: potentialCustomer.pd_account_number,
                        status: potentialCustomer.status,
                        notes: potentialCustomer.pd_notes
                    }}
                    onSubmit={async (values, { setStatus }) => {
                        const { status } = await axios.post(
                            "/api/v1/potential-customer/update",
                            values
                        );

                        if (status === 200) {
                            setFormPosted(true);
                        } else {
                            setStatus({ msg: "An error has occured" });
                        }
                    }}
                    validationSchema={Yup.object().shape({
                        accountNo: Yup.string().required("Required"),
                        status: Yup.string().required("Required")
                    })}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            status,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset
                        } = props;

                        return (
                            <form
                                onSubmit={handleSubmit}
                                className="customer-form"
                            >
                                <label
                                    htmlFor="accountNo"
                                    style={{ display: "block" }}
                                >
                                    Account Number
                                </label>
                                <input
                                    id="id"
                                    name="id"
                                    type="hidden"
                                    value={values.id}
                                />
                                <input
                                    id="accountNo"
                                    placeholder="Enter the account number"
                                    type="text"
                                    value={values.accountNo}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                        errors.accountNo && touched.accountNo
                                            ? "text-input error"
                                            : "text-input"
                                    }
                                />
                                {errors.accountNo && touched.accountNo && (
                                    <div className="input-feedback">
                                        {errors.accountNo}
                                    </div>
                                )}
                                <label
                                    htmlFor="status"
                                    style={{ display: "block" }}
                                >
                                    Status
                                </label>
                                <select
                                    id="status"
                                    placeholder="Enter the status"
                                    type="select"
                                    value={values.status}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    style={{ width: "100%" }}
                                    className={
                                        errors.status && touched.status
                                            ? "text-input error"
                                            : "text-input"
                                    }
                                >
                                    {potentialCustomerStatusesToSetByPD.map(
                                        status => {
                                            return (
                                                <option
                                                    key={status.value}
                                                    value={status.value}
                                                >
                                                    {status.label}
                                                </option>
                                            );
                                        }
                                    )}
                                </select>
                                {errors.status && touched.status && (
                                    <div className="input-feedback">
                                        {errors.status}
                                    </div>
                                )}

                                <label
                                    htmlFor="notes"
                                    style={{ display: "block" }}
                                >
                                    Notes
                                </label>
                                <input
                                    id="notes"
                                    placeholder="Enter any notes"
                                    type="textarea"
                                    value={values.notes}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    style={{ width: "100%", height: "4rem" }}
                                    className={
                                        errors.notes && touched.notes
                                            ? "text-input error"
                                            : "text-input"
                                    }
                                />
                                {errors.notes && touched.notes && (
                                    <div className="input-feedback">
                                        {errors.notes}
                                    </div>
                                )}

                                <button
                                    style={{ marginRight: "1rem" }}
                                    type="submit"
                                    className="button view"
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </button>
                                {}
                                <button
                                    style={{ backgroundColor: "#E75014" }}
                                    type="button"
                                    className="button view"
                                    onClick={handleReset}
                                    disabled={!dirty || isSubmitting}
                                >
                                    Reset
                                </button>
                                {status && status.msg && (
                                    <div style={{ color: "red" }}>
                                        {status.msg}
                                    </div>
                                )}
                            </form>
                        );
                    }}
                </Formik>
            </Fragment>
        );
    }
};
export default PotentialCustomer;
