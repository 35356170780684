import React, { Fragment, useEffect, useContext } from "react";
import Header from "./Header";
import Points from "./Points";
import CustomerContext from "../../context/customer/customerContext";
import Spinner from "../../containers/Spinner";
import moment from "moment";
import { isArray, has } from "lodash";

const Customer = ({ match }) => {
    const {
        customer,
        getCustomer,
        getCategoryOptions,
        categoryOptions,
        getMarketingCampaignsCustomer,
        marketingCampaignsCustomer,
        getCommunicationsCustomer,
        customerCommunications,
        loading
    } = useContext(CustomerContext);
    const {
        params: { customer_id: customerId }
    } = match;

    useEffect(() => {
        getCategoryOptions();
        getCustomer(customerId);
        getMarketingCampaignsCustomer(customerId);
        getCommunicationsCustomer(customerId);
    }, []);

    if (!loading && has(customer, "calc")) {
        return (
            <Fragment>
                <Header customer={customer} />
                <div className="row column">
                    <div className="columns small-7">
                        <div className="customer__offers">
                            <h4>Current Offers</h4>
                            {marketingCampaignsCustomer && isArray(marketingCampaignsCustomer) && marketingCampaignsCustomer.length > 0 ? (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Campaign</th>
                                        <th>Date From</th>
                                        <th>Date To</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {marketingCampaignsCustomer &&
                                        isArray(marketingCampaignsCustomer) &&
                                        marketingCampaignsCustomer.map(
                                            campaign => {
                                                return (
                                                    <tr key={campaign.id}>
                                                        <td>
                                                            {campaign.title}
                                                        </td>
                                                        <td>
                                                            {campaign.date_from
                                                                ? moment(
                                                                      campaign.date_from
                                                                  ).format(
                                                                      "DD/MM/YYYY"
                                                                  )
                                                                : "-"}
                                                        </td>
                                                        <td>
                                                            {campaign.date_to
                                                                ? moment(
                                                                      campaign.date_to
                                                                  ).format(
                                                                      "DD/MM/YYYY"
                                                                  )
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                </tbody>
                            </table>
                              ) : (
                                <p>No current campaigns available.</p>
                            )}
                        </div>
                        <div className="customer__latest">
                            <h4>Latest Communications</h4>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Offer/Action</th>
                                        <th>Date/Time Sent</th>
                                        <th>Opened</th>
                                        <th>Clicked</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {customerCommunications &&
                                        isArray(customerCommunications) &&
                                        customerCommunications.map(
                                            communication => {
                                                return (
                                                    <tr key={communication.id}>
                                                        <td>
                                                            {
                                                                communication.title
                                                            }
                                                        </td>
                                                        <td>
                                                            {communication.sent_at
                                                                ? moment(
                                                                      communication.sent_at
                                                                  ).format(
                                                                      "DD/MM/YYYY"
                                                                  )
                                                                : "-"}
                                                        </td>
                                                        <td>
                                                            {communication.opened_at
                                                                ? moment(
                                                                      communication.opened_at
                                                                  ).format(
                                                                      "DD/MM/YYYY"
                                                                  )
                                                                : "-"}
                                                        </td>
                                                        <td>
                                                            {communication.clicked_at
                                                                ? moment(
                                                                      communication.clicked_at
                                                                  ).format(
                                                                      "DD/MM/YYYY"
                                                                  )
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <Points
                        customer={customer}
                        categoryOptions={categoryOptions}
                    />
                </div>
            </Fragment>
        );
    } else {
        return <Spinner />;
    }
};
export default Customer;
