import React from "react";
import { Link } from "react-router-dom";

export default function HeadOfficePotentialCustomerRow({
    potentialCustomerDealer
}) {
    return (
        <tr key={potentialCustomerDealer.id}>
            <td className="PD">
                <Link
                    to={`/potential-customer/dealers/${potentialCustomerDealer.id}`}
                >
                    {potentialCustomerDealer.dealer_name}
                </Link>
            </td>
            <td className="PD">
                {potentialCustomerDealer.dealer_code.replace("HUB_", "")}
            </td>
            <td className="PD">
                {potentialCustomerDealer.potential_customers}
            </td>
            <td className="customers__buttons PD">
                <Link
                    to={`/potential-customer/dealer/${potentialCustomerDealer.id}`}
                >
                    <button className="button button--black">View </button>
                </Link>
            </td>
        </tr>
    );
}
