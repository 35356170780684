import React, { useContext } from "react";
import { Link } from "react-router-dom";
import SearchContext from "../../../context/search/searchContext";
import { checkBool, replaceUnderscores } from "../../../utils/utils";

export default function HeadOfficeCustomerRow({
    customerDealer,
    selectOption,
    filterValue: filterValueRaw,
    filterObject
}) {
    const { startDateMySQL, endDateMySQL } = useContext(SearchContext);
    const { dates } = filterObject;
    const filterValue = replaceUnderscores(filterValueRaw);

    const link = checkBool(dates)
        ? `/customer/dealer/${customerDealer.id}/${filterValue}/${startDateMySQL}/${endDateMySQL}`
        : `/customer/dealer/${customerDealer.id}/${filterValue}`;

    return (
        <tr key={customerDealer.id}>
            <td className="PD">
                <Link to={link}>{customerDealer.dealer_name}</Link>
            </td>
            <td>{customerDealer.dealer_code.replace("HUB_", "")}</td>
            <td>
                {customerDealer.pinpoint
                    ? Number(customerDealer.pinpoint).toLocaleString()
                    : 0}
            </td>
            <td>
                {customerDealer.activations
                    ? Number(customerDealer.activations).toLocaleString()
                    : 0}
            </td>
            <td>
                {customerDealer.awarded_points_to_show
                    ? Number(
                          customerDealer.awarded_points_to_show
                      ).toLocaleString()
                    : 0}
            </td>
            <td>
                {customerDealer[`points_${selectOption}`]
                    ? Number(
                          customerDealer[`points_${selectOption}`]
                      ).toLocaleString()
                    : 0}
            </td>
            <td>
                {customerDealer.redeemed_points_to_show
                    ? Number(
                          customerDealer.redeemed_points_to_show
                      ).toLocaleString()
                    : 0}
            </td>
            <td>
                {customerDealer.points_expired
                    ? Number(customerDealer.points_expired).toLocaleString()
                    : 0}
            </td>
            <td>
                {customerDealer.points_available
                    ? Number(customerDealer.points_available).toLocaleString()
                    : 0}
            </td>
            <td className="customers__buttons">
                <Link to={link}>
                    <button className="button button--black">View </button>
                </Link>
            </td>
        </tr>
    );
}
