import React, { Fragment } from "react";
import Ifbr from "./Ifbr";
import logo from "../../images/distrigo_logo.svg";
import moment from "moment";

const Header = ({ potentialCustomer, potentialCustomerStatuses }) => {
    const {
        post_code,
        telephone,
        mobile,
        email,
        business_name,
        first_name,
        last_name,
        address_1,
        address_2,
        address_3,
        town,
        account_number,
        status,
        display_current_dealer,
        rla_notes
    } = potentialCustomer;

    const address = (
        <span className="text-right">
            <Ifbr element={address_1} />
            <Ifbr element={address_2} />
            <Ifbr element={address_3} />
            <Ifbr element={town} />
            <Ifbr element={post_code} />
            <br />
        </span>
    );
    return (
        <Fragment>
            <div className="row column communication__header">
                <div className="columns small-6 communication__distrigo">
                    <img src={logo} alt="Distrigo" />
                    <div className="communication__detail">
                        <div className="details">
                            <p>Date:</p>
                            <span>
                                {moment(new Date()).format("DD/MM/YYYY")}
                            </span>
                        </div>
                        <div className="details">
                            <p>Business Name:</p>
                            <span>{business_name}</span>
                        </div>
                        <div className="details">
                            <p>Current Account Number:</p>
                            <span>{account_number}</span>
                        </div>
                        <div className="details">
                            <p>Dealer Currently Buying From:</p>
                            <span>{display_current_dealer}</span>
                        </div>
                        <div className="details">
                            <p>Distrigo Admin Notes:</p>
                            <span>{rla_notes}</span>
                        </div>
                        <div className="details">
                            <p>Status:</p>
                            <span>{potentialCustomerStatuses[`S_${potentialCustomer.status}`]
                                ? potentialCustomerStatuses[`S_${potentialCustomer.status}`]
                                : potentialCustomer.status}</span>
                        </div>
                    </div>
                </div>

                <div className="columns small-6 communication__address">
                    <div className="communication__detail">
                        <div className="details">
                            <p>Customer Name:</p>
                            <span>{`${first_name} ${last_name}`}</span>
                        </div>
                        <div className="details">
                            <p>Address:</p>
                            {address}
                        </div>
                        <div className="details">
                            <p>Phone:</p>
                            <span>{telephone}</span>
                        </div>
                        <div className="details">
                            <p>Email:</p> <span>{email}</span>
                        </div>
                        <div className="details">
                            <p>Mobile:</p> <span>{mobile}</span>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Header;
