import React, { useContext, useEffect, useState } from "react";
import TableLayout from "../../containers/TableLayout";
import Spinner from "../../containers/Spinner";
import PotentialCustomerRow from "../PotentialCustomersTable/PotentialCustomerRow";
import PotentialCustomerContext from "../../context/potentialcustomer/potentialCustomerContext";
import DealerContext from "../../context/dealer/dealerContext";

const DealerPotentialCustomersTable = ({ match }) => {
    const potentialCustomerContext = useContext(PotentialCustomerContext);
    const {
        potentialCustomers,
        getDealerPotentialCustomers,
        getPotentialCustomerStatuses,
        potentialCustomerStatuses,
        loading
    } = potentialCustomerContext;
    const [activePage, setActivePage] = useState(1);

    const dealerContext = useContext(DealerContext);
    const { dealer, getTTDealer, dealerLoading } = dealerContext;

    useEffect(() => {
        getPotentialCustomerStatuses();
        getTTDealer(match.params.id);
        getDealerPotentialCustomers(match.params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePageChange = pageNumber => {
        // setActivePage(pageNumber);
        // getCustomerPage(activePage);
    };

    if (loading || dealerLoading) {
        return (
            <React.Fragment>
                <div className="row">
                    <h3 className="title pad-t">
                        Potential Customers
                    </h3>
                    <TableLayout
                        getPage={handlePageChange}
                        activePage={activePage}
                        setActivePage={setActivePage}
                    >
                        <Spinner />
                    </TableLayout>
                </div>
            </React.Fragment>
        );
    } else {
        let title = `Potential Customers > ${dealer.name}`;
        return (
            <React.Fragment>
                <div className="row">
                    <h3 className="title pad-t">{title}</h3>
                    <TableLayout
                        getPage={handlePageChange}
                        activePage={activePage}
                        setActivePage={setActivePage}
                        hideFilter={true}
                    >
                        <table
                            id="dealer-potential-customers"
                            className="responsive"
                            width="100%"
                        >
                            <tbody>
                                <tr>
                                    <th>Business Name</th>
                                    <th>Account Number</th>
                                    <th>Current Dealer</th>
                                    <th>Post Code</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Email</th>
                                    <th>Contact Number</th>
                                    <th>Status</th>
                                    <th>Follow Up</th>
                                </tr>

                                {potentialCustomers.map(
                                    (potentialCustomer, i) => (
                                        <PotentialCustomerRow
                                            key={i}
                                            potentialCustomer={potentialCustomer}
                                            potentialCustomerStatuses={potentialCustomerStatuses}
                                        />
                                    )
                                )}
                            </tbody>
                        </table>
                    </TableLayout>
                </div>
            </React.Fragment>
        );
    }
};

export default DealerPotentialCustomersTable;
