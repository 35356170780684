import React, { Fragment, useContext, useEffect } from "react";
import { map, isString } from "lodash";
import TableLayout from "../../../containers/TableLayout";
import Spinner from "../../../containers/Spinner";
import HeadOfficeCommunicationRow from "./HeadOfficeCommunicationRow";
import CommunicationContext from "../../../context/communication/communicationContext";
import UserContext from "../../../context/user/userContext";
import SearchContext from "../../../context/search/searchContext";
import {
    arrayLen,
    checkArray,
    checkInteger,
    getFilterByValue,
    prepareDateString,
} from "../../../utils/utils";
import { useIsFirstRender } from "../../../utils/isFirstRender";
import { getCommunicationFilterOptions } from "../../communicationFilterOptions";
import GetOnFirstRender from "../../../tables/GetOnFirstRender";

const HeadOfficeCommunications = ({ match, location }) => {
    const {
        params: { filter, from, to },
    } = match;

    const {
        communicationDealers,
        getCommunicationDealers,
        loading,
        setCommunicationFilterValue,
        setCommunicationFilterOrDefault,
        communicationFilterValue,
        setCommunicationDealersPage,
        communicationDealersPage,
        communicationDealerCount,
    } = useContext(CommunicationContext);

    const { startDateMySQL, endDateMySQL } = useContext(SearchContext);

    const { groupType } = useContext(UserContext);
    const communicationFilterOptions = getCommunicationFilterOptions(groupType);
    const filterObject = getFilterByValue(
        communicationFilterOptions,
        communicationFilterValue
    );
    const filterValue = isString(filter) ? filter : communicationFilterValue;
    const { dates } = filterObject;

    const getItemsUrl = () => {
        getCommunicationDealers({
            communicationFilterValue: isString(filter)
                ? filter
                : communicationFilterValue,
            start: prepareDateString(from, startDateMySQL),
            end: prepareDateString(to, endDateMySQL),
        });
    };

    const getItemsState = () => {
        getCommunicationDealers({
            communicationFilterValue: communicationFilterValue,
            start: startDateMySQL,
            end: endDateMySQL,
        });
    };

    return (
        <Fragment>
            <div className="row">
                <h3 className="title pad-t">DISTRIGO PERKS Communications</h3>
                <GetOnFirstRender
                    setFilterValue={setCommunicationFilterValue}
                    from={from}
                    to={to}
                    filter={filter}
                    getItems={getItemsUrl}
                />
                <TableLayout
                    activePage={communicationDealersPage}
                    setActivePage={setCommunicationDealersPage}
                    showPagination={true}
                    totalItems={checkInteger(communicationDealerCount)}
                    getItems={getItemsState}
                    setFilterValue={setCommunicationFilterValue}
                    filterValue={communicationFilterValue}
                    filterOptions={communicationFilterOptions}
                    showDates={dates}
                >
                    <table
                        id="communications"
                        className="responsive"
                        width="100%"
                    >
                        <tbody>
                            <tr>
                                <th>Parts Distributor</th>
                                <th>Parts Distributor Code</th>
                                <th>Sent</th>
                                <th>Opened</th>
                                <th>Clicked</th>
                                <th>Comms Followed Up</th>
                                <th>Comms To Follow Up</th>
                                <th>Average Follow Up Time</th>
                                <th>Percentage Followed Up</th>
                                <th>View</th>
                            </tr>
                            {!loading &&
                                checkArray(communicationDealers) &&
                                map(
                                    communicationDealers,
                                    (communicationDealer, i) => (
                                        <HeadOfficeCommunicationRow
                                            key={communicationDealer.id}
                                            communicationDealer={
                                                communicationDealer
                                            }
                                            filterValue={
                                                communicationFilterValue
                                            }
                                            filterObject={filterObject}
                                        />
                                    )
                                )}
                        </tbody>
                    </table>
                    {loading ? <Spinner /> : null}
                </TableLayout>
            </div>
        </Fragment>
    );
};

export default HeadOfficeCommunications;
