import React, { Fragment, useContext, useEffect } from "react";
import TableHeader from "../../components/TableHeader";
import CommunicationRecipientsRow from "./CommunicationRecipientsRow";
import CommunicationsContext from "../../context/communication/communicationContext";
import { useIsFirstRender } from "../../utils/isFirstRender";
import { checkArray, getFilterByValue } from "../../utils/utils";
import { communicationFilterOptions } from "../communicationFilterOptions";
// import Spinner from "../../containers/Spinner";

const CommunicationRecipientsTable = ({ match }) => {
    const {
        params: { communication_id: communicationId },
    } = match;

    const {
        communicationRecipients,
        getCommunicationRecipients,
        communicationFilterValue,
    } = useContext(CommunicationsContext);

    const isFirstRender = useIsFirstRender();
    const filterObject = getFilterByValue(
        communicationFilterOptions,
        communicationFilterValue
    );
    const { dates } = filterObject;

    const firstCommunicationRecipient =
        communicationRecipients && checkArray(communicationRecipients)
            ? communicationRecipients[0]
            : null;
    const title =
        firstCommunicationRecipient &&
        firstCommunicationRecipient.communication.title
            ? `DISTRIGO PERKS Communications > ${firstCommunicationRecipient.communication.title}`
            : "DISTRIGO PERKS Communications";

    useEffect(() => {
        if (isFirstRender === true) getCommunicationRecipients(communicationId);
    }, []);

    return (
        <Fragment>
            <div className="row">
                <h3 className="title pad-t">{title}</h3>
                <table id="recipients" className="responsive" width="100%">
                    <tbody>
                        <tr>
                            <th>Customer</th>
                            <th>ID</th>
                            <th>Communication Title</th>
                            <th>Sent</th>
                            <th>Opened</th>
                            <th>Clicked</th>
                            <th>Followed Up?</th>
                            <th />
                        </tr>

                        {communicationRecipients &&
                            checkArray(communicationRecipients) &&
                            communicationRecipients.map(
                                (communicationRecipient, i) => (
                                    <CommunicationRecipientsRow
                                        key={i}
                                        communicationRecipient={
                                            communicationRecipient
                                        }
                                    />
                                )
                            )}
                    </tbody>
                </table>
            </div>
        </Fragment>
    );
};

export default CommunicationRecipientsTable;
