import React, { useRef, useEffect, useState, cloneElement } from "react";
import { map, isArray, isObject } from "lodash";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { createElementWithId } from "../utils/utils";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PopupOverlay = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
`;
export const StandardPopupSize = styled.div`
    width: 90%;
    height: 88vh;
    overflow: auto;
    margin: 2vh auto 0 auto;
`;

const CloseWrap = styled.div`
    z-index: 1;
    color: ${props => props.color || "white"};
    cursor: pointer;
`;

const CloseButton = ({ closeButtonAction, color }) => (
    <CloseWrap onClick={closeButtonAction} color={color}>
        <FontAwesomeIcon style={{ fontSize: "1.8rem" }} icon={faTimes} />
    </CloseWrap>
);

const Popup = ({ show, setVisible, children }) => {
    const container = useRef(createElementWithId("div", "modal-root"));
    const close = e => {
        if (e.target.id === "popup-overlay") setVisible(false);
    };

    const closeButtonAction = () => setVisible(false);

    useEffect(() => {
        document.getElementsByTagName("body")[0].appendChild(container.current);
    }, []);

    // Note: Supply close button as a prop to the popups children
    const renderCloseButton = ({ color }) => {
        return (
            <CloseButton color={color} closeButtonAction={closeButtonAction} />
        );
    };

    return show === true
        ? createPortal(
              <PopupOverlay
                  id="popup-overlay"
                  style={{ overflow: "scroll" }}
                  onClick={close}
              >
                  {!isArray(children) &&
                      isObject(children) &&
                      cloneElement(children, {
                          CloseButton: renderCloseButton
                      })}
                  {isArray(children) &&
                      map(children, (child, i) => {
                          return cloneElement(child, {
                              key: i,
                              CloseButton: renderCloseButton
                          });
                      })}
              </PopupOverlay>,
              container.current
          )
        : null;
};

export default Popup;
