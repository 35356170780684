import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
    secondsToHms,
    displayPercentage,
    replaceUnderscores
} from "../../../utils/utils";
import SearchContext from "../../../context/search/searchContext";

export default function HeadOfficeCommunicationRow({
    communicationDealer,
    filterValue: filterValueRaw,
    filterObject
}) {
    const { startDateMySQL, endDateMySQL } = useContext(SearchContext);

    const filter = replaceUnderscores(filterValueRaw);
    const { dates } = filterObject;

    const link = dates
        ? `/communications-dealer/${communicationDealer.id}/${filter}/${startDateMySQL}/${endDateMySQL}`
        : `/communications-dealer/${communicationDealer.id}/${filter}`;

    return (
        <tr key={communicationDealer.id}>
            <td className="PD">
                <Link to={link}>{communicationDealer.dealer_name}</Link>
            </td>
            <td className="PD">{communicationDealer.dealer_code}</td>
            <td style={{ background: "#E8E8E8" }} className="PD">
                {communicationDealer.total_sent}
            </td>
            <td style={{ background: "#D3D3D3" }} className="PD">
                {communicationDealer.total_opened}
            </td>
            <td style={{ background: "#B4B4B4" }} className="PD">
                {communicationDealer.total_clicked}
            </td>
            <td className="PD">{communicationDealer.total_followed_up}</td>
            <td className="PD">{communicationDealer.total_to_follow_up}</td>
            <td className="PD">
                {communicationDealer.average_followed_up > 0 &&
                communicationDealer.average_followed_up_time > 0
                    ? secondsToHms(communicationDealer.average_followed_up_time)
                    : "-"}
            </td>
            <td className="PD">
                {displayPercentage(communicationDealer.average_followed_up)}
            </td>
            <td className="communications__buttons">
                <Link to={link}>
                    <button className="button button--black">View </button>
                </Link>
            </td>
        </tr>
    );
}
