import React, { useReducer } from "react";
import moment from "moment";
import SearchContext from "./searchContext";
import SearchReducer from "./SearchReducer";
import {
    SET_LOADING,
    SET_START_DATE,
    SET_END_DATE,
    SET_SEARCH_TEXT,
    SET_PAGINATION_VALUE,
    TOGGLE_FORM_SUBMIT,
} from "../types";
import { getLocalStorage, updateLocalStorage } from "../../utils/utils";

const SearchState = (props) => {
    let n = moment().format("YYYYMMDD");

    let fdua = getLocalStorage("fduAt") ? getLocalStorage("fduAt") : null;

    let s =
        getLocalStorage("startDate") && fdua === n
            ? new Date(getLocalStorage("startDate"))
            : new Date(moment().subtract(30, "days").format("ll"));

    let t =
        getLocalStorage("endDate") && fdua === n
            ? new Date(getLocalStorage("endDate"))
            : new Date(moment().format("ll"));

    updateLocalStorage("fduAt", n);

    const initialState = {
        startDate: s,
        endDate: t,
        paginationValue: 10,
        searchText: "",
        loading: false,
        formSubmit: false,
    };

    const [state, dispatch] = useReducer(SearchReducer, initialState);

    /**
     * Cause form to be submitted via toggling boolean
     * (see SideEffect in TableHeader.js)
     *
     * @param {boolean} submit
     * @return {undefined}
     */
    const toggleFormSubmit = (submit) => {
        dispatch({
            type: TOGGLE_FORM_SUBMIT,
            payload: submit,
        });
    };

    const setSearchText = (text) => {
        setLoading();
        dispatch({
            type: SET_SEARCH_TEXT,
            payload: text,
        });
    };

    const setEndDate = (date) => {
        setLoading();
        dispatch({
            type: SET_END_DATE,
            payload: date,
        });
    };

    const setStartDate = (date) => {
        setLoading();
        dispatch({
            type: SET_START_DATE,
            payload: date,
        });
    };

    /**
     * @param {string} start - date string
     * @return {undefined}
     */
    const setStartDateIfValid = (start) => {
        const startDate = moment(start, "YYYY-MM-DD");

        if (moment(startDate).isValid()) setStartDate(startDate._d);
    };

    /**
     * @param {string} end - date string
     * @return {undefined}
     */
    const setEndDateIfValid = (end) => {
        const endDate = moment(end, "YYYY-MM-DD");

        if (moment(endDate).isValid()) setEndDate(endDate._d);
    };

    const setPaginationValue = (text) => {
        setLoading();
        dispatch({
            type: SET_PAGINATION_VALUE,
            payload: text,
        });
    };

    // Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });
    return (
        <SearchContext.Provider
            value={{
                startDate: state.startDate,
                endDate: state.endDate,
                startDateMySQL: moment(state.startDate).format("YYYY-MM-DD"),
                endDateMySQL: moment(state.endDate).format("YYYY-MM-DD"),
                searchText: state.searchText,
                paginationValue: state.paginationValue,
                loading: state.loading,
                formSubmit: state.formSubmit,
                setLoading,
                setEndDate,
                setStartDate,
                setStartDateIfValid,
                setEndDateIfValid,
                setPaginationValue,
                setSearchText,
                toggleFormSubmit,
            }}
        >
            {props.children}
        </SearchContext.Provider>
    );
};

export default SearchState;
