import { get } from "lodash";
import {
    getArray,
    getInteger,
    checkInteger,
    checkString
} from "../../utils/utils";
import {
    SET_LOADING,
    GET_LEAD_COUNTS,
    GET_LEADS,
    GET_DEALER_LEADS,
    GET_LEAD_DEALERS,
    GET_LEAD,
    GET_LEAD_PAGE,
    LEAD_STATUS_UPDATED,
    SET_ACTIVE_PAGE_VALUE,
    SET_LEAD_DEALERS_PAGE,
    SET_DEALER_LEADS_PAGE,
    SET_LEAD_FILTER_VALUE,
    SET_LEAD_PAGE
} from "../types";

export default (state, action) => {
    switch (action.type) {
        case SET_LEAD_PAGE:
            return {
                ...state,
                leadsPage: checkInteger(action.payload)
            };

        case SET_LEAD_FILTER_VALUE:
            return {
                ...state,
                leadFilterValue: checkString(action.payload)
            };

        case SET_DEALER_LEADS_PAGE:
            return {
                ...state,
                dealerLeadsPage: checkInteger(action.payload)
            };

        case SET_LEAD_DEALERS_PAGE:
            return {
                ...state,
                leadDealersPage: checkInteger(action.payload)
            };

        case SET_ACTIVE_PAGE_VALUE: // deprecated?
            return {
                ...state,
                activePage: action.payload
            };

        /* Deprecated ?
        case GET_TOTAL_LEADS:
            return {
                ...state,
                leadTotalCount: getInteger(action, "payload.data.count"),
                loading: false
            };
			*/

        case GET_LEADS:
            return {
                ...state,
                leads: getArray(action, "payload.data"),
                leadCount: getInteger(action, "payload.meta.total"),
                leadLinks: get(action, "payload.links", {}),
                loading: false
            };
        case GET_LEAD_COUNTS:
            return {
                ...state,
                leadCounts: get(action, "payload.data", {}),
                loading: false
            };
        case GET_DEALER_LEADS:
            return {
                ...state,
                leads: getArray(action, "payload.data"),
                leadCount: getInteger(action, "payload.meta.total"),
                leadLinks: get(action, "payload.links", {}),
                loading: false
            };
        case GET_LEAD_DEALERS:
            return {
                ...state,
                leadDealers: get(action, "payload.data", {}),
                leadDealerCount: getInteger(action, "payload.meta.total"),
                leadDealerLinks: get(action, "payload.links", {}),
                loading: false
            };
        case GET_LEAD_PAGE:
            return {
                ...state,
                leads: checkInteger(action.payload),
                loading: false
            };
        case GET_LEAD:
            return {
                ...state,
                lead: action.payload,
                loading: false
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true
            };

        case LEAD_STATUS_UPDATED:
            return {
                ...state,
                lead: action.payload
            };

        default:
            return state;
    }
};
