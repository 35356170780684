import React, { useReducer } from "react";
import axios from "axios";
import DealerContext from "./dealerContext";
import DealerReducer from "./DealerReducer";
import { SET_LOADING, GET_TT_DEALER, GET_DC_DEALER } from "../types";

const DealerState = (props) => {
    const initialState = {
        dealer: {},
        dealerLoading: false,
        loading: false,
    };

    const [state, dispatch] = useReducer(DealerReducer, initialState);

    const getTTDealer = async (id) => {
        setLoading();
        const res = await axios.get(`/api/v1/tt-dealer/${id}`);
        dispatch({
            type: GET_TT_DEALER,
            payload: res.data.data,
        });
    };
    const getDCDealer = async (id) => {
        setLoading();
        const res = await axios.get(`/api/v1/dc-dealer/${id}`);
        dispatch({
            type: GET_DC_DEALER,
            payload: res.data.data,
        });
    };
    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <DealerContext.Provider
            value={{
                dealer: state.dealer,
                dealerLoading: state.loading,
                setLoading,
                getTTDealer,
                getDCDealer,
            }}
        >
            {props.children}
        </DealerContext.Provider>
    );
};

export default DealerState;
