import React, { Fragment, useContext, useEffect } from "react";
import { map, isString, isArray } from "lodash";
import { checkInteger, getFilterByValue, checkString } from "../../utils/utils";
import { useIsFirstRender } from "../../utils/isFirstRender";
import TableLayout from "../../containers/TableLayout";
import Spinner from "../../containers/Spinner";
import CommunicationRow from "../CommunicationsTable/CommunicationRow";
import CommunicationContext from "../../context/communication/communicationContext";
import UserContext from "../../context/user/userContext";
import DealerContext from "../../context/dealer/dealerContext";
import SearchContext from "../../context/search/searchContext";
import { communicationFilterOptions } from "../communicationFilterOptions";
import GetOnFirstRender from "../../tables/GetOnFirstRender";

const DealerCommunicationsTable = ({ match }) => {
    const {
        params: { filter, dealer_id: dealerId, from, to }
    } = match;

    const {
        dealerCommunications,
        getDealerCommunications,
        loading,
        dealerCommunicationsPage,
        setDealerCommunicationsPage,
        communicationFilterValue,
        setCommunicationFilterValue,
        setCommunicationFilterOrDefault,
        dealerCommunicationsCount
    } = useContext(CommunicationContext);

    const { groupType, user } = useContext(UserContext);

    const { dealer, getDCDealer, dealerLoading } = useContext(DealerContext);
    const apiUrl = checkString(process.env.REACT_APP_BASE_URL);
    const filterObject = getFilterByValue(
        communicationFilterOptions,
        communicationFilterValue
    );
    const { dates } = filterObject;

    const title =
        dealer && dealer.name
            ? `Communications > ${dealer.name}`
            : "Communications";

    const getItemsUrl = () => {
        getDCDealer(dealerId);
        getDealerCommunications({
            communicationFilterValue: isString(filter)
                ? filter
                : communicationFilterValue,
            dealerId
        });
    };

    const getItemsState = () => {
        getDealerCommunications({
            communicationFilterValue: communicationFilterValue,
            dealerId
        });
    };

    return (
        <Fragment>
            <div className="row">
                <h3 className="title pad-t">{title}</h3>
                <GetOnFirstRender
                    from={from}
                    to={to}
                    filter={filter}
                    setFilterValue={setCommunicationFilterValue}
                    getItems={getItemsUrl}
                />
                <TableLayout
                    activePage={dealerCommunicationsPage}
                    setActivePage={setDealerCommunicationsPage}
                    getItems={getItemsState}
                    totalItems={checkInteger(dealerCommunicationsCount)}
                    filterOptions={communicationFilterOptions}
                    filterValue={communicationFilterValue}
                    setFilterValue={setCommunicationFilterValue}
                    showDates={dates}
                >
                    <table
                        id="dealer-communications"
                        className="responsive"
                        width="100%"
                    >
                        <tbody>
                            <tr>
                                <th>Communication Title</th>
                                <th>Method</th>
                                <th>Send Date</th>
                                <th>Sent</th>
                                <th>Opened</th>
                                <th>Clicked</th>
                                <th>View</th>
                            </tr>

                            {!loading &&
                                isArray(dealerCommunications) &&
                                map(
                                    dealerCommunications,
                                    (communication, i) => (
                                        <CommunicationRow
                                            key={i}
                                            communication={communication}
                                            filterValue={
                                                communicationFilterValue
                                            }
                                            filterObject={filterObject}
                                        />
                                    )
                                )}
                        </tbody>
                    </table>
                    {loading || dealerLoading ? <Spinner /> : null}
                </TableLayout>
            </div>
        </Fragment>
    );
};

export default DealerCommunicationsTable;
