import React from "react";

export default function BasketSection({ details }) {
    const { title, info, total_price } = details;
    if (! info) {
        return (<div></div>);
    }
    return (
        <div>
            <section className="detail">
                <div className="large-6 columns">
                    <h3>{title}</h3>
                    <p>Total Price: £{total_price}</p>
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <th>Part Number</th>
                                <th>Category</th>
                                <th>Trade Price</th>
                                <th>Quantity</th>
                            </tr>
                            {info && info.map((stat, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="detail-label">
                                            {stat.partNumber}
                                        </td>
                                        <td>{stat.category}{ stat.categoryParent ? ` - ${stat.categoryParent}` : ''} </td>
                                        <td>
                                            £{(stat.price
                                                ? Number(stat.price)
                                                : 0).toFixed(2)}
                                            </td>
                                        <td>{stat.quantity}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    );
}
