import React from "react";
import styled from "styled-components";
import Popup, { StandardPopupSize } from "../components/Popup";
import ReactHtmlParser from "react-html-parser";

const PromotionBackground = styled.div`
    background-color: white;
    border-radius: 4px;
`;

const PromotionImage = styled.img`
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
`;
const PromoPopupWrap_ = ({ className, CloseButton, children }) => {
    return (
        <div className={className}>
            <div
                style={{
                    top: "0.7rem",
                    right: "0.7rem",
                    position: "absolute",
                    zIndex: "100"
                }}
            >
                <CloseButton color={"#ffffff"} />
            </div>
            {children}
        </div>
    );
};

const PromoPopupWrap = styled(PromoPopupWrap_)`
    max-width: 600px;
    height: 88vh;
    margin: 12vh auto 0 auto;
    position: relative;
    overflow: auto;
`

const PromotionPopup = ({ show, setVisible, campaign, imgUrl }) => {
    return (
        <Popup show={show} setVisible={setVisible}>
          <PromoPopupWrap>
                <PromotionBackground>
                    <PromotionImage src={imgUrl} />
                    <div style={{ padding: "2rem" }} className="row columns">
                        <div className="title text-center">
                            <h3>{campaign.banner_title}</h3>
                            <p className="summary">
                                {ReactHtmlParser(campaign.summary)}
                            </p>
                        </div>
                    </div>
                    <div style={{ padding: "2rem" }} className="row">
                        <div className="summary">
                            <h6>{ReactHtmlParser(campaign.how_it_works)}</h6>
                        </div>

                        <div className="terms">
                            {ReactHtmlParser(campaign.terms_and_conditions)}
                        </div>
                    </div>
                </PromotionBackground>
            </PromoPopupWrap>
        </Popup>
    );
};

export default PromotionPopup;
