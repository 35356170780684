import React, { Fragment } from "react";
import img from "../../images/blur_placeholder.jpg";

const BlurredPromotion = ({ campaign }) => {
    return (
        <Fragment>
            <div className="column promotion_details">
                <img src={img} alt="" />
                <p>
                    Competition coming soon
                </p>
            </div>
        </Fragment>
    );
};

export default BlurredPromotion;




