import React, { useReducer, useContext } from "react";
import axios from "axios";
import moment from "moment";
import { get } from "lodash";

import RewardContext from "./rewardContext";

import RewardReducer from "../reward/RewardReducer";
import {
    SET_LOADING,
    GET_REWARD_COUNTS,
    GET_REWARD_DEALERS,
    GET_DEALER_REWARDS,
    GET_REWARDS,
    GET_REWARD,
    GET_REWARD_PAGE,
    REWARD_STATUS_UPDATED,
    SET_ACTIVE_PAGE_VALUE,
    SET_REWARD_DEALERS_PAGE,
    SET_DEALER_REWARDS_PAGE,
    SET_REWARD_PAGE,
    SET_REWARD_FILTER_VALUE,
} from "../types";
import SearchContext from "../search/searchContext";
import { checkRewardFilter } from "../../tables/rewardFilterOptions";
import { replaceHyphens, checkFilter } from "../../utils/utils";

const RewardState = (props) => {
    const searchContext = useContext(SearchContext);
    const { startDateMySQL, endDateMySQL, paginationValue, searchText } = searchContext;

    const initialState = {
        test: 1,
        reward: {},
        rewardLinks: {},
        rewardCount: 0,
        rewardDealerCount: 0,
        rewardCounts: [],
        rewards: [],
        rewardDealers: [],
        loading: false,
        rewardFilterValue: "all_dates",
        activePage: 1,
        rewardsPage: 1,
        dealerRewardsPage: 1,
        rewardDealersPage: 1,
    };

    const [state, dispatch] = useReducer(RewardReducer, initialState);

    /**
     * @param {string} filter
     * @return {undefined}
     */
    const setRewardFilterValue = (filter) => {
        dispatch({
            type: SET_REWARD_FILTER_VALUE,
            payload: filter,
        });
    };

    /**
     * Ensure the filter value passed is a rewardFilter
     * if not, set default filter instead
     *
     * @param {string} rawFilter
     * @return {undefined}
     */
    const setRewardFilterOrDefault = (rawFilter) => {
        const filter = checkRewardFilter(replaceHyphens(rawFilter));
        setRewardFilterValue(filter);
    };

    // deprecated?
    const setActivePage = (pageNumber) => {
        dispatch({
            type: SET_ACTIVE_PAGE_VALUE,
            payload: pageNumber,
        });
    };

    /**
     * @param {integer} pageNumber
     * @return {undefined}
     */
    const setRewardPage = (pageNumber) => {
        dispatch({
            type: SET_REWARD_PAGE,
            payload: pageNumber,
        });
    };

    /**
     * @param {integer} pageNumber
     * @return {undefined}
     */
    const setDealerRewardsPage = (pageNumber) => {
        dispatch({
            type: SET_DEALER_REWARDS_PAGE,
            payload: pageNumber,
        });
    };

    /**
     * @param {integer} pageNumber
     * @return {undefined}
     */
    const setRewardDealersPage = (pageNumber) => {
        dispatch({
            type: SET_REWARD_DEALERS_PAGE,
            payload: pageNumber,
        });
    };

    const updateRewardStatus = async ({ values, rewardFilterValue }) => {
        const res = await axios.post(`/api/v1/reward/status-update`, values);
        const reward = get(res, "data.data", {});

        const data = {
            data: reward,
        };
        dispatch({
            type: REWARD_STATUS_UPDATED,
            payload: data,
        });
        getRewards({ rewardFilterValue: rewardFilterValue });

    };

    const getRewardCounts = async () => {
        setLoading();
        const res = await axios.get(
            `/api/v1/reward/counts/${startDateMySQL}/${endDateMySQL}`
        );
        dispatch({
            type: GET_REWARD_COUNTS,
            payload: get(res, "data", {}),
        });
    };

    const getRewardDealers = async ({
        rewardFilterValue: rewardFilterValueRaw,
    }) => {
        const filter = replaceHyphens(rewardFilterValueRaw);

        setLoading();
        const res = await axios.get(
            `/api/v1/reward-dealers/${filter}/${startDateMySQL}/${endDateMySQL}?pagination=${paginationValue}&page=${state.rewardDealersPage}`
        );
        dispatch({
            type: GET_REWARD_DEALERS,
            payload: get(res, "data", {}),
        });
    };

    /**
     * param {string}   rewardFilterValue (object prop)
     * param {integer}  id (object prop)
     * return {undefined}
     */
    const getDealerRewards = async ({
        rewardFilterValue: rewardFilterValueRaw,
        id,
    }) => {
        const filter = replaceHyphens(rewardFilterValueRaw);

        setLoading();
        const res = await axios.get(
            `/api/v1/reward/dealer/${id}/${filter}/${startDateMySQL}/${endDateMySQL}?pagination=${paginationValue}&page=${state.dealerRewardsPage}`
        );

        dispatch({
            type: GET_DEALER_REWARDS,
            payload: get(res, "data", {}),
        });
    };

    /**
     * param {string} rewardFilterValue (object prop)
     * return {undefined}
     */
    const getRewards = async ({ rewardFilterValue: rewardFilterValueRaw }) => {
        const filter = replaceHyphens(rewardFilterValueRaw);

        setLoading();

        const res = await axios.get(
            `/api/v1/rewards/${filter}/${startDateMySQL}/${endDateMySQL}?pagination=${paginationValue}&page=${state.rewardsPage}&searchText=${searchText}`
        );
        dispatch({
            type: GET_REWARDS,
            payload: get(res, "data", {}),
        });
    };

    /**
     * param {integer} pageNumber
     * return {undefined}
     */
    const getRewardPage = async (pageNumber) => {
        setLoading();
        const res = await axios.get(
            `/api/v1/rewards/${startDateMySQL}/${endDateMySQL}?page=${pageNumber}`
        );
        dispatch({
            type: GET_REWARD_PAGE,
            payload: get(res, "data", {}),
        });
    };

    /**
     * param {integer} id
     * return {undefined}
     */
    const getReward = async (id) => {
        setLoading();
        const res = await axios.get(`/api/v1/reward/${id}`);
        const reward = res.data.data;

        const data = {
            data: reward,
        };
        dispatch({
            type: GET_REWARD,
            payload: data,
        });
    };

    // Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });
    return (
        <RewardContext.Provider
            value={{
                reward: state.reward,
                rewards: state.rewards,
                rewardDealers: state.rewardDealers,
                rewardLinks: state.rewardLinks,
                rewardCount: state.rewardCount,
                rewardCounts: state.rewardCounts,
                rewardDealerCount: state.rewardDealerCount,
                loading: state.loading,
                activePage: state.activePage, // deprecated?
                rewardsPage: state.rewardsPage,
                dealerRewardsPage: state.dealerRewardsPage,
                rewardDealersPage: state.rewardDealersPage,
                rewardFilterValue: state.rewardFilterValue,
                setRewardPage,
                setRewardFilterValue,
                setRewardFilterOrDefault,
                setDealerRewardsPage,
                setRewardDealersPage,
                setActivePage,
                getRewardPage,
                setLoading,
                // getTotalRewards,
                getRewards,
                getRewardCounts,
                getRewardDealers,
                getDealerRewards,
                getReward,
                updateRewardStatus,
            }}
        >
            {props.children}
        </RewardContext.Provider>
    );
};

export default RewardState;
