import {
    SET_LOADING,
    GET_DC_DEALER,
    GET_TT_DEALER,
} from "../types";

export default (state, action) => {
    switch (action.type) {
        case GET_DC_DEALER:
            return {
                ...state,
                dealer: action.payload,
                loading: false
            };
        case GET_TT_DEALER:
            return {
                ...state,
                dealer: action.payload,
                loading: false
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
};
