import { SET_LOADING, SET_LOADED } from "../context/types";
import { checkArray, checkString } from "./utils";
import { filter } from "lodash";

/**
 * Add itemLoading to the currently loading items,
 * set loading flag to true
 *
 * @param {string} itemLoading (action name)
 * @return {undefined}
 */
export const setLoading = (itemsLoading, dispatch) => itemLoading => {
    dispatch({
        type: SET_LOADING,
        payload: [...checkArray(itemsLoading), checkString(itemLoading)]
    });
};

/**
 * Update the currently loading items, if all items
 * have loaded, then also set loading to false
 *
 * @param {string} itemLoaded (action name)
 * @return {undefined}
 */
export const setLoaded = (itemsLoadingRaw, dispatch) => itemLoadedRaw => {
    const itemsLoading = checkArray(itemsLoadingRaw);
    const itemLoading = checkString(itemLoadedRaw);

    // Remove the loaded item from itemsLoading
    const loaded = filter(itemsLoading, item => item !== itemLoading);

    dispatch({
        type: SET_LOADED,
        payload: {
            itemsLoading: loaded,
            loading: loaded.length !== 0 ? true : false
        }
    });
};
