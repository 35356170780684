import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export default function CommunicationRecipientsRow({
    communicationRecipient,
    showPopup,
    setRecipient
}) {
    const showRecipient = () => {
        setRecipient(communicationRecipient);
        showPopup();
    };

    return (
        <tr key={communicationRecipient.id}>
            <td className="PD">
                <Link to={`/customer/${communicationRecipient.recipient_id}`}>
                    {communicationRecipient.trade_team_customer}
                </Link>
            </td>
            <td className="PD">{communicationRecipient.trade_team_id}</td>
            <td className="PD">{communicationRecipient.communication.title}</td>
            <td style={{ background: "#E8E8E8" }} className="PD">
                {communicationRecipient.sent_at
                    ? moment(communicationRecipient.sent_at).format("DD/MM/YYYY HH:mm")
                    : "-"}
            </td>
            <td style={{ background: "#D3D3D3" }} className="PD">
                {communicationRecipient.opened_at
                    ? moment(communicationRecipient.opened_at).format("DD/MM/YYYY HH:mm")
                    : "-"}
            </td>
            <td style={{ background: "#B4B4B4" }} className="PD">
                {communicationRecipient.clicked_at
                    ? moment(communicationRecipient.clicked_at).format("DD/MM/YYYY HH:mm")
                    : "-"}
            </td>
            <td className="PD">{communicationRecipient.display_sale_status}</td>
            <td className="PD" className="communication__buttons">
                <button
                    onClick={showRecipient}
                    className="button button--black"
                >
                    View{" "}
                </button>
            </td>
        </tr>
    );
}

// <Link to={`/communication-recipient/${communicationRecipient.communication_id}/${communicationRecipient.uuid}`} >
