import React from "react";
import { checkString } from "../../utils/utils";

export default function ReportRow({
    report,
    groupType,
    user,
    startDateMySQL,
    endDateMySQL
}) {
    const apiUrl = checkString(process.env.REACT_APP_BASE_URL);

    return (
        <tr key={report.key}>
            <td className="PD">{report.title}</td>
            <td className="PD">{report.description}</td>

            <td className="reports__buttons PD">
                <a
                    href={`${apiUrl}/download/${report.key}/${user.uuid}/${groupType}/${startDateMySQL}/${endDateMySQL}`}
                >
                    <button className="button button--black">View </button>
                </a>
            </td>
        </tr>
    );
}
