import { map, filter, includes } from "lodash";
import { checkFilter, checkArray, checkString } from "../utils/utils";

export const defaultRewardFilterOption = {
    Dealer: "credit_ordered_dates",
    "Dealer Group": "all_dates",
    Region: "all_dates",
    Zone: "all_dates",
};

/* TODO - USE THE NEW DATES FUNCTIONALITY */
/* TODO - USE OPTGROUP CORRECTLY IF REQUIRED */

export const rewardFilterOptions = [
    {
        name: "All",
        value: "all_dates",
        dates: true,
        availableTo: ["Dealer", "Dealer Group", "Zone", "Region"],
    },
    {
        name: "Credit - New",
        value: "credit_ordered_dates",
        dates: true,
        availableTo: ["Dealer", "Dealer Group", "Zone", "Region"],
    },
    {
        name: "Credit - Used",
        value: "credit_dispatched_dates",
        dates: true,
        availableTo: ["Dealer", "Dealer Group", "Zone", "Region"],
    },

];

export const rewardFilterValues = () =>
    map(rewardFilterOptions, (f) => f.value);

/**
 * Check if given filter a reward filter value,
 * if not, return the default "all"
 *
 * @param {string} filter
 * @return {string} filter || defaultFilter
 */
export const checkRewardFilter = (filter) =>
    checkFilter(rewardFilterValues())(filter);

/**
 * Get available reward filter options for users group
 *
 * @param {string} groupType
 * @return {array} availableRewardFilterOptions
 */
export const getRewardFilterOptions = (groupType) =>
    filter(rewardFilterOptions, (o) =>
        includes(checkArray(o.availableTo), checkString(groupType))
    );

/**
 * Get available reward filter options for users group
 *
 * @param {string} groupType
 * @return {array} availableRewardFilterOptions
 */
export const getDefaultRewardFilterOption = (groupType) =>
    defaultRewardFilterOption[groupType]
        ? defaultRewardFilterOption[groupType]
        : "";
