import React from "react";
import spinner from "./distrigo_logo.svg";
const Spinner = () => (
    <div style={{ margin: "100px 100px" }}>
        <img
            src={spinner}
            alt="loading..."
            style={{ width: "200px", display: "block", margin: "auto" }}
        />
    </div>
);

export const SpinnerSmall = () => (
    <div style={{ margin: "10px" }}>
        <img
            src={spinner}
            alt="loading..."
            style={{ width: "60px", display: "block", margin: "auto" }}
        />
    </div>
);

export default Spinner;
