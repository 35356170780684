import { map, filter, includes } from "lodash";
import { checkFilter, checkArray, checkString } from "../utils/utils";

export const defaultCustomerFilterOption = {
	Dealer: "all_perks_dates",
    "Dealer Group": "all_dates",
    Region: "all_perks_dates",
	Zone: "all_perks_dates",
}

export const customerFilterOptions = [
    {
        value: "all_perks_dates",
        name: "Customers activated",
        dates: true,
        availableTo: ["Dealer", "Dealer Group", "Zone", "Region"] // d
    },
    {
        value: "all_perks_points_dates",
        name: "Customer points",
        dates: true,
        availableTo: ["Dealer", "Dealer Group", "Zone", "Region"]
    },
    {
        value: "all",
        name: "Total customers eligible to join",
        dates: false,
        availableTo: ["Zone", "Region"]
    },
    {
        value: "not_perks",
        name: "Total customers to be activated",
        dates: false,
        availableTo: ["Dealer", "Dealer Group", "Zone", "Region"] // d
    },
	 // Delete below on confirmation from Adam
    // {
        // value: "all_perks",
        // name: "Total customers activated",
        // dates: false,
        // availableTo: ["Dealer", "Zone", "Region"] // d
    // },
    {
        value: "all_perks_contactable",
        name: "Total contactable customers",
        dates: false,
        availableTo: ["Dealer", "Dealer Group", "Zone", "Region"] // d
    },
    {
        value: "all_perks_unverified",
        name: "Total unverified customers",
        dates: false,
        availableTo: ["Dealer", "Dealer Group", "Zone", "Region"] // d
    },
];

export const customerFilterValues = () =>
    map(customerFilterOptions, f => f.value);

/**
 * check if given filter is a customer filter value,
 * if not, return the default "all"
 *
 * @param {string} filter
 * @return {string} filter || defaultFilter
 */
export const checkCustomerFilter = filter =>
    checkFilter(customerFilterValues())(filter);

/**
 * Get available customer filter options for users group
 *
 * @param {string} groupType
 * @return {array} availableCustomerFilterOptions
 */
export const getCustomerFilterOptions = groupType =>
    filter(customerFilterOptions, o =>
        includes(checkArray(o.availableTo), checkString(groupType))
    );
