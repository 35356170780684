import { map, filter, includes } from "lodash";
import { checkFilter, checkArray, checkString } from "../utils/utils";

export const defaultLeadFilterOption = {
	Dealer: "all_dates",
    "Dealer Group": "all_dates",
	Region: "all_dates",
	Zone: "all_dates"
}

export const leadFilterOptions = [
    {
        value: "all_dates",
        name: "Leads received",
        dates: true,
        availableTo: ["Dealer", "Dealer Group", "Zone", "Region"]
    },
    {
        value: "not_actioned_dates",
        name: "Leads still to be actioned",
        dates: true,
        availableTo: ["Dealer", "Dealer Group", "Zone", "Region"]
    },
    {
        value: "sale_made_dates",
        name: "Sales made",
        dates: true,
        availableTo: ["Zone", "Region"]
    },
    {
        value: "sale_not_made_dates",
        name: "Sales not made",
        dates: true,
        availableTo: ["Zone", "Region"]
    },
    {
        value: "actioned_dates",
        name: "Leads actioned",
        dates: true,
        availableTo: ["Dealer", "Dealer Group", "Zone", "Region"]
    },
    {
        value: "basket_not_actioned_dates",
        name: "Basket leads still to be actioned",
        dates: true,
        availableTo: ["Dealer", "Dealer Group", "Zone", "Region"]
    },
    {
        value: "credit_not_actioned_dates",
        name: "Credit leads still to be actioned",
        dates: true,
        availableTo: ["Dealer", "Dealer Group", "Zone", "Region"]
    },

];

export const leadFilterValues = () => map(leadFilterOptions, f => f.value);

/**
 * Check if given filter a lead filter value,
 * if not, return the default "all"
 *
 * @param {string} filter
 * @return {string} filter || defaultFilter
 */
export const checkLeadFilter = filter =>
    checkFilter(leadFilterValues())(filter);

/**
 * Get available lead filter options for users group
 *
 * @param {string} groupType
 * @return {array} availableLeadFilterOptions
 */
export const getLeadFilterOptions = groupType =>
    filter(leadFilterOptions, o =>
        includes(checkArray(o.availableTo), checkString(groupType))
    );
