import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./sass/app.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "./utils/ErrorBoundary";
require("./config/axiosConfig");

const supportsHistory = "pushState" in window.history;

ReactDOM.render(
    <BrowserRouter forceRefresh={!supportsHistory}>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </BrowserRouter>,
    document.getElementById("root")
);
