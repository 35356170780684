import React, { Fragment, useContext, useEffect } from "react";
import Pagination from "react-js-pagination";
import { checkInteger, parseInteger, runFunction } from "../../utils/utils";
import { useIsFirstRender } from "../../utils/isFirstRender";
import SearchContext from "../../context/search/searchContext";
import ExternalLink from "../../components/ExternalLink";

const pageRangeDisplay = (totalItems, itemsPerPage) => {
    const totalItems_ = checkInteger(totalItems);
    const itemsPerPage_ = checkInteger(itemsPerPage);
    const maxVisibleRange = 5;

    if (itemsPerPage_ === 0) return 1;

    const range = Math.ceil(totalItems_ / itemsPerPage_);

    return !(range > maxVisibleRange) ? range : maxVisibleRange;
};

const TableFooter = ({
    getPage,
    activePage,
    setActivePage,
    showPagination,
    totalItems,
    getItems,
    exportLink,
}) => {
    const { setPaginationValue, paginationValue } = useContext(SearchContext);

    const handlePageChange = (pageNumber) => {
        runFunction(setActivePage, [pageNumber]);
    };
    const isFirstRender = useIsFirstRender();

    const updatePaginationValue = (x) => {
        const val =
            parseInteger(x.target.value) === 0
                ? 1
                : parseInteger(x.target.value);
        setPaginationValue(val);
        setActivePage(1);
    };

    const pageRangeDisplayed = pageRangeDisplay(totalItems, paginationValue);

    useEffect(() => {
        if (!isFirstRender === true) runFunction(getItems);
    }, [paginationValue, activePage]);

    return (
        <div style={{ marginBottom: "3rem" }}>
            <div className="row">
                <div className="large-4 columns text-left">&nbsp;</div>

                {showPagination !== false && (
                    <Fragment>
                        <div className="large-4 columns text-center">
                            <div className="pagination">
                                <Pagination
                                    activePage={checkInteger(activePage)}
                                    itemsCountPerPage={paginationValue}
                                    totalItemsCount={totalItems}
                                    pageRangeDisplayed={
                                        pageRangeDisplayed === 0
                                            ? 1
                                            : pageRangeDisplayed
                                    }
                                    onChange={handlePageChange}
                                    linkClass={"page-link"}
                                />
                            </div>
                        </div>
                        <div className="large-4 columns text-right">
                            <div className="pagination__quantity">
                                <p className="inline">SHOW PER PAGE</p>
                                <select
                                    className="inline"
                                    value={paginationValue}
                                    onChange={updatePaginationValue}
                                >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                </select>
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
            <div className="row">
                {exportLink && (
                    <Fragment>
                        <div></div>
                        <div></div>
                        <div className="large-4 columns text-right">
                            <ExternalLink href={exportLink}>
                                Export to CSV
                            </ExternalLink>
                        </div>
                    </Fragment>
                )}
            </div>
        </div>
    );
};

export default TableFooter;
