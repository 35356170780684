import React, { Fragment, useState } from "react";
// import { Link } from "react-router-dom";
import img from "../../images/perks1.png";
import moment from "moment";
import PromotionPopup from "../../pages/PromotionPopup";

const Promotion = ({ campaign }) => {
    const imageUrl = campaign.dashboard_image ? campaign.dashboard_image : img;
    const date_from = campaign.date_from
        ? moment(campaign.date_from).format("DD/MM/YYYY")
        : "";
    const date_to = campaign.date_to
        ? moment(campaign.date_to).format("DD/MM/YYYY")
        : "";
    const dates = date_from && date_to ? `(${date_from} - ${date_to})` : "";
    const [popupVisible, setPopupVisible] = useState(false);
    const showPopup = () => setPopupVisible(true);
    const hidePopup = () => setPopupVisible(false);

    return (
        <Fragment>
            <PromotionPopup
                campaign={campaign}
                imgUrl={imageUrl}
                show={popupVisible}
                setVisible={setPopupVisible}
            />
            <div className="column promotion_details">
                <a onClick={showPopup}>
                    <img src={imageUrl} alt="" />
                </a>
                <p>
                    {`${campaign.title} ${dates}   `}
                    <span>
                        <a onClick={showPopup}>Preview</a>
                    </span>
                </p>
            </div>
        </Fragment>
    );
};

export default Promotion;

/* original modal code
<div className={"modal"}>
		<div
			className="reveal perks-reveal"
			id="{{$id}}"
			data-reveal
			data-animation-in="fade-in"
			data-animation-out="fade-out"
		>
			<div className="reveal-inner">
				<div className="row columns">
						<div className="title text-center">
							<h3>{campaign.banner_title}</h3>
							<p className="summary">{campaign.summary}</p>
						</div>
				</div>
				<div className="row">
						<div className="summary">
							<h6>{campaign.how_it_works}</h6>
						</div>

						<div className="terms">
							{campaign.terms_and_conditions}
						</div>
				</div>
			</div>
		</div>
</div>
*/
