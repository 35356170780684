import React, { useContext, useEffect, useState } from "react";
import TableLayout from "../../containers/TableLayout";
import Spinner from "../../containers/Spinner";
import { map } from "lodash";
import PotentialCustomerRow from "./PotentialCustomerRow";
import PotentialCustomerContext from "../../context/potentialcustomer/potentialCustomerContext";
import {
    checkInteger,
} from "../../utils/utils";
const PotentialCustomersTable = () => {
    const potentialCustomerContext = useContext(PotentialCustomerContext);
    const {
        potentialCustomers,
        getPotentialCustomers,
        getPotentialCustomerStatuses,
        potentialCustomerStatuses,
        setPotentialCustomersPage,
        potentialCustomersPage,
        potentialCustomerCount,
        loading
    } = potentialCustomerContext;

    useEffect(() => {
        getPotentialCustomerStatuses();
        getPotentialCustomers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

 
    return (
        <React.Fragment>
            <div className="row">
                <h3 className="title pad-t">Potential Customers</h3>
        
                <TableLayout
                    activePage={potentialCustomersPage}
                    setActivePage={setPotentialCustomersPage}
                    totalItems={checkInteger(potentialCustomerCount)}
                    getItems={getPotentialCustomers}
                    hideFilter={true}
                >
                    <table
                        id="potential-customers"
                        className="responsive"
                        width="100%"
                    >
                        <tbody>
                            <tr>
                                <th>Business Name</th>
                                <th>Account Number</th>
                                <th>Current Dealer</th>
                                <th>Post Code</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Contact Number</th>
                                <th>Status</th>
                                <th>Follow up</th>
                            </tr>

                            {!loading &&
                                map(potentialCustomers, (potentialCustomer, i) => (
                                    <PotentialCustomerRow
                                        key={i}
                                        potentialCustomer={potentialCustomer}
                                        potentialCustomerStatuses={
                                            potentialCustomerStatuses
                                        }
                                    />
                                ))}
                    
                        </tbody>
                    </table>
                    {loading ? <Spinner /> : null}
                </TableLayout>
            </div>
        </React.Fragment>
    );
};

export default PotentialCustomersTable;
