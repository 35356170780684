import React, { Fragment, useContext, useEffect } from "react";
import { map, isString } from "lodash";
import { useIsFirstRender } from "../../../utils/isFirstRender";
import TableLayout from "../../../containers/TableLayout";
import HeadOfficeLeadRow from "./HeadOfficeLeadRow";
import LeadContext from "../../../context/lead/leadContext";
import SearchContext from "../../../context/search/searchContext";
import UserContext from "../../../context/user/userContext";
import Spinner from "../../../containers/Spinner";
import GetOnFirstRender from "../../GetOnFirstRender";
import {
    checkInteger,
    getFilterByValue,
    checkFilter,
    replaceHyphens,
   checkString,
	prepareDateString
} from "../../../utils/utils";
import { getLeadFilterOptions } from "../../leadFilterOptions";

const HeadOfficeLeads = ({ match }) => {
    const {
        params: { filter, from, to }
    } = match;

    const {
        leadDealers,
        getLeadDealers,
        loading,
        setLeadDealersPage,
        leadDealersPage,
        leadDealerCount,
        setLeadFilterValue,
        setLeadFilterOrDefault,
        leadFilterValue
    } = useContext(LeadContext);

    const {
        groupType,
        user: { uuid }
    } = useContext(UserContext);

    const { startDateMySQL, endDateMySQL } = useContext(SearchContext);

    const apiUrl = checkString(process.env.REACT_APP_BASE_URL);

    const leadFilterOptions = getLeadFilterOptions(groupType);
    const filterObject = getFilterByValue(leadFilterOptions, leadFilterValue);

    const { dates } = filterObject;
    const getItemsUrl = () => {
        getLeadDealers({
           leadFilterValue: isString(filter) ? filter : leadFilterValue,
			  start: prepareDateString(from, startDateMySQL),
			  end: prepareDateString(to, endDateMySQL),
        });
    };

    const getItemsState = () => {
       getLeadDealers({
			 leadFilterValue: leadFilterValue,
			 start: startDateMySQL,
			 end: endDateMySQL,
		 });
    };

    return (
        <Fragment>
            <div className="row">
                <h3 className="title pad-t">Leads</h3>
                <GetOnFirstRender
                    to={to}
                    from={from}
                    filter={filter}
                    getItems={getItemsUrl}
                    setFilterValue={setLeadFilterValue}
                />
                <TableLayout
                    activePage={leadDealersPage}
                    setActivePage={setLeadDealersPage}
                    totalItems={checkInteger(leadDealerCount)}
                    getItems={getItemsState}
                    setFilterValue={setLeadFilterValue}
                    filterValue={leadFilterValue}
                    filterOptions={leadFilterOptions}
                    showDates={dates}
                    exportLink={`${apiUrl}/download/LeadSummary/${uuid}/${groupType}/${startDateMySQL}/${endDateMySQL}/${leadFilterValue}`}
                >
                    <table id="leads" className="responsive" width="100%">
                        <thead>
                            <tr>
                                <th>Parts Distributor</th>
                                <th>Parts Distributor Code</th>
                                <th>Total Leads</th>
                                <th>Total Followed Up</th>
                                <th>Total To Follow Up</th>
                                <th>Total Notifications Sent</th>
                                <th>Average Follow Up Time</th>
                                <th>Average Followed Up</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading &&
                                map(leadDealers, (leadDealer, i) => (
                                    <HeadOfficeLeadRow
                                        key={i}
                                        leadDealer={leadDealer}
                                        filterValue={leadFilterValue}
                                        filterObject={filterObject}
                                    />
                                ))}
                        </tbody>
                    </table>
                    {loading ? <Spinner /> : null}
                </TableLayout>
            </div>
        </Fragment>
    );
};

export default HeadOfficeLeads;
