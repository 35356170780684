import React, { useReducer, useContext } from "react";
import axios from "axios";
import { get, filter } from "lodash";
import PointContext from "./pointContext";
import PointReducer from "./PointReducer";
import {
    SET_LOADING,
    SET_LOADED,
    GET_TOTAL_POINTS_AWARDED,
    GET_POINTS_AWARDED,
    GET_TOTAL_POINTS_REDEEMED,
    GET_POINTS_REDEEMED,
    GET_TOTAL_POINTS_EXPIRED
} from "../types";
import SearchContext from "../search/searchContext";
import { checkString } from "../../utils/utils";
import { setLoading, setLoaded } from "../../utils/state";

const PointState = props => {
    const searchContext = useContext(SearchContext);
    const { startDateMySQL, endDateMySQL } = searchContext;

    const initialState = {
        pointsTotalAwarded: "",
        pointsAwarded: "",
        pointsTotalRedeemed: "",
        pointsRedeemed: "",
        pointsTotalActive: "",
        pointsTotalExpired: "",
        loading: false,
        itemsLoading: []
    };

    const [state, dispatch] = useReducer(PointReducer, initialState);

    const setLoadedPointState = setLoaded(state.itemsLoading, dispatch);
    const setLoadingPointState = setLoading(state.itemsLoading, dispatch);

    const getTotalPointsAwarded = async () => {
        setLoadingPointState(GET_TOTAL_POINTS_AWARDED);

        const res = await axios.get(`/api/v1/points/awarded`);

        dispatch({
            type: GET_TOTAL_POINTS_AWARDED,
            payload: get(res, "data", {})
        });

        setLoadedPointState(GET_TOTAL_POINTS_AWARDED);
    };
    const getPointsAwarded = async () => {
        setLoadingPointState(GET_POINTS_AWARDED);

        const res = await axios.get(
            `/api/v1/points/awarded/${startDateMySQL}/${endDateMySQL}`
        );
        dispatch({
            type: GET_POINTS_AWARDED,
            payload: get(res, "data", {})
        });

        setLoadedPointState(GET_POINTS_AWARDED);
    };
    const getTotalPointsRedeemed = async () => {
        setLoadingPointState(GET_TOTAL_POINTS_REDEEMED);

        const res = await axios.get(`/api/v1/points/redeemed`);

        dispatch({
            type: GET_TOTAL_POINTS_REDEEMED,
            payload: get(res, "data", {})
        });

        setLoadedPointState(GET_TOTAL_POINTS_REDEEMED);
    };
    const getPointsRedeemed = async () => {
        setLoadingPointState(GET_POINTS_REDEEMED);

        const res = await axios.get(
            `/api/v1/points/redeemed/${startDateMySQL}/${endDateMySQL}`
        );

        dispatch({
            type: GET_POINTS_REDEEMED,
            payload: get(res, "data", {})
        });

        setLoadedPointState(GET_POINTS_REDEEMED);
    };

    const getTotalPointsExpired = async () => {
        setLoadingPointState(GET_TOTAL_POINTS_EXPIRED);

        const res = await axios.get(`/api/v1/points/expired`);

        dispatch({
            type: GET_TOTAL_POINTS_EXPIRED,
            payload: get(res, "data", {})
        });

        setLoadedPointState(GET_TOTAL_POINTS_EXPIRED);
    };

    //const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <PointContext.Provider
            value={{
                pointsTotalAwarded: state.pointsTotalAwarded,
                pointsAwarded: state.pointsAwarded,
                pointsTotalRedeemed: state.pointsTotalRedeemed,
                pointsRedeemed: state.pointsRedeemed,
                pointsTotalExpired: state.pointsTotalExpired,
                pointsTotalActive:
                    Number(state.pointsTotalAwarded) &&
                    Number(state.pointsTotalRedeemed) &&
                    Number(state.pointsTotalExpired)
                        ? Number(state.pointsTotalAwarded) -
                          Number(state.pointsTotalRedeemed) -
                          Number(state.pointsTotalExpired)
                        : state.pointsTotalActive,
                loading: state.loading,
                setLoading,
                getTotalPointsAwarded,
                getPointsAwarded,
                getTotalPointsRedeemed,
                getPointsRedeemed,
                getTotalPointsExpired
            }}
        >
            {props.children}
        </PointContext.Provider>
    );
};

export default PointState;
