import React, { Fragment, useContext, useEffect, useState } from "react";
import TableLayout from "../../containers/TableLayout";
import { __RouterContext } from "react-router-dom";
import Spinner from "../../containers/Spinner";
import ReportRow from "./ReportRow";
import ReportContext from "../../context/report/reportContext";
import UserContext from "../../context/user/userContext";
import SearchContext from "../../context/search/searchContext";
import { checkArray } from "../../utils/utils";
import { useIsFirstRender } from "../../utils/isFirstRender";
import { map } from "lodash";
import TableHeader from "../../components/TableHeader";

const ReportsTable = () => {
    const userContext = useContext(UserContext);
    const { groupType, user } = userContext;


	 const { history } = useContext(__RouterContext)


	 //console.log('history:',history);

    const searchContext = useContext(SearchContext);
    const { startDateMySQL, endDateMySQL } = searchContext;
    const reportContext = useContext(ReportContext);
    const {
        reports,
        getReports,
        generateNewReport,
        reportDownloaded,
        downloadUrl,
        loading
    } = reportContext;
    const [activePage, setActivePage] = useState(1);
    const isFirstRender = useIsFirstRender();

    useEffect(() => {
        if (isFirstRender === true) getReports();

        if (downloadUrl) {
            window.location = downloadUrl;

            reportDownloaded();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePageChange = pageNumber => {
        // setActivePage(pageNumber);
        // getReportPage(activePage);
    };

    return (
        <Fragment>
            <div className="row">
                <h3 className="title pad-t">Reports</h3>
                <TableLayout
                    showDates={true}
                    getPage={handlePageChange}
                    activePage={activePage}
                    setActivePage={setActivePage}
                    changeDatesOnly={true}
		              showPagination={false}
		              useFilterOptions={false}
                >
                    <table id="reports" className="responsive" width="100%">
                        <tbody>
                            <tr>
                                <th>Report Name</th>
                                <th>Report Description</th>
                                <th>Download</th>
                            </tr>

                            {!loading &&
                                map(reports, (report, i) => (
                                    <ReportRow
                                        key={report.key}
                                        report={report}
                                        groupType={groupType}
                                        user={user}
                                        startDateMySQL={startDateMySQL}
                                        endDateMySQL={endDateMySQL}
                                    />
                                ))}
                        </tbody>
                    </table>
                    {loading ? <Spinner /> : null}
                </TableLayout>
            </div>
        </Fragment>
    );
};

export default ReportsTable;
