import React, { useEffect, useContext, Fragment } from "react";
import { isString } from "lodash";
import { useIsFirstRender } from "../utils/isFirstRender";
import SearchContext from "../context/search/searchContext";
import { replaceHyphens } from "../utils/utils";

const GetOnFirstRender = ({
    children,
    filter,
    getItems,
    from,
    to,
    setFilterValue
}) => {
    const { setStartDateIfValid, setEndDateIfValid } = useContext(
        SearchContext
    );

    const isFirstRender = useIsFirstRender();

    useEffect(() => {
        if (isFirstRender === true) {
            if (isString(filter) === true) setFilterValue(replaceHyphens(filter));
            if (isString(from) === true) setStartDateIfValid(from);
            if (isString(to) === true) setEndDateIfValid(to);

            getItems();
        }
    }, []);

    return <Fragment>{children}</Fragment>;
};

export default GetOnFirstRender;
