import React, { Fragment, useContext, useEffect, useState } from "react";
import TableLayout from "../../containers/TableLayout";
import LeadRow from "../LeadsTable/LeadRow";
import LeadContext from "../../context/lead/leadContext";
import DealerContext from "../../context/dealer/dealerContext";
import SearchContext from "../../context/search/searchContext";
import Spinner from "../../containers/Spinner";
import { map, isString } from "lodash";
import { checkArray, getFilterByValue } from "../../utils/utils";
import { useIsFirstRender } from "../../utils/isFirstRender";
import { checkInteger, parseInteger } from "../../utils/utils";
import { leadFilterOptions } from "../leadFilterOptions";
import ExternalLink from "../../components/ExternalLink";
import LeadPopUp from "../../components/DealerLeads/DealerLeadsPopup";
import GetOnFirstRender from "../GetOnFirstRender";

export default function DealerLeadsTable({ match }) {
    const {
        params: { filter, dealer_id: dealerId, from, to }
    } = match;

    const {
        leads,
        leadCount,
        getDealerLeads,
        loading,
        setDealerLeadsPage,
        dealerLeadsPage,
        setLeadFilterValue,
        setLeadFilterOrDefault,
        leadFilterValue
    } = useContext(LeadContext);

    const { dealer, getTTDealer, dealerLoading } = useContext(DealerContext);
    const [leadId, setLeadId] = useState(undefined);

    const [popupVisible, setPopupVisible] = useState(false);
    const showPopup = () => setPopupVisible(true);
    const hidePopup = () => setPopupVisible(false);

    const filterObject = getFilterByValue(leadFilterOptions, leadFilterValue);
    const { dates } = filterObject;

    const title = dealer && dealer.name ? `Leads > ${dealer.name}` : "Leads";

    const getItemsUrl = () => {
        getTTDealer(parseInteger(dealerId));
        getDealerLeads({
            leadFilterValue: isString(filter) ? filter : leadFilterValue,
            id: parseInteger(dealerId)
        });
    };

    const getItemsState = () => {
        getDealerLeads({
            leadFilterValue: leadFilterValue,
            id: parseInteger(dealerId)
        });
    };

    return (
        <Fragment>
            <LeadPopUp
                show={popupVisible}
                setVisible={setPopupVisible}
                leadId={leadId}
            />
            <div className="row">
                <h3 className="title pad-t">{title}</h3>
                <GetOnFirstRender
                    setFilterValue={setLeadFilterValue}
                    from={from}
                    to={to}
                    filter={filter}
                    getItems={getItemsUrl}
                />
                <TableLayout
                    activePage={dealerLeadsPage}
                    setActivePage={setDealerLeadsPage}
                    totalItems={checkInteger(leadCount)}
                    getItems={getItemsState}
                    setFilterValue={setLeadFilterValue}
                    filterValue={leadFilterValue}
                    filterOptions={leadFilterOptions}
                    showDates={dates}
                >
                    <table
                        id="dealer-leads"
                        className="responsive"
                        width="100%"
                    >
                        <thead>
                            <tr>
                                <th>Source</th>
                                <th>Customer</th>
                                <th>Description</th>
                                <th>Make</th>
                                <th>Model</th>
                                <th>Registration</th>
                                <th>Lead Creation</th>
                                <th>Customer Appointment Date</th>
                                <th>SMS Notification</th>
                                <th>Followed Up?</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading &&
                                checkArray(leads) &&
                                map(leads, (lead, i) => (
                                    <LeadRow
                                        key={i}
                                        lead={lead}
                                        showPopup={showPopup}
                                        setLeadId={setLeadId}
                                    />
                                ))}
                        </tbody>
                    </table>
                    {loading ? <Spinner /> : null}
                </TableLayout>
            </div>
        </Fragment>
    );
}
