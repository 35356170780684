import React, { Fragment } from "react";
import Promotion from "./Promotion";
import BlurredPromotion from "./BlurredPromotion";
import { isArray } from "lodash";

const Promotions = ({ marketingCampaigns }) => {
    const blurredCampaignsCount = 3 - marketingCampaigns.length;
    const blurredCampaignsArray = [];
    for (var i = 1; i <= blurredCampaignsCount; i++) {
        blurredCampaignsArray[i] = { id: i };
    }

    return (
        <Fragment>
            <h3 className="title promotions">
                Current DISTRIGO PERKS Competitions and Promotions
            </h3>
            <div className="promotions_banner">
                {marketingCampaigns &&
                    isArray(marketingCampaigns) &&
                    marketingCampaigns.map((campaign) => {
                        return (
                            <Promotion key={campaign.id} campaign={campaign} />
                        );
                    })}

                {blurredCampaignsArray &&
                    isArray(blurredCampaignsArray) &&
                    blurredCampaignsArray.map((campaign) => {
                        return (
                            <BlurredPromotion
                                key={campaign.id}
                                campaign={campaign}
                            />
                        );
                    })}
            </div>
        </Fragment>
    );
};
export default Promotions;
