import React from "react";
import styled from "styled-components";
import Popup from "../../components/Popup";
import Lead from "../../components/Lead/Lead";

const Test = ({ CloseButton }) => {
    return <CloseButton />;
};

const LeadPopupWrap_ = ({ className, CloseButton, children }) => {
    return (
        <div className={className}>
            <div
                style={{
                    top: "0.7rem",
                    right: "0.7rem",
                    position: "absolute",
                    zIndex: "100"
                }}
            >
                <CloseButton />
            </div>
            {children}
        </div>
    );
};

const LeadPopupWrap = styled(LeadPopupWrap_)`
    max-width: 930px;
    margin: auto;
    margin-top: 40px;
    position: relative;
`;

const DealerLeadsPopup = ({ show, setVisible, leadId }) => {
    return (
        <Popup show={show} setVisible={setVisible}>
            <LeadPopupWrap>
                <Lead leadId={leadId} />
            </LeadPopupWrap>
        </Popup>
    );
};

export default DealerLeadsPopup;
