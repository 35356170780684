import React, { Fragment, useContext, useEffect, useState } from "react";
import { map, isString } from "lodash";
import TableLayout from "../../containers/TableLayout";
import PartsRow from "./PartsRow";
import Spinner from "../../containers/Spinner";
import { checkInteger, checkString, getFilterByValue } from "../../utils/utils";

export default function PartsTable({ parts }) {
    const customTh = {
        fontSize: "14px",
        cursor: "pointer",
        transition: ".3s background",
        "&:hover": {
            background: "#40a9ff",
        },
    };
    return (
        <Fragment>
            <div className="large-6 columns">
                <h3 className="title ">Parts</h3>
                <table id="parts-table" className="responsive" width="100%">
                    <thead className={"lead__part"}>
                        <tr>
                            <th>Part Number</th>
                            <th>Description</th>
                            <th>Suggested Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {map(parts, (part, i) => (
                            <PartsRow key={i} part={part} />
                        ))}
                    </tbody>
                </table>
            </div>
        </Fragment>
    );
}
