import {
    SET_LOADING,
    GET_REPORTS,
} from "../types";

export default (state, action) => {
    switch (action.type) {
        case GET_REPORTS:
            return {
                ...state,
                reports: action.payload.data,
                loading: false
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
};
