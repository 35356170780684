import storage from "local-storage-fallback";
import query from "query-string";

import axios from "axios";
// import "nprogress/nprogress.css";
// import NProgress from "nprogress";

export const getItem = (key, defaultValue = null) => {
    //Get from local localStorage if available
    if (storage.getItem(key)) {
        return { [key]: storage.getItem(key) };
    }
    return { [key]: defaultValue };
};

export const setItem = (key, value) => {
    const data =
        value !== null && typeof value === "object"
            ? JSON.stringify(value)
            : value;

    // console.log("Setting item: " + key, value, data);
    storage.setItem(key, data);
};

export const clearStorage = () => {
    storage.clear();
};

export const clearStorageExcept = (keysToKeep = []) => {
    let savedKeys = [];
    keysToKeep.map((key) => {
        savedKeys.push(getItem(key));
    });

    clearStorage();

    savedKeys.forEach((savedKey) => {
        // console.log(savedKey);
        Object.entries(savedKey).forEach(([key, value]) => {
            setItem(key, value);
        });
    });
};

// Set default base url.
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
const params = query.parse(window.location.search);
let token = { access_token: null };

if (params.token) {
    setItem("access_token", params.token);
    token = { access_token: params.token };
} else {
    token = getItem("access_token");
}

// console.log(token);
axios.defaults.headers.common["Authorization"] = `Bearer ${token.access_token}`;

// Count all (non-asynchronous) requests which were made...
//let requestsCounter = 0;

// Add a request interceptor
axios.interceptors.request.use((request) => {
    // const async = get(request, "data.async", false);

    // if (!async) {
    //     if (requestsCounter++ <= 0) {
    //         NProgress.start();
    //     }
    // }

    return request;
});

// Add a response interceptor
axios.interceptors.response.use(
    (response) => {
        // if (requestsCounter > 0 && --requestsCounter === 0) {
        //     NProgress.done();
        // }

        return response;
    },
    (error) => {
        // if (--requestsCounter === 0) {
        //     NProgress.done();
        // }
        // return;
        if (error.response && error.response.status === 401) {
            window.location.href = "https://distrigoparts.co.uk/auth/login";
        }

        throw error;
    }
);
