import React, { useContext } from "react";

export default function PartsRow({ part }) {
    return (
        <tr key={part.part_number}>
            <td className="PD" style={{textAlign: 'left'}}>{part.part_number}</td>
            <td className="PD" style={{textAlign: 'left'}}>{part.description}</td>
            <td className="PD" style={{textAlign: 'right'}}> £{(part.amount ? Number(part.amount) : 0).toFixed(2)}</td>
        </tr>
    );
}
