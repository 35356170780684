import { checkInteger } from "../../utils/utils";
import {
    SET_LOADING,
    GET_POTENTIAL_CUSTOMER,
    GET_POTENTIAL_CUSTOMER_DEALERS,
    GET_DEALER_POTENTIAL_CUSTOMERS,
    GET_POTENTIAL_CUSTOMERS,
    GET_POTENTIAL_CUSTOMER_COUNTS,
    SET_POTENTIAL_CUSTOMERS_PAGE,
    GET_POTENTIAL_CUSTOMER_STATUSES
} from "../types";

export default (state, action) => {
    switch (action.type) {
        case SET_POTENTIAL_CUSTOMERS_PAGE:
            return {
                ...state,
                potentialCustomersPage: checkInteger(action.payload)
            };

        case GET_POTENTIAL_CUSTOMER_COUNTS:
            return {
                ...state,
                potentialCustomerCounts: action.payload.data,
                loading: false
            };
        case GET_DEALER_POTENTIAL_CUSTOMERS:
            return {
                ...state,
                potentialCustomers: action.payload.data,
                potentialCustomerCount: action.payload.meta.total,
                potentialCustomerLinks: action.payload.links,
                loading: false
            };
        case GET_POTENTIAL_CUSTOMERS:
            return {
                ...state,
                potentialCustomers: action.payload.data,
                potentialCustomerCount: action.payload.meta.total,
                potentialCustomerLinks: action.payload.links,
                loading: false
            };
        case GET_POTENTIAL_CUSTOMER_DEALERS:
            return {
                ...state,
                potentialCustomerDealers: action.payload.data,
                potentialCustomerDealerCount: action.payload.meta.total,
                potentialCustomerDealerLinks: action.payload.links,
                loading: false
            };
        case GET_POTENTIAL_CUSTOMER:
            return {
                ...state,
                potentialCustomer: action.payload,
                loading: false
            };
        case GET_POTENTIAL_CUSTOMER_STATUSES:
            return {
                ...state,
                potentialCustomerStatusesToSetByPD: action.payload.potentialCustomerStatusesToSetByPD,
                potentialCustomerStatuses: action.payload.potentialCustomerStatuses,
                loading: false
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
};
