export const SET_LOADING = "SET_LOADING";
export const SET_LOADED = "SET_LOADED";
export const SET_CATEGORY_OPTIONS_LOADING = "SET_CATEGORY_OPTIONS_LOADING";
export const GET_TOKEN = "GET_TOKEN";
export const GET_USER = "GET_USER";
export const GET_DC_DEALER = "GET_DC_DEALER";
export const GET_TT_DEALER = "GET_TT_DEALER";

export const GET_COMMUNICATION_COUNTS = "GET_COMMUNICATION_COUNTS";
export const GET_COMMUNICATION_DEALERS = "GET_COMMUNICATION_DEALERS";
export const GET_DEALER_COMMUNICATIONS = "GET_DEALER_COMMUNICATIONS";
export const GET_DEALER_COMMUNICATION_RECIPIENTS =
    "GET_DEALER_COMMUNICATION_RECIPIENTS";
export const SET_DEALER_COMMUNICATIONS_PAGE = "SET_DEALER_COMMUNICATIONS_PAGE";

export const GET_COMMUNICATIONS = "GET_COMMUNICATIONS";
export const GET_COMMUNICATION_RECIPIENTS = "GET_COMMUNICATION_RECIPIENTS";
export const GET_COMMUNICATION_RECIPIENT = "GET_COMMUNICATION_RECIPIENT";
export const COMMUNICATION_STATUS_UPDATED = "COMMUNICATION_STATUS_UPDATED";
export const SET_DEALER_COMMUNICATION_RECIPIENTS_PAGE =
    "SET_DEALER_COMMUNICATION_RECIPIENTS_PAGE";
export const SET_COMMUNICATION_FILTER_VALUE = "SET_COMMUNICATION_FILTER_VALUE";
export const SET_COMMUNICATION_DEALERS_PAGE = "SET_COMMUNICATION_DEALERS_PAGE";

export const GET_TOTAL_CUSTOMERS = "GET_TOTAL_CUSTOMERS";
export const GET_CUSTOMER_COUNTS = "GET_CUSTOMER_COUNTS";
export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";
export const GET_ACTIVATED_CUSTOMERS = "GET_ACTIVATED_CUSTOMERS";
export const GET_NOT_ACTIVATED_CUSTOMERS = "GET_NOT_ACTIVATED_CUSTOMERS";
export const GET_CUSTOMER_PAGE = "GET_CUSTOMER_PAGE";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_CUSTOMER_DEALERS = "GET_CUSTOMER_DEALERS";
export const GET_DEALER_CUSTOMERS = "GET_DEALER_CUSTOMERS";

export const GET_POTENTIAL_CUSTOMER = "GET_POTENTIAL_CUSTOMER";
export const GET_POTENTIAL_CUSTOMER_DEALERS = "GET_POTENTIAL_CUSTOMER_DEALERS";
export const SET_POTENTIAL_CUSTOMERS_PAGE = "SET_POTENTIAL_CUSTOMERS_PAGE";
export const GET_DEALER_POTENTIAL_CUSTOMERS = "GET_DEALER_POTENTIAL_CUSTOMERS";
export const GET_POTENTIAL_CUSTOMERS = "GET_POTENTIAL_CUSTOMERS";
export const GET_POTENTIAL_CUSTOMER_COUNTS = "GET_POTENTIAL_CUSTOMER_COUNTS";
export const GET_POTENTIAL_CUSTOMER_STATUSES = "GET_POTENTIAL_CUSTOMER_STATUSES";

export const GET_LEADS = "GET_LEADS";
export const GET_LEAD_COUNTS = "GET_LEAD_COUNTS";
export const GET_LEAD_PAGE = "GET_LEAD_PAGE";
export const GET_LEAD = "GET_LEAD";
export const GET_LEAD_DEALERS = "GET_LEAD_DEALERS";
export const GET_DEALER_LEADS = "GET_DEALER_LEADS";
export const LEAD_STATUS_UPDATED = "LEAD_STATUS_UPDATED";
export const SET_ACTIVE_PAGE_VALUE = "SET_ACTIVE_PAGE_VALUE";
export const SET_DEALER_LEADS_PAGE = "SET_DEALER_LEADS_PAGE";
export const SET_LEAD_DEALERS_PAGE = "SET_LEAD_DEALERS_PAGE";

export const GET_TOTAL_POINTS_AWARDED = "GET_TOTAL_POINTS_AWARDED";
export const GET_POINTS_AWARDED = "GET_POINTS_AWARDED";
export const GET_TOTAL_POINTS_REDEEMED = "GET_TOTAL_POINTS_REDEEMED";
export const GET_POINTS_REDEEMED = "GET_POINTS_REDEEMED";
export const GET_TOTAL_POINTS_ACTIVE = "GET_TOTAL_POINTS_ACTIVE";
export const GET_TOTAL_POINTS_EXPIRED = "GET_TOTAL_POINTS_EXPIRED";

export const SET_START_DATE = "SET_START_DATE";
export const SET_END_DATE = "SET_END_DATE";
export const TOGGLE_FORM_SUBMIT = "TOGGLE_FORM_SUBMIT";
export const SET_LEAD_FILTER_VALUE = "SET_LEAD_FILTER_VALUE";
export const SET_LEAD_PAGE = "SET_LEAD_PAGE";

export const SET_CUSTOMER_FILTER_VALUE = "SET_CUSTOMER_FILTER_VALUE";
export const SET_CUSTOMER_DEALERS_PAGE = "SET_CUSTOMER_DEALERS_PAGE";
export const SET_DEALER_CUSTOMERS_PAGE = "SET_DEALER_CUSTOMERS_PAGE";
export const SET_CUSTOMER_PAGE = "SET_CUSTOMER_PAGE";

export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const SET_PAGINATION_VALUE = "SET_PAGINATION_VALUE";

export const GET_CATEGORY_OPTIONS = "GET_CATEGORY_OPTIONS";

export const GET_MARKETING_CAMPAIGNS_CUSTOMER =
    "GET_MARKETING_CAMPAIGNS_CUSTOMER";
export const GET_MARKETING_CAMPAIGNS_DEALER = "GET_MARKETING_CAMPAIGNS_DEALER";

export const GET_COMMUNICATIONS_CUSTOMER = "GET_COMMUNICATIONS_CUSTOMER";

export const GET_REWARDS = "GET_REWARDS";
export const GET_REWARD_COUNTS = "GET_REWARD_COUNTS";
export const GET_REWARD_PAGE = "GET_REWARD_PAGE";
export const GET_REWARD = "GET_REWARD";
export const GET_REWARD_DEALERS = "GET_REWARD_DEALERS";
export const GET_DEALER_REWARDS = "GET_DEALER_REWARDS";
export const REWARD_STATUS_UPDATED = "REWARD_STATUS_UPDATED";
export const SET_DEALER_REWARDS_PAGE = "SET_DEALER_REWARDS_PAGE";
export const SET_REWARD_DEALERS_PAGE = "SET_REWARD_DEALERS_PAGE";
export const SET_REWARD_FILTER_VALUE = "SET_REWARD_FILTER_VALUE";
export const SET_REWARD_PAGE = "SET_REWARD_PAGE";

export const GET_REPORTS = "GET_REPORTS";
