import React from "react";
import styled from "styled-components";
import Popup from "../../components/Popup";
import CommunicationRecipient from "../../components/CommunicationRecipient/CommunicationRecipient";

const CommsPopupWrap_ = ({ className, CloseButton, children }) => {
    return (
        <div className={className}>
            <div
                style={{
                    top: "0.7rem",
                    right: "0.7rem",
                    position: "absolute",
                    zIndex: "100"
                }}
            >
                <CloseButton color={"#333333"} />
            </div>
            {children}
        </div>
    );
};

const CommsPopupWrap = styled(CommsPopupWrap_)`
    max-width: 750px;
    margin: auto;
    position: relative;
    margin-top: 20px;
`;

const CommunicationRecipientsPopup = ({
    show,
    setVisible,
    recipient,
    communicationId,
    dealerId,
    acousticEmailHTML
}) => {
    return (
        <Popup show={show} setVisible={setVisible}>
            <CommsPopupWrap>
                <CommunicationRecipient
                    acousticEmailHTML={acousticEmailHTML}
                    recipient={recipient}
                    communicationId={communicationId}
                    dealerId={dealerId}
                    setVisible={setVisible}
                />
            </CommsPopupWrap>
        </Popup>
    );
};

export default CommunicationRecipientsPopup;
