import {
    SET_LOADING,
    GET_TOTAL_POINTS_AWARDED,
    GET_POINTS_AWARDED,
    GET_TOTAL_POINTS_REDEEMED,
    GET_POINTS_REDEEMED,
    GET_TOTAL_POINTS_ACTIVE,
   GET_TOTAL_POINTS_EXPIRED,
	SET_LOADED
} from "../types";
import { get } from "lodash";
import { getArray } from "../../utils/utils"

export default (state, action) => {
    switch (action.type) {
        case GET_TOTAL_POINTS_AWARDED:
            return {
                ...state,
                pointsTotalAwarded: get(action, "payload.data.points", "-"),
                // loading: false
            };
        case GET_POINTS_AWARDED:
            return {
                ...state,
                pointsAwarded: get(action, "payload.data.points", "-"),
                // loading: false
            };
        case GET_TOTAL_POINTS_REDEEMED:
            return {
                ...state,
                pointsTotalRedeemed: get(action, "payload.data.points", "-"),
                // loading: false
            };
        case GET_POINTS_REDEEMED:
            return {
                ...state,
                pointsRedeemed: get(action, "payload.data.points", "-"),
                // loading: false
            };
        case GET_TOTAL_POINTS_ACTIVE:
            return {
                ...state,
                pointsTotalActive: get(action, "payload.data.points", "-"),
                // loading: false
            };
        case GET_TOTAL_POINTS_EXPIRED:
            return {
                ...state,
                pointsTotalExpired: get(action, "payload.data.points", "-"),
                // loading: false
            };
        case SET_LOADING:
            return {
                ...state,
                itemsLoading: getArray(action, "payload"),
                loading: true
            };

        case SET_LOADED:
            return {
                ...state,
                itemsLoading: getArray(action, "payload.itemsLoading"),
                loading: get(action, "payload.loading")
            };

        default:
            return state;
    }
};
