import React, { Fragment, useContext } from "react";
import { map, isString } from "lodash";
import TableLayout from "../../../containers/TableLayout";
import HeadOfficeRewardRow from "./HeadOfficeRewardRow";

import RewardContext from "../../../context/reward/rewardContext";

import SearchContext from "../../../context/search/searchContext";
import UserContext from "../../../context/user/userContext";
import Spinner from "../../../containers/Spinner";
import GetOnFirstRender from "../../GetOnFirstRender";
import {
    checkInteger,
    getFilterByValue,
    checkString,
} from "../../../utils/utils";
import { getRewardFilterOptions } from "../../rewardFilterOptions";

const HeadOfficeRewards = ({ match }) => {
    const {
        params: { filter, from, to },
    } = match;

    const {
        rewardDealers,
        getRewardDealers,
        loading,
        setRewardDealersPage,
        rewardDealersPage,
        rewardDealerCount,
        setRewardFilterValue,
        setRewardFilterOrDefault,
        rewardFilterValue,
    } = useContext(RewardContext);

    const {
        groupType,
        user: { uuid },
    } = useContext(UserContext);

    const { startDateMySQL, endDateMySQL } = useContext(SearchContext);

    const apiUrl = checkString(process.env.REACT_APP_BASE_URL);

    const rewardFilterOptions = getRewardFilterOptions(groupType);
    const filterObject = getFilterByValue(
        rewardFilterOptions,
        rewardFilterValue
    );

    const { dates } = filterObject;
    const getItemsUrl = () => {
        getRewardDealers({
            rewardFilterValue: isString(filter) ? filter : rewardFilterValue,
        });
    };

    const getItemsState = () => {
        getRewardDealers({ rewardFilterValue: rewardFilterValue });
    };

    return (
        <Fragment>
            <div className="row">
                <h3 className="title pad-t">Rewards</h3>
                <GetOnFirstRender
                    to={to}
                    from={from}
                    filter={filter}
                    getItems={getItemsUrl}
                    setFilterValue={setRewardFilterValue}
                />
                <TableLayout
                    activePage={rewardDealersPage}
                    setActivePage={setRewardDealersPage}
                    totalItems={checkInteger(rewardDealerCount)}
                    getItems={getItemsState}
                    setFilterValue={setRewardFilterValue}
                    filterValue={rewardFilterValue}
                    filterOptions={rewardFilterOptions}
                    showDates={dates}
                    exportLink={`${apiUrl}/download/RewardSummary/${uuid}/${groupType}/${startDateMySQL}/${endDateMySQL}/${rewardFilterValue}`}
                >
                    <table id="rewards" className="responsive" width="100%">
                        <thead>
                            <tr style={{ background: "black", color: "white" }}>
                                <th>Parts Distributor</th>
                                <th>Parts Distributor Code</th>
                                <th>Number of Amazon Redemptions</th>
                                <th>Number of Credit Redemptions</th>
                                <th>Number of Snap-on Redemptions</th>
                                <th>Total Number of Redemptions</th>
                                <th>Total Points</th>
                                <th>View</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading &&
                                map(rewardDealers, (rewardDealer, i) => (
                                    <HeadOfficeRewardRow
                                        key={i}
                                        rewardDealer={rewardDealer}
                                        filterValue={rewardFilterValue}
                                        filterObject={filterObject}
                                    />
                                ))}
                        </tbody>
                    </table>
                    {loading ? <Spinner /> : null}
                </TableLayout>
            </div>
        </Fragment>
    );
};

export default HeadOfficeRewards;
