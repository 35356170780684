import React from "react";
import { Link } from "react-router-dom";
// import greenTick from "../../images/green_tick.png";
import moment from "moment";

export default function LeadRow({ lead, showPopup, setLeadId }) {
	const showLead = () => {
		setLeadId(lead.id)
		showPopup()
	}

    return (
        <tr key={lead.id}>
            <td className="PD">{lead.first_name}</td>
            <td className="PD">{lead.centre_name}</td>
            <td className="PD">{lead.job_description}</td>
            <td className="PD">{lead.make}</td>
            <td className="PD">{lead.model}</td>
            <td className="PD">{lead.vehicle_registration}</td>
            <td className="PD">
                {lead.created_at
                    ? moment(lead.created_at).format("DD/MM/YYYY HH:mm")
                    : "-"}
            </td>
            <td className="PD">
                {lead.appointment_at
                    ? moment(lead.appointment_at).format("DD/MM/YYYY HH:mm")
                    : "-"}
            </td>
            <td className="PD">
                {lead.sms_sent_at
                    ? `Sent ` + moment(lead.sms_sent_at).format("DD/MM/YYYY")
                    : "-"}
            </td>
            <td className="PD">
                {lead.display_sale_status ? lead.display_sale_status : "-"}
            </td>
            <td className="leads__buttons PD">
                    <button onClick={showLead} className="button button--black">View </button>
            </td>
        </tr>
    );
}
                    // <button onClick={showLead} className="button button--black">View </button>

                // <Link to={`/lead/${lead.id}`}>
