import React from "react";
import { Link } from "react-router-dom";


export default function NavLink({ to, name, className, highlight }) {
	 const highlighted = highlight ? {color: '#e84e0f'} : {}

    return (
			 <li className={className}>
            <Link to={to} style={highlighted}>{name}</Link>
        </li>
    );
}
