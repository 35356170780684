import React, { useReducer, useContext } from "react";
import axios from "axios";
import PotentialCustomerContext from "./potentialCustomerContext";
import PotentialCustomerReducer from "./PotentialCustomerReducer";
import {
    SET_LOADING,
    GET_POTENTIAL_CUSTOMER,
    GET_POTENTIAL_CUSTOMER_DEALERS,
    GET_POTENTIAL_CUSTOMERS,
    GET_DEALER_POTENTIAL_CUSTOMERS,
    GET_POTENTIAL_CUSTOMER_COUNTS,
    SET_POTENTIAL_CUSTOMERS_PAGE,
    GET_POTENTIAL_CUSTOMER_STATUSES
} from "../types";
import SearchContext from "../search/searchContext";

const PotentialCustomerState = props => {
    const searchContext = useContext(SearchContext);
    const { startDateMySQL, endDateMySQL, paginationValue } = searchContext;

    const initialState = {
        potentialCustomer: {},
        potentialCustomers: [],
        potentialCustomerDealers: [],
        potentialCustomerCounts: [],
        potentialCustomerCount: 0,
        potentialCustomerDealerCount: 0,
        potentialCustomerTotal: 0,
        potentialCustomersPage: 1,
        potentialCustomerStatusesToSetByPD: [],
        potentialCustomerStatuses: [],
        loading: false
    };

    const [state, dispatch] = useReducer(
        PotentialCustomerReducer,
        initialState
    );

    /**
     * @param {integer} pageNumber
     * @return {undefined}
     */
    const setPotentialCustomersPage = pageNumber => {
        dispatch({
            type: SET_POTENTIAL_CUSTOMERS_PAGE,
            payload: pageNumber
        });
    };

    const getPotentialCustomerCounts = async () => {
        setLoading();
        const res = await axios.get(
            `/api/v1/potential-customer/counts/${startDateMySQL}/${endDateMySQL}`
        );
        dispatch({
            type: GET_POTENTIAL_CUSTOMER_COUNTS,
            payload: res.data
        });
    };

    const getPotentialCustomerDealers = async () => {
        setLoading();
        const res = await axios.get(
            `/api/v1/potential-customer/dealers/${startDateMySQL}/${endDateMySQL}?pagination=${paginationValue}&page=${state.potentialCustomersPage}`
        );
        dispatch({
            type: GET_POTENTIAL_CUSTOMER_DEALERS,
            payload: res.data
        });
    };
    const getPotentialCustomers = async () => {
        setLoading();
        const res = await axios.get(
            `/api/v1/potential-customers/${startDateMySQL}/${endDateMySQL}?pagination=${paginationValue}&page=${state.potentialCustomersPage}`
        );
        dispatch({
            type: GET_POTENTIAL_CUSTOMERS,
            payload: res.data
        });
    };
    const getDealerPotentialCustomers = async id => {
        setLoading();
        const res = await axios.get(
            `/api/v1/potential-customer/dealer/${id}/${startDateMySQL}/${endDateMySQL}`
        );
        dispatch({
            type: GET_DEALER_POTENTIAL_CUSTOMERS,
            payload: res.data
        });
    };

    const getPotentialCustomer = async id => {
        setLoading();
        const res = await axios.get(`/api/v1/potential-customer/${id}`);

        dispatch({
            type: GET_POTENTIAL_CUSTOMER,
            payload: res.data.data
        });
    };

    const getPotentialCustomerStatuses = async () => {
        setLoading();
        const res = await axios.get(`/api/v1/potential-customer-statuses`);
        console.log(res.data.data);
        dispatch({
            type: GET_POTENTIAL_CUSTOMER_STATUSES,
            payload: res.data.data
        });
    };

    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <PotentialCustomerContext.Provider
            value={{
                potentialCustomers: state.potentialCustomers,
                potentialCustomer: state.potentialCustomer,
                potentialCustomerDealers: state.potentialCustomerDealers,
                potentialCustomerCounts: state.potentialCustomerCounts,
                potentialCustomerCount: state.potentialCustomerCount,
                potentialCustomerDealerCount: state.potentialCustomerDealerCount,
                potentialCustomersPage: state.potentialCustomersPage,
                potentialCustomerStatusesToSetByPD: state.potentialCustomerStatusesToSetByPD,
                potentialCustomerStatuses: state.potentialCustomerStatuses,
                loading: state.loading,
                setLoading,
                setPotentialCustomersPage,
                getPotentialCustomerDealers,
                getDealerPotentialCustomers,
                getPotentialCustomers,
                getPotentialCustomerCounts,
                getPotentialCustomer,
                getPotentialCustomerStatuses
            }}
        >
            {props.children}
        </PotentialCustomerContext.Provider>
    );
};

export default PotentialCustomerState;
