import React, { Fragment, useContext, useEffect } from "react";
import TableLayout from "../../../containers/TableLayout";
import Spinner from "../../../containers/Spinner";
import HeadOfficePotentialCustomerRow from "./HeadOfficePotentialCustomerRow";
import PotentialCustomerContext from "../../../context/potentialcustomer/potentialCustomerContext";
import { checkInteger } from "../../../utils/utils";

const HeadOfficePotentialCustomers = () => {
    const potentialCustomerContext = useContext(PotentialCustomerContext);
    const {
        potentialCustomerDealers,
        potentialCustomerDealerCount,
        getPotentialCustomerDealers,
        loading,
        setPotentialCustomersPage,
        potentialCustomersPage
    } = potentialCustomerContext;

    useEffect(() => {
        getPotentialCustomerDealers();
    }, []);

    return (
        <Fragment>
            <div className="row">
                <h3 className="title pad-t">Potential Customer Dealers</h3>
                <TableLayout
                    activePage={potentialCustomersPage}
                    setActivePage={setPotentialCustomersPage}
                    getItems={getPotentialCustomerDealers}
                    totalItems={checkInteger(potentialCustomerDealerCount)}
		              showDates={false}
                >
                    <table id="potential" className="responsive" width="100%">
                        <tbody>
                            <tr>
                                <th valign="bottom">Parts Distributor</th>
                                <th valign="bottom">Parts Distributor Code</th>
                                <th valign="bottom">Potential Customers</th>
                                <th valign="bottom">Action</th>
                            </tr>

                            {potentialCustomerDealers.map(
                                (potentialCustomerDealer, i) => (
                                    <HeadOfficePotentialCustomerRow
                                        key={potentialCustomerDealer.id}
                                        potentialCustomerDealer={
                                            potentialCustomerDealer
                                        }
                                    />
                                )
                            )}
                        </tbody>
                    </table>
                    {loading === true ? <Spinner /> : null}
                </TableLayout>
            </div>
        </Fragment>
    );
};

export default HeadOfficePotentialCustomers;
