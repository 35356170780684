import React from "react";
import { Link } from "react-router-dom";

export default function PotentialCustomerRow({
    potentialCustomer,
    potentialCustomerStatuses
}) {
    return (
        <tr key={potentialCustomer.id}>
            <td className="PD">{potentialCustomer.business_name}</td>
            <td className="PD">{potentialCustomer.account_number}</td>
            <td className="PD">{potentialCustomer.display_current_dealer}</td>
            <td className="PD">{potentialCustomer.post_code}</td>
            <td className="PD">{potentialCustomer.first_name}</td>
            <td className="PD">{potentialCustomer.last_name}</td>
            <td className="PD">{potentialCustomer.email}</td>
            <td className="PD">{potentialCustomer.mobile}</td>
            <td className="PD">
                {potentialCustomerStatuses[`S_${potentialCustomer.status}`]
                    ? potentialCustomerStatuses[`S_${potentialCustomer.status}`]
                    : potentialCustomer.status}
            </td>

            <td className="PD" className="leads__buttons">
                <Link to={`/potential-customer/${potentialCustomer.id}`}>
                    <button className="button button--black view">View</button>
                </Link>
            </td>
        </tr>
    );
}
