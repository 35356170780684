import React from "react";
import {
    faSort,
    faSortUp,
    faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SortButton = ({
    children,
    sortDirection,
    setSortDirection,
    setSortField,
    sortField,
    field,
}) => {
    const buttonIsSelected = sortField === field;
    const handleClick = () => {
        if (buttonIsSelected) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
            setSortField(field);
        } else {
            setSortDirection("asc");
            setSortField(field);
        }
    };

    return (
        <button
            style={{
                color: "inherit",
                display: "inline-flex",
                flexDirection: "row",
                fontWeight: "inherit",
            }}
            className={`sort-button`}
            onClick={handleClick}
        >
            {children}{" "}
            <FontAwesomeIcon
                style={{
                    opacity: buttonIsSelected ? 1 : 0.5,
                    marginLeft: "0.5rem",
                }}
                icon={
                    buttonIsSelected
                        ? sortDirection === "asc"
                            ? faSortUp
                            : faSortDown
                        : faSort
                }
            />
        </button>
    );
};

export default SortButton;
