import React from 'react'
import styled from 'styled-components'


const ExternalLink = styled.a`
   color: #e84e0f;

   :focused, hover {
      color: #1468a0;
   }
`


export default ExternalLink
