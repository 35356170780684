import React, { useReducer, useEffect } from "react";
import axios from "axios";
import { get } from "lodash";
import UserContext from "./userContext";
import UserReducer from "./UserReducer";
import { SET_LOADING, GET_TOKEN, GET_USER } from "../types";

const UserState = (props) => {
    const initialState = {
        loading: false,
        passport: {},
        groupType: JSON.parse(window.localStorage.getItem("groupType")) || "",
        user: JSON.parse(window.localStorage.getItem("user")) || null,
        group: JSON.parse(window.localStorage.getItem("group")) || null,
    };

    const [state, dispatch] = useReducer(UserReducer, initialState);

    const getToken = async (email, password) => {
        setLoading();
        const res = await axios.post("/oauth/token", {
            grant_type: "password",
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            password: password,
            username: email,
        });
        dispatch({
            type: GET_TOKEN,
            payload: get(res, "data", {}),
        });
    };

    const getUser = async () => {
        setLoading();
        //Try and get the user, if it fails, redirect to login on the distrigo site
        try {
            const res = await axios.get("/api/v1/user");

            if (!res) {
                window.location.href = "https://distrigoparts.co.uk/auth/login";
            }
            dispatch({
                type: GET_USER,
                payload: get(res, "data", {}),
            });
        } catch (error) {
            window.location.href = "https://distrigoparts.co.uk/auth/login";
        }
    };

    // const getUser = async (email, password) => {
    //     setLoading();
    //     const res = await axios.post(
    //         "/api/v1/login",
    //         {
    //             headers: {
    //                 accept: "application/json",
    //                 Authorization: state.passport.access_token
    //             },
    //             body: {
    //                 email,
    //                 password
    //             }
    //         }
    //     );
    //     dispatch({
    //         type: GET_USER,
    //         payload: res.data
    //     });
    // };
    // Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    useEffect(() => {
        window.localStorage.setItem(
            "groupType",
            JSON.stringify(state.groupType)
        );
        window.localStorage.setItem("user", JSON.stringify(state.user));
        window.localStorage.setItem("group", JSON.stringify(state.group));
    }, [state.groupType, state.user, state.group]);

    return (
        <UserContext.Provider
            value={{
                loading: state.loading,
                passport: state.passport,
                user: state.user,
                group: state.group,
                groupType: state.groupType,
                getUser,
                setLoading,
                getToken,
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
};

export default UserState;
