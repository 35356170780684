import React, { Fragment, useContext, useEffect, useState } from "react";
import { map, isString } from "lodash";
import { checkInteger, getFilterByValue } from "../../utils/utils";
import TableLayout from "../../containers/TableLayout";
import Spinner from "../../containers/Spinner";
import SortButton from "../../components/TableHeader/SortButton";
import CustomerRow from "../CustomersTable/CustomerRow";
import CustomerContext from "../../context/customer/customerContext";
import DealerContext from "../../context/dealer/dealerContext";
import { customerFilterOptions } from "../customerFilterOptions";
import GetOnFirstRender from "../GetOnFirstRender";
import { TableHeaderRow } from "../../components/TableHeader/TableHeaderRow";

const DealerCustomersTable = ({ match }) => {
    const {
        params: { dealer_id: dealerId, filter, from, to },
    } = match;

    const {
        customers,
        getDealerCustomers,
        categoryOptions,
        getCategoryOptions,
        loading,
        setCustomerDealersPage,
        customerDealersPage,
        customerFilterValue,
        setCustomerFilterValue,
        customerCount,
    } = useContext(CustomerContext);
    // console.log({ itemsLoading, customers, loading });

    const [customerSearchText, setCustomerSearchText] = useState("");
    const [tradeTeamIdText, setTradeTeamIdText] = useState("");
    const [customerTypeText, setCustomerTypeText] = useState("");
    const [value, setValue] = useState("all");
    const [sortDirection, setSortDirection] = useState("asc");
    const [sortField, setSortField] = useState("trade_team_customer");
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);

    const filterObject = getFilterByValue(
        customerFilterOptions,
        customerFilterValue
    );
    const { dates } = filterObject;
    const { dealer, getDCDealer, dealerLoading } = useContext(DealerContext);
    const title =
        dealer && dealer.name ? `Customers > ${dealer.name}` : "Customers";

    useEffect(() => {
        if (initialLoadComplete) {
            handleSubmit();
        }
    }, [sortDirection, sortField]);

    const handleSelectChange = (event) => {
        setValue(event.target.value);
    };

    const getItemsUrl = () => {
        getCategoryOptions();
        getDCDealer(dealerId);
        getDealerCustomers({
            customerFilterValue: isString(filter)
                ? filter
                : customerFilterValue,
            id: dealerId,
            customerSearchText: customerSearchText,
            tradeTeamIdText: tradeTeamIdText,
            customerTypeText: customerTypeText,
            sortDirection: sortDirection,
            sortField: sortField,
        }).then(() => {
            setInitialLoadComplete(true);
        });
    };

    //Call this function to get the data from the server when something changes
    const handleSubmit = () => {
        setCustomerDealersPage(1);
        getItemsState();
    };

    const getItemsState = () => {
        getDealerCustomers({
            customerFilterValue: customerFilterValue,
            id: dealerId,
            customerSearchText: customerSearchText,
            tradeTeamIdText: tradeTeamIdText,
            customerTypeText: customerTypeText,
            sortDirection: sortDirection,
            sortField: sortField,
        });
    };

    return (
        <Fragment>
            <div className="row">
                <h3 className="title pad-t">{title}</h3>
                <GetOnFirstRender
                    from={from}
                    to={to}
                    filter={filter}
                    setFilterValue={setCustomerFilterValue}
                    getItems={getItemsUrl}
                />
                <TableLayout
                    activePage={customerDealersPage}
                    setActivePage={setCustomerDealersPage}
                    getItems={getItemsState}
                    totalItems={checkInteger(customerCount)}
                    filterOptions={customerFilterOptions}
                    filterValue={customerFilterValue}
                    setFilterValue={setCustomerFilterValue}
                    showDates={dates}
                >
                    <table
                        id="dealer-customers"
                        className="responsive"
                        width="100%"
                    >
                        <thead>
                            <TableHeaderRow>
                                <th>
                                    <SortButton
                                        field={"trade_team_customer"}
                                        sortDirection={sortDirection}
                                        setSortDirection={setSortDirection}
                                        setSortField={setSortField}
                                        sortField={sortField}
                                    >
                                        Customer{" "}
                                    </SortButton>
                                    <br />
                                    <input
                                        type="text"
                                        value={customerSearchText}
                                        onChange={(e) =>
                                            setCustomerSearchText(
                                                e.target.value
                                            )
                                        }
                                        placeholder="Search"
                                    />
                                </th>
                                <th>
                                    <SortButton
                                        field={"trade_team_id"}
                                        sortDirection={sortDirection}
                                        setSortDirection={setSortDirection}
                                        setSortField={setSortField}
                                        sortField={sortField}
                                    >
                                        ID{" "}
                                    </SortButton>
                                    <br />
                                    <input
                                        type="text"
                                        value={tradeTeamIdText}
                                        onChange={(e) =>
                                            setTradeTeamIdText(e.target.value)
                                        }
                                        placeholder="Search"
                                    />
                                </th>
                                <th>GDPR</th>
                                <th>
                                    <SortButton
                                        field={"customer_type"}
                                        sortDirection={sortDirection}
                                        setSortDirection={setSortDirection}
                                        setSortField={setSortField}
                                        sortField={sortField}
                                    >
                                        Type{" "}
                                    </SortButton>
                                    <br />
                                    <input
                                        type="text"
                                        value={customerTypeText}
                                        onChange={(e) =>
                                            setCustomerTypeText(e.target.value)
                                        }
                                        placeholder="Search"
                                    />
                                </th>
                                <th>
                                    <SortButton
                                        field={"awarded_points"}
                                        sortDirection={sortDirection}
                                        setSortDirection={setSortDirection}
                                        setSortField={setSortField}
                                        sortField={sortField}
                                    >
                                        Points Awarded{" "}
                                    </SortButton>
                                </th>
                                <th>
                                    Category <br />
                                    <select
                                        value={value}
                                        onChange={handleSelectChange}
                                    >
                                        <option disabled selected value>
                                            {" "}
                                            -- Select An Option --{" "}
                                        </option>
                                        {map(categoryOptions, (option) => {
                                            return (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </th>
                                <th>
                                    <SortButton
                                        field={"points_claimed"}
                                        sortDirection={sortDirection}
                                        setSortDirection={setSortDirection}
                                        setSortField={setSortField}
                                        sortField={sortField}
                                    >
                                        Points Claimed{" "}
                                    </SortButton>
                                </th>
                                <th>
                                    <SortButton
                                        field={"points_expired"}
                                        sortDirection={sortDirection}
                                        setSortDirection={setSortDirection}
                                        setSortField={setSortField}
                                        sortField={sortField}
                                    >
                                        Points Expired{" "}
                                    </SortButton>
                                </th>
                                <th>
                                    <SortButton
                                        field={"points_available"}
                                        sortDirection={sortDirection}
                                        setSortDirection={setSortDirection}
                                        setSortField={setSortField}
                                        sortField={sortField}
                                    >
                                        Points Available{" "}
                                    </SortButton>
                                </th>
                                <th>Permissions</th>
                                <th>DISTRIGO PERKS</th>
                                <th>View</th>
                            </TableHeaderRow>
                        </thead>
                        {!loading && !dealerLoading && (
                            <tbody>
                                {customers.length > 0 ? (
                                    map(customers, (customer, i) => (
                                        <CustomerRow
                                            key={customer.trade_team_id}
                                            customer={customer}
                                            selectOption={value}
                                        />
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="12">No customers found</td>
                                    </tr>
                                )}
                            </tbody>
                        )}
                    </table>
                    {loading || dealerLoading ? <Spinner /> : null}
                </TableLayout>
            </div>
        </Fragment>
    );
};

export default DealerCustomersTable;
