import React, { useReducer, useContext } from "react";
import axios from "axios";
import { get } from "lodash";
import CommunicationContext from "./communicationContext";
import CommunicationReducer from "./CommunicationReducer";
import {
    SET_LOADING,
    GET_COMMUNICATION_COUNTS,
    GET_COMMUNICATION_DEALERS,
    GET_DEALER_COMMUNICATIONS,
    GET_DEALER_COMMUNICATION_RECIPIENTS,
    GET_COMMUNICATIONS,
    GET_COMMUNICATION_RECIPIENTS,
    GET_COMMUNICATION_RECIPIENT,
    COMMUNICATION_STATUS_UPDATED,
    SET_COMMUNICATION_FILTER_VALUE,
    SET_COMMUNICATION_DEALERS_PAGE,
    SET_DEALER_COMMUNICATIONS_PAGE,
    SET_DEALER_COMMUNICATION_RECIPIENTS_PAGE
} from "../types";
import SearchContext from "../search/searchContext";
import UserContext from "../user/userContext";
import { checkCommunicationFilter } from "../../tables/communicationFilterOptions";
import { replaceHyphens } from "../../utils/utils";

const CommunicationState = props => {
    const { startDateMySQL, endDateMySQL, paginationValue } = useContext(
        SearchContext
    );
    const { group } = useContext(UserContext);

    const initialState = {
        communication: {},
        communications: [],
        dealerCommunications: [],
        dealerCommunicationsLinks: {},
        dealerCommunicationsCount: 0,
        communicationRecipientCount: 0,
        dealerCommunicationRecipientsPage: 1,
        communicationDealers: [],
        communicationLinks: {},
        communicationTotal: "",
        communicationCounts: [],
        communicationCount: 0,
        communicationDealerCount: 0,
        categoryOptions: [],
        categoryOptionsLoaded: false,
        loading: false,
        communicationFilterValue: "all_dates",
        communicationDealersPage: 1,
        dealerCommunicationsPage: 1,
        communicationPage: 1
    };

    const [state, dispatch] = useReducer(CommunicationReducer, initialState);

    /**
     * @param {integer} pageNumber
     * @return {undefined}
     */
    const setDealerCommunicationRecipientsPage = pageNumber => {
        dispatch({
            type: SET_DEALER_COMMUNICATION_RECIPIENTS_PAGE,
            payload: pageNumber
        });
    };

    /**
     * @param {integer} pageNumber
     * @return {undefined}
     */
    const setDealerCommunicationsPage = pageNumber => {
        dispatch({
            type: SET_DEALER_COMMUNICATIONS_PAGE,
            payload: pageNumber
        });
    };

    /**
     * @param {integer} pageNumber
     * @return {undefined}
     */
    const setCommunicationDealersPage = pageNumber => {
        dispatch({
            type: SET_COMMUNICATION_DEALERS_PAGE,
            payload: pageNumber
        });
    };

    /**
     * @param {stirng} filter
     * @return {undefined}
     */
    const setCommunicationFilterValue = filter => {
        dispatch({
            type: SET_COMMUNICATION_FILTER_VALUE,
            payload: filter
        });
    };

    /**
     * @param {string} rawFilter
     * @return {undefined}
     */
    const setCommunicationFilterOrDefault = rawFilter => {
        const filter = checkCommunicationFilter(replaceHyphens(rawFilter));
        setCommunicationFilterValue(filter);
    };

    const getCommunicationCounts = async () => {
        setLoading();
        const res = await axios.get(
            `/api/v1/communication/counts/${startDateMySQL}/${endDateMySQL}`
        );
        dispatch({
            type: GET_COMMUNICATION_COUNTS,
            payload: get(res, "data", {})
        });
    };

    const getCommunicationDealers = async ({
       communicationFilterValue: communicationFilterValueRaw,
		 start,
		 end
    }) => {
        const filter = replaceHyphens(communicationFilterValueRaw);

        setLoading();
        const res = await axios.get(
            `/api/v1/communication-dealers/${filter}/${start}/${end}?pagination=${paginationValue}&page=${state.communicationDealersPage}`
        );
        dispatch({
            type: GET_COMMUNICATION_DEALERS,
            payload: get(res, "data", {})
        });
    };

    const getDealerCommunications = async ({
        communicationFilterValue: communicationFilterValueRaw,
		  dealerId
    }) => {
        const filter = replaceHyphens(communicationFilterValueRaw);

        setLoading();
        const res = await axios.get(
            `/api/v1/communications-dealer/${dealerId}/${filter}/${startDateMySQL}/${endDateMySQL}?pagination=${paginationValue}&page=${state.communicationDealersPage}`
        );
        dispatch({
            type: GET_DEALER_COMMUNICATIONS,
            payload: get(res, "data", {})
        });
    };

    const getCommunications = async ({
       communicationFilterValue: communicationFilterValueRaw,
		 start,
		 end
    }) => {
        const filter = replaceHyphens(communicationFilterValueRaw);

        setLoading();
        const res = await axios.get(
            `/api/v1/communications/${filter}/${start}/${end}?pagination=${paginationValue}&page=${state.communicationDealersPage}`
        );
        dispatch({
            type: GET_COMMUNICATIONS,
            payload: get(res, "data", {})
        });
    };

    const getDealerCommunicationRecipients = async ({
        communicationFilterValue: communicationFilterValueRaw,
        communicationId,
        dealerId
    }) => {
        const filter = replaceHyphens(communicationFilterValueRaw);

        setLoading();
        const res = await axios.get(
            `/api/v1/communication-recipients/${communicationId}/dealer/${dealerId}/${filter}/${startDateMySQL}/${endDateMySQL}?pagination=${paginationValue}&page=${state.dealerCommunicationRecipientsPage}`
        );
        dispatch({
            type: GET_DEALER_COMMUNICATION_RECIPIENTS,
            payload: get(res, "data", {})
        });
    };

    const getCommunicationRecipients = async ({
        communicationFilterValue: communicationFilterValueRaw,
        communicationId
    }) => {
        const filter = replaceHyphens(communicationFilterValueRaw);

        setLoading();
        const res = await axios.get(
            `/api/v1/communication-recipients/${communicationId}/${state.communicationFilterValue}/${startDateMySQL}/${endDateMySQL}?pagination=${paginationValue}&page=${state.communicationDealersPage}`
        );
        dispatch({
            type: GET_COMMUNICATION_RECIPIENTS,
            payload: get(res, "data", {})
        });
    };

    const getCommunicationRecipient = async (
        communication_id,
        communication_recipient_uuid
    ) => {
        setLoading();
        const res = await axios.get(
            `/api/v1/communication-recipient/${communication_id}/${communication_recipient_uuid}`
        );
        dispatch({
            type: GET_COMMUNICATION_RECIPIENT,
            payload: get(res, "data", {})
        });
    };

    const updateCommunicationStatus = async ({
        values,
        communicationId,
        dealerId,
        communicationFilterValue,
    }) => {

        const res = await axios.post(
            `/api/v1/communication-recipient/status-update`,
            values
        );

        getDealerCommunicationRecipients({
            communicationFilterValue,
            communicationId,
            dealerId
        });

        dispatch({
            type: COMMUNICATION_STATUS_UPDATED,
            payload: get(res, "data", {})
        });
    };

    const setLoading = () => dispatch({ type: SET_LOADING });

    return (
        <CommunicationContext.Provider
            value={{
                communicationCounts: state.communicationCounts,
                communicationCount: state.communicationCount,
                communicationDealerCount: state.communicationDealerCount,
                communicationDealers: state.communicationDealers,
                dealerCommunicationsPage: state.dealerCommunicationsPage,
                dealerCommunicationsCount: state.dealerCommunicationsCount,
                dealerCommunications: state.dealerCommunications,
                communications: state.communications,
                communicationRecipientCount: state.communicationRecipientCount,
                communicationRecipients: state.communicationRecipients,
                communicationRecipient: state.communicationRecipient,
                dealerCommunicationRecipientsPage:
                    state.dealerCommunicationRecipientsPage,
                communicationFilterValue: state.communicationFilterValue,
                communicationDealersPage: state.communicationDealersPage,
                loading: state.loading,
                getCommunicationCounts,
                getCommunicationDealers,
                getDealerCommunications,
                getCommunications,
                getDealerCommunicationRecipients,
                getCommunicationRecipients,
                getCommunicationRecipient,
                updateCommunicationStatus,
                setLoading,
                setCommunicationFilterOrDefault,
                setCommunicationFilterValue,
                setCommunicationDealersPage,
                setDealerCommunicationRecipientsPage,
                setDealerCommunicationsPage
            }}
        >
            {props.children}
        </CommunicationContext.Provider>
    );
};

export default CommunicationState;
